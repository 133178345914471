import { Injectable, signal } from '@angular/core';
import { AppService } from './app.service';
import { BGCModuleRow, LibraryModuleRow, LockedStatus, LockTargetCommand, RejectTargetCommand, TargetSelectionApi, TargetStatus } from './target-selection-api/target-selection-api';
import { first, Observable, Subject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderService } from './loader.service';
import { FilterItem } from '../filter/filter.component';
import { RDKitLoaderService } from './rdkit-loader.service';
import { RDKitModule } from '@rdkit/rdkit';
import { LocalStorageService } from './local-storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

export interface BGCModuleFilter {
  filterConfig?: FilterItem[];
}

export interface FilterInterface {
  (input: any[]): any[];
}

@Injectable({
  providedIn: 'root'
})

export class BGCModuleService {


  data: BGCModuleRow[] = [];
  filteredData = signal<BGCModuleRow[]>([]);
  selectedData: BGCModuleRow[] = [];
  dataLoaded$ = new Subject<void>();
  beforeFilterApplied$ = new Subject<boolean>();
  filter: BGCModuleFilter;
  filters: FilterInterface[] = [];

  constructor(
    private client: TargetSelectionApi,
    private appService: AppService,
    public loaderService: LoaderService,
    private router: Router,
    private messageService: MessageService
  ) {

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();

    });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.beforeFilterApplied$.next(true);
      this.getData();
    });

  }

  addFilter(f: FilterInterface) {
    this.filters.push(f);
  }

  getData() {
    this.loaderService.setLoading(true);
    this.data = [];
    this.beforeFilterApplied$.next(false);
    this.filteredData.set([]);
    this.client.getBGCModuleRows(this.appService.selectedBatchName()).subscribe(x => {
      this.data = x;
      this.filteredData.set([...x]);
    }, error => this.loaderService.setLoading(false), () => this.loaderService.setLoading(false));
  }

  reset() {
    if (this.data.length > 0) {
      this.beforeFilterApplied$.next(true);
      this.filter = this.getBGCFilter();
      this.filteredData.set(this.data.filter(x => true));
    }
  }

  getFilteredData(): any[] {
    var retval = this.data.filter(x => true);
    for (var f of this.filters) {
      retval = f(retval);
    }
    return retval;
  }

  updateFilterValues(rows: BGCModuleRow[]) {
    if (!rows) return;
  }


  getBGCFilter(): BGCModuleFilter {
    return {
      filterConfig: []
    };
  }

  navigate(gcfId: number, type: string) {
    if (type == "gcfid" || type == "countBGC") {
      const queryParams: any = {};
      queryParams["gcfId"] = gcfId;
      queryParams["batchId"] = this.appService.selectedBatchName();
      queryParams["refBGC"] = "No";
      this.appService.openModuleNewWindowMultipleParams(queryParams, "bgc-deep-dive");
    } else if (type == "countRefGeneCluster"){
      const queryParams: any = {};
      queryParams["gcfId"] = gcfId;
      queryParams["batchId"] = this.appService.selectedBatchName();
      queryParams["refBGC"] = "Yes";
      this.appService.openModuleNewWindowMultipleParams(queryParams, "bgc-deep-dive");
    }
      else {

      this.loaderService.setLoading(true);
      this.client.getBGCLinkIds(this.appService.selectedBatchName(), gcfId, type).subscribe(x => {

        this.loaderService.setLoading(false);

        if (!x || x.length == 0) {
          this.messageService.add({ severity: 'info', summary: 'Not Found', detail: 'No matches found' });
          return;
        }

        console.log(x);
        console.log(type);

        // Call setLoading with dontModifyCount=true so the loading indicator shows during and after transition to other module
        this.loaderService.setLoading(true, true);

        if (type == "countMSNodeCorrelated") {
          this.appService.navigateToModuleWithParams(x, "metabolites");
        }
        else if (type == "countPredictedCompoundProduced") {
          this.appService.navigateToModuleWithParams(x, "library");
        }
        else if (type == "countTotalStrains") {
          const queryParams: any = {};
          queryParams["STRAIN_IDS"] = x;
          this.appService.navigateToModuleWithParams(queryParams, "strains");
        }

      });
    }
  }

  updateTargetButtons() {
    this.appService.showUnLockButton.set(false);
    this.appService.showLockButton.set(false);
    this.appService.showRejectButton.set(false);
    this.appService.showTargetButton.set(false);

    if (!this.selectedData) return;

    if (this.selectedData.length == 1 && !["target", "rejected"].includes(this.selectedData[0].status.status)) {
      this.appService.showTargetButton.set(true);
    }

    if (this.selectedData.length > 0) {
      const targetRows = this.selectedData.filter(x => x.status?.status == "target");
      const rejectRows = this.selectedData.filter(x => x.status?.status == "rejected");
      const lockedByMeRows = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId == this.appService.currentUser); // also check on show locked
      const lockedBySomeoneElse = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId != this.appService.currentUser); // also check on show locked
      const noStatusRows = this.selectedData.filter(x => !x.status || !x.status.status);

      if (targetRows?.length > 0) {
        return;
      }

      if (noStatusRows?.length == this.selectedData.length) {
        this.appService.showLockButton.set(true);
      }

      if (lockedByMeRows?.length == this.selectedData.length) {
        this.appService.showUnLockButton.set(true);
      }

      if (targetRows?.length == 0 && rejectRows?.length == 0) {
        this.appService.showRejectButton.set(true);
      }
    }

  }

  setSelectedData(selData: BGCModuleRow[]) {
    this.selectedData = selData;
    this.updateTargetButtons();
  }

  // lockTarget(cmd: LockTargetCommand, row: BGCModuleRow): Observable<TargetStatus> {
  //   return this.client.lockTarget(cmd).pipe(tap(x => {
  //     if (row) {
  //       if (cmd.status == LockedStatus.Locked) {
  //         Object.assign(row.status, x);
  //       }
  //       else if (cmd.status == LockedStatus.UnLocked) {
  //         row.status.status = "";
  //       }
  //     }
  //   }));
  // }

  // rejectTarget(cmd: RejectTargetCommand, row: BGCModuleRow): Observable<TargetStatus> {
  //   return this.client.rejectTarget(cmd).pipe(tap(x => {
  //     if (row) {
  //       Object.assign(row.status, x);
  //     }
  //   }));
  // }



}
