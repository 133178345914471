import { Inject, Injectable, signal } from '@angular/core';
import { Batch, TargetSelectionApi } from './target-selection-api/target-selection-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, shareReplay, Subject } from 'rxjs';
import { APP_SETTINGS_TOKEN, AppSettings } from '../app.settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';






export type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  displayName?: string,
  mail?: string,
  id?: string
};


@Injectable({
  providedIn: 'root'
})
export class MSGraphService {

  baseUrl = "https://graph.microsoft.com/";
  profile = signal<ProfileType | undefined>(undefined);;

  constructor(@Inject(APP_SETTINGS_TOKEN) private settings: AppSettings,
    private httpClient: HttpClient) {
  }


  getMyPhoto$() : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'image/jpeg',
      }),
      responseType: 'blob' as 'blob'
    };
    return this.httpClient.get(this.baseUrl + 'v1.0/me/photo/$value', httpOptions).pipe(shareReplay(1));
  }

  getUserPhoto$(emailAddress: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'image/jpeg',
      }),
      responseType: 'blob' as 'blob'
    };
    return this.httpClient.get(this.baseUrl + `v1.0/users/${emailAddress}/photo/$value`, httpOptions).pipe(shareReplay(1));
  }

  getMyProile$(): Observable<ProfileType> {
    return this.httpClient.get(this.baseUrl + `v1.0/me`).pipe(shareReplay(1));
  }

  getUserProile$(email: string): Observable<ProfileType> {
    return this.httpClient.get(this.baseUrl + `v1.0/users/${email}`).pipe(shareReplay(1));
  }
}
