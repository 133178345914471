import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles, UserService } from '../services/user.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate {
  constructor(public userService: UserService, private router: Router, private messageService: MessageService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let minimumRole = route.data['minimumRole'];

        if (minimumRole == Roles.Reader && this.userService.isReaderOrHigher()) {
          return true;
        }
        else if (minimumRole == Roles.IsolationChemist && this.userService.isIsolationChemistOrHigher()) {
          return true;
        }
        else if (minimumRole == Roles.TargetPicker && this.userService.isTargetPickerOrHigher()) {
          return true;
        }
        else if (minimumRole == Roles.Admin && this.userService.isAdminOrHigher()) {
          return true;
        }

        this.router.navigate(['/home']);
        this.messageService.add({ severity: 'error', summary: 'Unauthorized', detail: 'You are not authorized to view that page' });
        return false;
    }
}
