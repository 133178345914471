import { Component, effect, OnInit, signal, ViewChild } from '@angular/core';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';
import { AppService } from '../../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { CountRenderer } from '../metabolite-module/count-renderer.component';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { UrlRenderer } from '../metabolite-module/url-renderer.component';
import { StructureRenderer, StructureRendererContext } from '../metabolite-module/structure-renderer.component';
import { StructureFilterComponent } from '../../../structure-filter/structure-filter.component';
import { StatusRenderer } from '../metabolite-module/status-renderer.component';
import { LockedStatus, LockTargetCommand, MetaboliteModuleRow, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { TabViewModule } from 'primeng/tabview';
import { BiodataRenderer } from './biodata-renderer.component';
import { debounceTime, forkJoin, Observable, Subject } from 'rxjs';
import { CreateTargetComponent, CreateTargetDialogInput } from "../../targets/create-target/create-target.component";
import { RejectTargetComponent, RejectTargetDialogInput } from "../../targets/reject-target/reject-target.component";
import { TargetModuleService } from '../../../services/target-module.service';

@Component({
  selector: 'app-metabolite-module-deep-dive',
  standalone: true,
  imports: [
    AgGridModule,
    TabViewModule,
    CreateTargetComponent,
    RejectTargetComponent
  ],
  templateUrl: './metabolite-module-deep-dive.component.html',
  styleUrl: './metabolite-module-deep-dive.component.scss'
})
export class MetaboliteModuleDeepDiveComponent implements OnInit {

  @ViewChild("ctd") ctd: CreateTargetComponent;
  @ViewChild("rtd") rtd: RejectTargetComponent;

  msNodeId: any;
  spectralFamilyId: any;
  batchId: string;
  gridApi = signal<GridApi<MetaboliteModuleRow> | undefined>(undefined);


  private selectionSubject = new Subject<MetaboliteModuleRow[]>();



  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  msNodesColDef: ColDef[] = [
    {
      field: "status", headerName: "Status", filter: "agSetColumnFilter", filterValueGetter: (p) => {
        return p.data.status?.status;
      }, cellRenderer: StatusRenderer, cellDataType: "text"
    },
    { field: "mz", headerName: "m/z", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "retentionTime", headerName: "Retention Time" },
    {
      field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number"
    },
    // {
    //   field: "spectralFamilyId", headerName: "Spectral Family", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
    //   cellRendererParams: {
    //     clicked: (urlValue: number, field: string) => {
    //       this.mmdd.navigate(urlValue, field);
    //     }
    //   }
    // },
    { field: "strainID", headerName: "Highest Strain", filter: "agSetColumnFilter", cellDataType: "text" },
    {
      field: "countExactMassMatch", headerName: "# Exact Mass Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    {
      field: "countSpectralMatch", headerName: "# Spectral Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    {
      field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer, filter: StructureFilterComponent,

      cellRendererParams: {
        context: {
          smilesProp: "spectrumCompoundSMILES",
          compoundNameProp: "spectrumCompoundName",
          showSpectralMatchChip: true,
          matchTypeValue: "sm",
        } as StructureRendererContext
      }

    },
    //{ field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer },
    //{ field: "cScore", headerName: "C-Score", filter: "agNumberColumnFilter", cellDataType: "number" },
    {
      field: "countGCF", headerName: "# GCF Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    { headerName: "Bioactivity Correlation" },
    { headerName: "HCA-PCA selection" },

  ];


  rawMetabColDef: ColDef[] = [
    { field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number" },
    { field: "msScanId", headerName: "MS Scan", filter: "agSetColumnFilter", cellDataType: "text" },
    //{ field: "msRunId", headerName: "Run Id", filter: "agSetColumnFilter", cellDataType: "text" },
    { field: "mz", headerName: "m/z", cellDataType: "number" },
    {
      field: "strainId", headerName: "Strain", filter: "agSetColumnFilter", cellDataType: "text", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.appService.openModuleNewWindow([urlValue.toString()], "STRAIN_IDS", "strains");
        }
      }
    },
    { field: "taxonomy", headerName: "Taxonomy", cellDataType: "text" },
    { field: "sampleRunDate", headerName: "Sample Run Date", cellDataType: "date" },
    { field: "lotId", headerName: "CRS Lot Id", filter: "agSetColumnFilter", cellDataType: "text" },
    { field: "precursorIntensity", headerName: "Precursor Intensity", cellDataType: "number" }

  ];

  compoundsColDef: ColDef[] = [
    { field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number" },
    { field: "matchType", headerName: "Match Type", filter: "agSetColumnFilter", cellDataType: "text", rowGroup: true, hide: true },
    {
      field: "smiles", headerName: "SMILES", cellDataType: "text", cellRenderer: StructureRenderer,

      cellRendererParams: {
        context: {
          smilesProp: "smiles",
          compoundNameProp: "compoundName",
          matchTypeProp: "matchType",
          showSpectralMatchChip: true,

        } as StructureRendererContext
      }

    },
    { field: "compoundId", headerName: "Compound Id", cellDataType: "text" },
    { field: "compoundName", headerName: "Compound Name", cellDataType: "text" },
    { field: "ppmError", headerName: "PPM Error", cellDataType: "number" },
    { field: "mz", headerName: "Theoretical MZ", cellDataType: "number" },
    { field: "mirrorSpectra", headerName: "Mirror Spectra", cellDataType: "text" },
    { field: "cosine", headerName: "Cosine", cellDataType: "number" }

  ];

  bioactivityColDef: ColDef[] = [
    { field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number" },
    { field: "matchType", headerName: "Match Type", filter: "agSetColumnFilter", cellDataType: "text", rowGroup: true, hide: true },
    {
      field: "smiles", headerName: "SMILES", cellDataType: "text", cellRenderer: StructureRenderer,

      cellRendererParams: {
        context: {
          smilesProp: "smiles",
          compoundNameProp: "compoundName",
          matchTypeProp: "matchType",
          showSpectralMatchChip: true,

        } as StructureRendererContext
      }

    },
    { field: "materialId", headerName: "Material Id", cellDataType: "number" },
    { field: "compoundId", headerName: "Compound Id", cellDataType: "text" },
    { field: "compoundName", headerName: "Compound Name", cellDataType: "text" },

    { field: "septtrHit", headerName: "SEPTTR Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "asrHit", headerName: "ASR Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "broadSpectrumHit", headerName: "Broad Spectrum Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "oomyceteHit", headerName: "Oomycete Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "lepHit", headerName: "LEP Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "crwHit", headerName: "CRW Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "sapHit", headerName: "SAP Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "nematodeHit", headerName: "Nematode Hit", cellDataType: "text", cellRenderer: BiodataRenderer },
    { field: "wmHit", headerName: "WM Hit", cellDataType: "text", cellRenderer: BiodataRenderer }


  ];

  gcfColDef: ColDef[] = [
    { field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number" },
    {
      field: "gcfId", headerName: "GCF Id", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.appService.openModuleNewWindow([urlValue.toString()], "GCF_IDS", "bgc");
        }
      }
    },
    { field: "numBgc", headerName: "# BGCs", cellDataType: "number" },
    { field: "numRefBgc", headerName: "# Ref BGCs", cellDataType: "number" },
    { field: "correlationScore", headerName: "Correlation Score", cellDataType: "number" },
    { field: "gpMp", headerName: "gP_mP", cellDataType: "number" },
    { field: "gpMa", headerName: "gP_mA", cellDataType: "number" },
    { field: "gaMp", headerName: "gA_mP", cellDataType: "number" },
    { field: "gaMa", headerName: "gA_mA", cellDataType: "number" }

  ];

  obColDef: ColDef[] = [
    { field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number" },
    {
      field: "altMsNodeId", headerName: "Alt MS Node", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clickedWRow: (urlValue: number, field: string, row: any) => {
          this.mmdd.navigateOtherBatch(row["altMsNodeId"], "msNodeId", row["altBatchId"]);
        }
      }
    },
    { field: "altBatchId", headerName: "Alt Batch", filter: "agSetColumnFilter", cellDataType: "text" },
    {
      field: "altSpectralFamilyId", headerName: "Alt Spectral Family", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clickedWRow: (urlValue: number, field: string, row: any) => {
          this.mmdd.navigateOtherBatch(row["altSpectralFamilyId"], "spectralFamilyId", row["altBatchId"]);
        }
      }
    },
  ];



  constructor(public mmdd: MetaboliteDeepDiveService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private metabolitesService: MetaboliteModuleService,
    private targetService: TargetModuleService
  ) {

    this.msNodeId = this.activatedRoute.snapshot.queryParams['msNodeId'];
    this.spectralFamilyId = this.activatedRoute.snapshot.queryParams['spectralFamilyId'];
    this.batchId = this.activatedRoute.snapshot.queryParams['batchId'];

    effect(() => {
      if (this.appService.selectedBatchName()) {
        this.mmdd.getDeepDive(this.msNodeId, this.spectralFamilyId).subscribe(x => {
          console.log(x);
        });
      }
    });

    appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      if (this.mmdd.selectedData.length == 1 && x == "target") {
        //this.selectedRow = this.metabolitesService.selectedData[0];
        let input = new CreateTargetDialogInput();
        input.batchId = this.mmdd.selectedData[0].batchId;
        input.msNodeId = this.mmdd.selectedData[0].msNodeId;
        input.mz = this.mmdd.selectedData[0].mz;
        input.rowToUpdate = this.mmdd.selectedData[0];
        input.spectralFamilyId = this.mmdd.selectedData[0].spectralFamilyId;
        input.spectrumCompoundSMILES = this.mmdd.selectedData[0].spectrumCompoundSMILES;
        input.strainID = this.mmdd.selectedData[0].strainID;

        this.ctd.show(input);
      }

      if (x == "rejected") {

        let input: RejectTargetDialogInput[] = [];

        for (let row of this.mmdd.selectedData) {
          let x = new RejectTargetDialogInput();
          x.batchId = row.batchId;
          x.msNodeId = row.msNodeId;
          x.rowToUpdate = row;

          input.push(x);
        }

        this.rtd.show(input);
      }

      if (x == "locked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.mmdd.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.Locked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.mmdd.updateTargetButtons();
        });
      }

      if (x == "unlocked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.mmdd.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.UnLocked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.mmdd.updateTargetButtons();
        });
      }

    });
  }

  ngOnInit(): void {
    this.selectionSubject.pipe(debounceTime(1000)).subscribe((v) => {
      this.mmdd.setSelectedData(v);
    });

    if (this.batchId) {
      this.appService.selectBatch(this.batchId);
    }
  }

  onSelectionChanged($event: SelectionChangedEvent<MetaboliteModuleRow, any>) {
    var rowCount = $event.api.getSelectedNodes().length;
    console.log(rowCount);
    this.selectionSubject.next($event.api.getSelectedNodes().map(x => x.data));
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi.set(params.api);
  }

  onFirstDataRendered(params: any) {
    if (this.mmdd.deepDive()?.msNodeId && this.gridApi()) {
      this.gridApi().forEachNode(node => {
        if (node.data.msNodeId == this.mmdd.deepDive().msNodeId) {
          node.setSelected(true);
        }
      });
    }
  }

  targetCreated() {
    this.gridApi().deselectAll();
  }

  targetsRejected() {
    this.gridApi().deselectAll();
  }
}
