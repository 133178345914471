import { Injectable, ErrorHandler } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { settings } from '../../settings/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: settings.appInsightsInstrumentationKey, // Replace with your actual key
        enableAutoRouteTracking: true 
      }
    });
    this.appInsights.loadAppInsights(); 
  }

  handleError(error: any): void {
    // Log the error to the console for debugging
    console.error('An error occurred:', error);

    // Send the error to Application Insights
    this.appInsights.trackException({ exception: error });
  }
}