import { PanelModule } from 'primeng/panel';
import { Component, ViewChild } from '@angular/core';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ChemdrawWrapperComponent } from "../../../chemdraw-wrapper/chemdraw-wrapper.component";
import { SelectItem } from 'primeng/api';
import { InputWLimitedDecComponent } from "../../../utility/input-w-limited-dec/input-w-limited-dec.component";
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, AgPromise, IDoesFilterPassParams, IAfterGuiAttachedParams } from 'ag-grid-enterprise';
import { LibraryModuleComponent, StructureSearchType } from '../library-module/library-module.component';
import _ from 'lodash';

@Component({
  selector: 'app-library-module-filter',
  standalone: true,
  imports: [
    PanelModule,
    InputGroupModule,
    ButtonModule,
    MoleculeStructureComponent,
    CommonModule,
    FormsModule,
    DialogModule,
    ChemdrawWrapperComponent,
    DropdownModule,
    InputNumberModule,
    FloatLabelModule,
    InputWLimitedDecComponent
],
  templateUrl: './library-module-filter.component.html',
  styleUrl: './library-module-filter.component.scss'
})

export class LibraryModuleFilterComponent implements IFilterAngularComp {

  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;

  structureSearchType = StructureSearchType;

  showChemDraw: boolean = false;
  searchTypesOptions: SelectItem<number>[] = [];
  drawnStructure: string;
  isFilterApplied: boolean = false;
  lmComponent: LibraryModuleComponent;
  filterData: LibraryModuleFilterComponentData = {
    searchSmiles: undefined,
    selectedSearchType: 0,
    similarityThreshold: 0
  };

  constructor(
  ) {

    Object.entries(StructureSearchType).forEach(([key, val]) => {
      this.searchTypesOptions.push({label: key, value: val} as SelectItem<number>);
    });

  }

  applyFilter() {
    this.isFilterApplied = true;
    this.lmComponent.structureFilterData = _.cloneDeep(this.filterData);
    this.lmComponent.getData();
  }

  getValuesFromComoponent() {
    this.filterData = _.cloneDeep(this.lmComponent.structureFilterData);
  }

  reset() {
    this.isFilterApplied = false;
    this.lmComponent.setDefaults();
    this.getValuesFromComoponent();
    this.lmComponent.getData();
  }

  showChemDrawDialog() {
    this.drawnStructure = this.filterData.searchSmiles;
    this.showChemDraw = true;
  }

  applyDrawnStructure() {
    this.showChemDraw = false;
    this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
      this.filterData.searchSmiles = smiles;
    });
    this.applyFilter();
  }

  agInit(params: IFilterParams<any, any>): void {
    this.lmComponent = params.context as LibraryModuleComponent;
    this.getValuesFromComoponent();
  }

  isFilterActive(): boolean {
    //return this.isFilterApplied;
    return false;
  }

  getModel() {
    return this.filterData;
  }

  setModel(model: any): void | AgPromise<void> {
    this.filterData = model as LibraryModuleFilterComponentData;
  }

  refresh?(newParams: IFilterParams): boolean {
    return false;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  onNewRowsLoaded?(): void {

  }

  onAnyFilterChanged?(): void {

  }

  getModelAsString?(model: any): string {
    return JSON.stringify(this.filterData);
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  afterGuiDetached?(): void {

  }

}

export interface LibraryModuleFilterComponentData {
  searchSmiles: string;
  selectedSearchType: number;
  similarityThreshold: number;
}
