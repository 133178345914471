import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouteReuseStrategy } from '@angular/router';

import { routes } from './app.routes';
//import { environment } from '../environments/environment';
import { TARGET_SELECTION_API_BASE_URL } from './services/target-selection-api/target-selection-api';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';

import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";

import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';

import { settings } from '../settings/environment';
import { APP_SETTINGS_TOKEN } from './app.settings';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './services/loader.service';
import { RDKitLoaderService } from './services/rdkit-loader.service';
import { FilterService, MessageService } from 'primeng/api';
import { MSGraphService } from './services/msgraph.service';
import {LicenseManager} from "ag-grid-enterprise";
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy.service';
import { BrowserNavButtonsService } from './services/browser-nav-buttons.service';
import { MetaboliteDeepDiveService } from './services/metabolite-deep-dive.service';








export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}


export const appConfig: ApplicationConfig = {

  providers: [

    {
      provide: APP_SETTINGS_TOKEN,
      useValue: settings
    },

    { provide: TARGET_SELECTION_API_BASE_URL, useValue: settings.baseUrl },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom(
      ResearchComponentsCoreModule.forRoot('Target Selection') ,
      ResearchComponentsMsalIntegrationModule,
      BrowserAnimationsModule 
    ),

    { provide: ResearchComponentsCoreModule, useValue: undefined },

    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: () => new PublicClientApplication(settings.msal.config),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: () => settings.msal.guard,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: () => settings.msal.interceptor,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoaderService,
    //TSFilterService,
    RDKitLoaderService,
    MessageService,
    FilterService,
    BrowserNavButtonsService,
    MetaboliteDeepDiveService,
    MSGraphService


  ]
};
