import { Injectable, signal } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, ReplaySubject, BehaviorSubject, debounceTime, Subject, tap } from 'rxjs';
import { AppService } from './app.service';
import { DdmBioactivity, DdmCompound, DdmGcf, MetaboliteModuleRow, MetabolitesDeepDiveResponse, OtherBatch, RawMetab, TargetSelectionApi } from './target-selection-api/target-selection-api';
import { UntypedFormArray } from '@angular/forms';


@Injectable()
export class MetaboliteDeepDiveService {


  deepDive = signal<MetabolitesDeepDiveResponse | undefined>(undefined);
  selectedData: MetaboliteModuleRow[];
  rawMetab: RawMetab[];
  compounds: DdmCompound[];
  bioactivity: DdmBioactivity[];
  gcfs: DdmGcf[];
  otherBatches: OtherBatch[];

  constructor(private router: Router,
    private appService: AppService,
    private client: TargetSelectionApi,
  ) {

  }

  navigate(id: any, idType: string) {
    console.log(`value=${id} field=${idType}`);

    const queryParams: any = {};
    queryParams[idType] = id;
    const navigationExtras: NavigationExtras = {
      queryParams
    };

    var url = "";

    if (idType == "spectralFamilyId")
      url = this.router.serializeUrl(this.router.createUrlTree(["/metabolites-deep-dive"], navigationExtras));
    // if (idType== "strainID")
    //   url = this.router.serializeUrl(this.router.createUrlTree(["/strains"], navigationExtras));

    this.appService.openWindow(url);
  }

  navigateOtherBatch(urlValue: number, paramName: string, batchId: string) {
    const queryParams: any = {};
    queryParams[paramName] = urlValue;
    queryParams["batchId"] = batchId;
    const navigationExtras: NavigationExtras = {
      queryParams
    };

    let url = this.router.serializeUrl(this.router.createUrlTree(["/metabolites-deep-dive"], navigationExtras));
    this.appService.openWindow(url);
  }


  getDeepDive(msNodeId: number, spectralFamilyId: number): Observable<MetabolitesDeepDiveResponse> {
    let batchId = this.appService.selectedBatchName();
    return this.client.getMetabolitesDeepDive(batchId, msNodeId, spectralFamilyId).pipe(tap(x => {
      this.deepDive.set(x);

    }));
  }

  setSelectedData(rows: MetaboliteModuleRow[]) {
    this.selectedData = rows;
    this.updateTargetButtons();
    this.updateTabs();
  }

  updateTabs() {
    let nodeIds = this.selectedData.map(x => x.msNodeId);
    let batchId = this.appService.selectedBatchName();

    this.rawMetab = [];
    this.compounds = [];
    this.gcfs = [];
    this.bioactivity = [];
    this.otherBatches = [];


    this.client.getMetabolitesDeepDiveTabs(batchId, nodeIds).subscribe(x => {
      this.rawMetab = x.rawMetabolomics;
      this.compounds = x.compounds;
      this.gcfs = x.gcfs;
      this.bioactivity = x.compounds.map(x => x.bioactivities).reduce((p, c) => { return p.concat(c); });
      this.otherBatches = x.otherBatches;
    });


    // this.rawMetab = this.deepDive().rawMetabolomics.filter(x => nodeIds.includes(x.msNodeId));
    // this.compounds = this.deepDive().compounds.filter(x => nodeIds.includes(x.msNodeId));
    // this.gcfs = this.deepDive().gcfs.filter(x => nodeIds.includes(x.msNodeId));
    // this.bioactivity = this.deepDive().compounds.map(x => x.bioactivities).reduce((p, c) => { return p.concat(c); }).filter(x => nodeIds.includes(x.msNodeId));
  }

  updateTargetButtons() {
    this.appService.showUnLockButton.set(false);
    this.appService.showLockButton.set(false);
    this.appService.showRejectButton.set(false);
    this.appService.showTargetButton.set(false);

    if (!this.selectedData) return;

    if (this.selectedData.length == 1 && !["target", "rejected"].includes(this.selectedData[0].status.status)) {
      this.appService.showTargetButton.set(true);
    }

    if (this.selectedData.length > 0) {
      const targetRows = this.selectedData.filter(x => x.status?.status == "target");
      const rejectRows = this.selectedData.filter(x => x.status?.status == "rejected");
      const lockedByMeRows = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId == this.appService.currentUser); // also check on show locked 
      const lockedBySomeoneElse = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId != this.appService.currentUser); // also check on show locked 
      const noStatusRows = this.selectedData.filter(x => !x.status || !x.status.status);

      if (targetRows?.length > 0) {
        return;
      }

      if (noStatusRows?.length == this.selectedData.length) {
        this.appService.showLockButton.set(true);
      }

      if (lockedByMeRows?.length == this.selectedData.length) {
        this.appService.showUnLockButton.set(true);
      }

      if (targetRows?.length == 0 && rejectRows?.length == 0) {
        this.appService.showRejectButton.set(true);
      }
    }

  }

}
