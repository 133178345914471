import { AfterViewInit, Component, effect, signal, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { TableFilterEvent, TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CreateTargetCommand, LockedStatus, LockTargetCommand, MetaboliteModuleRow, RejectTargetCommand, TargetSelectionApi, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { filter, first, forkJoin, map, Observable, shareReplay, tap } from 'rxjs';
import { RDKitLoaderService } from '../../../services/rdkit-loader.service';
import { RDKitModule } from '@rdkit/rdkit';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { CardModule } from 'primeng/card';
import { TruncatePipe } from '../../../pipes/TruncatePipe';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { FilterService, MessageService } from 'primeng/api';
import { FormGroupDirective, FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { AppService } from '../../../services/app.service';
import { UserChipComponent } from '../../../user-chip/user-chip.component';
import { ChemdrawWrapperComponent } from '../../../chemdraw-wrapper/chemdraw-wrapper.component';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef, GridApi, ICellRendererParams, IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { GridReadyEvent } from 'ag-grid-enterprise';
import { StatusRenderer } from './status-renderer.component';
import { StructureRenderer, StructureRendererContext } from './structure-renderer.component';
import { StructureFilterComponent } from '../../../structure-filter/structure-filter.component';
import { MSGraphService } from '../../../services/msgraph.service';
import { ActivatedRoute } from '@angular/router';
import { Stepper, StepperModule } from 'primeng/stepper';
import { InputGroupModule } from 'primeng/inputgroup';
import { CountRenderer } from './count-renderer.component';
import { UrlRenderer } from './url-renderer.component';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';


@Component({
  selector: 'app-metabolite-module',
  standalone: true,
  imports: [
    AsyncPipe,
    TableModule,
    CommonModule,
    ResearchComponentsCoreModule,
    BlockUIModule,
    OverlayPanelModule,
    MoleculeStructureComponent,
    CardModule,
    TruncatePipe,
    TooltipModule,
    ButtonModule,
    FloatLabelModule,
    FormsModule,
    BadgeModule,
    ChipModule,
    UserChipComponent,
    ChemdrawWrapperComponent,
    DecimalPipe,
    SliderModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    AgGridAngular,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    InputGroupModule],

  templateUrl: './metabolite-module.component.html',
  styleUrl: './metabolite-module.component.scss'
})
export class MetaboliteModuleComponent implements AfterViewInit {


  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;

  createTargetFormGrp: FormGroup;
  rejectTargetFormGrp: FormGroup;
  first = 0;
  showCreateTarget = false;
  selectedRow: MetaboliteModuleRow;
  msNodeIds: any[] = [];
  gridApi = signal<GridApi<MetaboliteModuleRow> | undefined>(undefined);
  filterModel: any;
  createTargetCmd: CreateTargetCommand;
  rejectTargetCmd: RejectTargetCommand;
  showChemdraw = false;
  creatingTarget: boolean;
  activeStep: number;
  showRejectTarget = false;



  //Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    { field: "mz", headerName: "m/z", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "retentionTime", headerName: "Retention Time" },
    {
      field: "msNodeId", headerName: "MS Node", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.mmdd.navigate(urlValue, field);
        }
      }
    },
    {
      field: "spectralFamilyId", headerName: "Spectral Family", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.mmdd.navigate(urlValue, field);
        }
      }
    },
    { field: "strainID", headerName: "Highest Strain", filter: "agSetColumnFilter", cellDataType: "text" },
    {
      field: "countExactMassMatch", headerName: "# Exact Mass Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigate(msNodeId, type);
        }
      }
    },
    {
      field: "countSpectralMatch", headerName: "# Spectral Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigate(msNodeId, type);
        }
      }
    },
    { field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer, filter: StructureFilterComponent,
      cellRendererParams: {
        context: {
          smilesProp: "spectrumCompoundSMILES",
          compoundNameProp: "spectrumCompoundName",
          showSpectralMatchChip: true,
          matchTypeValue: "sm",
          getSubstructure: () => {
            return this.metabolitesService?.filter?.smiles;
          }
        } as StructureRendererContext
      }
    },
    //{ field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer },
    { field: "cScore", headerName: "C-Score", filter: "agNumberColumnFilter", cellDataType: "number" },
    {
      field: "countGCF", headerName: "# GCF Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigate(msNodeId, type);
        }
      }
    },
    { headerName: "Bioactivity Correlation" },
    { headerName: "HCA-PCA selection" },
    {
      field: "status", headerName: "Status", filter: "agSetColumnFilter", filterValueGetter: (p) => {
        return p.data.status?.status;
      }, cellRenderer: StatusRenderer, cellDataType: "text"
    }
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  constructor(
    private messageService: MessageService,
    public metabolitesService: MetaboliteModuleService,
    private appService: AppService,
    private graphService: MSGraphService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mmdd: MetaboliteDeepDiveService
  ) {

    this.msNodeIds = JSON.parse(this.activatedRoute.snapshot.queryParams['MS_NODE_IDS'] ?? "[]");

    effect(() => {
      console.log('num rows:', this.metabolitesService.filteredData()?.length);

      if (this.metabolitesService.filteredData()?.length > 0 && this.gridApi) {

        setTimeout(() => {
          if (this.filterModel) {
            this.gridApi().setFilterModel(this.filterModel); //put the saved filter back
          }

          if (this.msNodeIds?.length > 0) {
            this.gridApi().setColumnFilterModel("msNodeId", { values: [...this.msNodeIds] });
            this.msNodeIds = [];
          }

          this.gridApi().onFilterChanged(); //apply the filter
        }, null);
      }
    });


    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.filterModel = null;
      this.gridApi()?.setFilterModel(null);
    });

    this.metabolitesService.beforeFilterApplied$.pipe(takeUntilDestroyed()).subscribe(reset => {
      this.first = 0;

      //save the ag grid filter before we filter the data our own way
      if (this.gridApi && !reset) {
        this.filterModel = this.gridApi().getFilterModel();
      }
    });

    //in case someone comes directly to this route
    this.appService.selectModule(this.appService.modules.find(x => x.name == "Metabolites"), false);

    appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      if (this.metabolitesService.selectedData.length == 1 && x == "target") {
        this.selectedRow = this.metabolitesService.selectedData[0];


        this.createTargetCmd = new CreateTargetCommand({
          msNodeId: this.selectedRow.msNodeId,
          batchId: this.selectedRow.batchId,
          strainID: this.selectedRow.strainID,
          predictedStructureSMILES: this.selectedRow.spectrumCompoundSMILES
        });

        this.createTargetFormGrp.reset();

        this.metabolitesService.getCreateTargetInfo(this.selectedRow.msNodeId, undefined).subscribe(x => {
          this.activeStep = 0;
          this.showCreateTarget = true;
        });
      }

      if (x == "rejected") {
        this.rejectTargetFormGrp.reset();
        this.showRejectTarget = true;
      }

      if (x == "locked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.metabolitesService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.Locked
          });
          obs.push(this.metabolitesService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.metabolitesService.updateTargetButtons();
        });
      }

      if (x == "unlocked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.metabolitesService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.UnLocked
          });
          obs.push(this.metabolitesService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.metabolitesService.updateTargetButtons();
        });
      }

    });

    this.createTargetFormGrp = this.fb.group({
      selectionType: [null, Validators.required],
      hypothesis: ['', [Validators.required]],
      type: [null, [Validators.required]],
      adduct: [null],
      gcfId: [null]
    });

    this.rejectTargetFormGrp = this.fb.group({
      rejectReason: [null, Validators.required]
    });

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi.set(params.api);
  }

  ngAfterViewInit(): void {
    //this.metabolitesService.applyFilter();
  }

  copySMILES(SMILES: string) {
    navigator.clipboard.writeText(SMILES);
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: 'SMILES copied to clipboard' });
  }

  dataFiltered($event: TableFilterEvent) {
    this.metabolitesService.updateFilterValues(<MetaboliteModuleRow[]>$event.filteredValue);
  }

  createTarget() {
    this.creatingTarget = true;

    this.createTargetCmd.selectionType = this.createTargetFormGrp.get("selectionType").value;
    this.createTargetCmd.hypothesis = this.createTargetFormGrp.get("hypothesis").value;
    this.createTargetCmd.adduct = this.createTargetFormGrp.get("adduct").value;
    this.createTargetCmd.gcfId = this.createTargetFormGrp.get("gcfId").value;
    this.createTargetCmd.type = this.createTargetFormGrp.get("type").value;


    this.metabolitesService.createTarget(this.createTargetCmd).subscribe(x => {
      Object.assign(this.selectedRow.status, x);
    }, null, () => { this.showCreateTarget = false; this.creatingTarget = false; });
  }

  rejectTargets() {
    let obs: Observable<TargetStatus>[] = [];

    for (let row of this.metabolitesService.selectedData) {
      var cmd = new RejectTargetCommand({
        batchId: this.appService.selectedBatchName(),
        msNodeId: row.msNodeId,
        rejectReason: this.rejectTargetFormGrp.get("rejectReason").value
      });
      obs.push(this.metabolitesService.rejectTarget(cmd, row));
    }

    forkJoin(obs).subscribe(x => {
      this.metabolitesService.updateTargetButtons();
      this.showRejectTarget = false;
    });


  }

  onSelectionChanged($event: SelectionChangedEvent<MetaboliteModuleRow, any>) {
    var rowCount = $event.api.getSelectedNodes().length;
    console.log(rowCount);

    this.metabolitesService.setSelectedData($event.api.getSelectedNodes().map(x => x.data));
  }

  onSubmit() {
    if (this.createTargetFormGrp.valid) {
      this.createTarget();
    }
  }

  updateSmiles() {
    if (this.showChemdraw) {
      this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
        this.createTargetCmd.predictedStructureSMILES = smiles;
      });
      this.showChemdraw = false;
    }
  }

}

