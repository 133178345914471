import { AfterViewInit, Component, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BGCModuleRow, TargetSelectionApi } from '../../../services/target-selection-api/target-selection-api';
import { first } from 'rxjs';
import { TableFilterEvent, TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { AppService } from '../../../services/app.service';
import { BGCModuleService } from '../../../services/bgc-module.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UrlRenderer } from '../../metabolite/metabolite-module/url-renderer.component';

@Component({
  selector: 'app-bgc-module',
  standalone: true,
  imports: [AgGridAngular,
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule
  ],
  templateUrl: './bgc-module.component.html',
  styleUrl: './bgc-module.component.scss'
})
export class BgcModuleComponent implements AfterViewInit {

  gridApi = signal<GridApi<BGCModuleRow> | undefined>(undefined);
  loading: boolean = false;
  data: BGCModuleRow[] = [];
  filterModel: FilterModel;
  first = 0;
  gcfIds: any[] = [];

  colDefs: ColDef[] = [
    { field: "gcfid", headerName: "GCF", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.bgcService.navigate(urlValue, field);
        }
      }
     },
    { field: "countBGC", headerName: "#BGC", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
     },
    { field: "countRefGeneCluster", headerName: "#Ref Gene Cluster", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
     },
    { field: "highestCorrelationScore", headerName: "Highest Correlation Score", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "countMSNodeCorrelated", headerName: "#MS Node Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
     },
    { field: "countTotalStrains", headerName: "#Total Strains", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer },
    { field: "countPredictedCompoundProduced", headerName: "#Predicted Compound Produced", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer }
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  constructor(
    public bgcService: BGCModuleService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute) {

      this.gcfIds = JSON.parse(this.activatedRoute.snapshot.queryParams['GCF_IDS'] ?? "[]");

    effect(() => {
      console.log('num rows:', this.bgcService.filteredData()?.length);

      if (this.bgcService.filteredData()?.length > 0 && this.gridApi) {

        setTimeout(() => {
          if (this.filterModel) {
            this.gridApi().setFilterModel(this.filterModel); //put the saved filter back
          }

          if (this.gcfIds?.length > 0) {
            this.gridApi().setColumnFilterModel("gcfid", { values: [...this.gcfIds] });
            this.gcfIds = [];
          }

          this.gridApi().onFilterChanged(); //apply the filter
        }, null);
      }
    });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.filterModel = null;
      this.gridApi()?.setFilterModel(null);
    });

    this.bgcService.beforeFilterApplied$.pipe(takeUntilDestroyed()).subscribe(reset => {
      this.first = 0;

      //save the ag grid filter before we filter the data our own way
      if (this.gridApi && !reset) {
        this.filterModel = this.gridApi().getFilterModel();
      }
    });

    this.appService.selectModule(this.appService.modules.find(x => x.name == "Biosynthetic Gene Clusters"), false);

  }

  ngAfterViewInit(): void {
  }

  dataFiltered($event: TableFilterEvent) {
    this.bgcService.updateFilterValues(<BGCModuleRow[]>$event.filteredValue);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi.set(params.api);
  }
}
