import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StrainsModuleComponent } from './modules/strains/strains-module/strains-module.component';
import { MetaboliteModuleComponent } from './modules/metabolite/metabolite-module/metabolite-module.component';
import { BgcModuleComponent } from './modules/bgc/bgc-module/bgc-module.component';
import { TargetsModuleComponent } from './modules/targets/targets-module/targets-module.component';
import { LibraryModuleComponent } from './modules/library/library-module/library-module.component';
import { MetaboliteModuleFilterComponent } from './modules/metabolite/metabolite-module-filter/metabolite-module-filter.component';
import { MetaboliteModuleDeepDiveComponent } from './modules/metabolite/metabolite-module-deep-dive/metabolite-module-deep-dive.component';
import { BgcModuleDeepDiveComponent } from './modules/bgc/bgc-module-deep-dive/bgc-module-deep-dive.component';
import { Roles } from './services/user.service';
import { RoleGuard } from './utility/role-guard';
import { AdductsComponent } from './adducts/adducts.component';

export const routes: Routes = [
    {
        path: 'home',
        title: 'Target Selection Tool',
        component: DashboardComponent,
    },
    {
        path: 'strains',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Strains Module',
        component: StrainsModuleComponent,
        canActivate: [RoleGuard]
    },

    {
        path: 'metabolites',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Metabolites Module',
        component: MetaboliteModuleComponent,
        canActivate: [RoleGuard]
        // children: [
        //     {
        //         path: 'buttons',
        //         component: MetaboliteModuleButtonsComponent,
        //         outlet: 'module-buttons'
        //     },
        // ]
    },

    {
        path: 'metabolites-deep-dive',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Metabolites Detail',
        component: MetaboliteModuleDeepDiveComponent,
        canActivate: [RoleGuard]

    },

    {
        path: 'metabolites-filter',
        title: 'Metabolites Module Filter',
        component: MetaboliteModuleFilterComponent,
        outlet: 'filter',
        canActivate: [RoleGuard]
    },
    {
        path: 'bgc',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Biosynthetic Gene Clusters',
        component: BgcModuleComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'bgc-deep-dive',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Biosynthetic Gene Clusters Deep Dive',
        component: BgcModuleDeepDiveComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'targets',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Targets Module',
        component: TargetsModuleComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'library',
        data: {
            reuse: true,
            minimumRole: Roles.Reader
        },
        title: 'Library Module',
        component: LibraryModuleComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'adducts',
        data: {
            reuse: true,
            minimumRole: Roles.Admin
        },
        title: 'Adducts',
        component: AdductsComponent,
        canActivate: [RoleGuard]
    },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
];
