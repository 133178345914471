import { AfterViewInit, Component, effect, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BGCModuleRow, LockedStatus, LockTargetCommand, TargetSelectionApi, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { first, forkJoin, Observable } from 'rxjs';
import { TableFilterEvent, TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { AppService } from '../../../services/app.service';
import { BGCModuleService } from '../../../services/bgc-module.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UrlRenderer } from '../../metabolite/metabolite-module/url-renderer.component';
import { CreateTargetDialogInput, CreateTargetComponent } from '../../targets/create-target/create-target.component';
import { RejectTargetComponent, RejectTargetDialogInput } from "../../targets/reject-target/reject-target.component";
import { StatusRenderer } from '../../metabolite/metabolite-module/status-renderer.component';
import { TargetModuleService } from '../../../services/target-module.service';

@Component({
  selector: 'app-bgc-module',
  standalone: true,
  imports: [AgGridAngular,
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule, RejectTargetComponent, CreateTargetComponent],
  templateUrl: './bgc-module.component.html',
  styleUrl: './bgc-module.component.scss'
})
export class BgcModuleComponent implements AfterViewInit {
  @ViewChild("ctd") ctd: CreateTargetComponent;
  @ViewChild("rtd") rtd: RejectTargetComponent;



  gridApi = signal<GridApi<BGCModuleRow> | undefined>(undefined);
  loading: boolean = false;
  data: BGCModuleRow[] = [];
  filterModel: FilterModel;
  first = 0;
  gcfIds: any[] = [];

  colDefs: ColDef[] = [
    {
      field: "status", headerName: "Status", filter: "agSetColumnFilter", filterValueGetter: (p) => {
        return p.data.status?.status;
      }, cellRenderer: StatusRenderer, cellDataType: "text"
    },
    {
      field: "gcfid", headerName: "GCF ID", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.bgcService.navigate(urlValue, field);
        }
      }
    },
    {
      field: "countBGC", headerName: "# BGC", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
    },
    {
      field: "countRefGeneCluster", headerName: "# Reference BGC", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
    },
    { field: "highestCorrelationScore", headerName: "Max Correlation Score", filter: "agNumberColumnFilter", cellDataType: "number" },
    {
      field: "countMSNodeCorrelated", headerName: "# MS Node Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "gcfid",
        clicked: (gcfid: number, type: string, rowData: any) => {
          this.bgcService.navigate(gcfid, type);
        }
      }
    },
    { field: "countTotalStrains", headerName: "# Strain", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer },
    { field: "countPredictedCompoundProduced", headerName: "# Predicted Compound Produced", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer },
   
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  constructor(
    public bgcService: BGCModuleService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private targetService: TargetModuleService) {

    this.gcfIds = JSON.parse(this.activatedRoute.snapshot.queryParams['GCF_IDS'] ?? "[]");

    effect(() => {
      console.log('num rows:', this.bgcService.filteredData()?.length);

      if (this.bgcService.filteredData()?.length > 0 && this.gridApi) {

        setTimeout(() => {
          if (this.filterModel) {
            this.gridApi().setFilterModel(this.filterModel); //put the saved filter back
          }

          if (this.gcfIds?.length > 0) {
            this.gridApi().setColumnFilterModel("gcfid", { values: [...this.gcfIds] });
            this.gcfIds = [];
          }

          this.gridApi().onFilterChanged(); //apply the filter
        }, null);
      }
    });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.filterModel = null;
      this.gridApi()?.setFilterModel(null);
    });

    this.bgcService.beforeFilterApplied$.pipe(takeUntilDestroyed()).subscribe(reset => {
      this.first = 0;

      //save the ag grid filter before we filter the data our own way
      if (this.gridApi && !reset) {
        this.filterModel = this.gridApi().getFilterModel();
      }
    });

    this.appService.selectModule(this.appService.modules.find(x => x.name == "Biosynthetic Gene Clusters"), false);



    appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      if (this.bgcService.selectedData.length == 1 && x == "target") {
        let selectedRow = this.bgcService.selectedData[0];
        let input = new CreateTargetDialogInput();
        input.batchId = selectedRow.batchId;
        input.rowToUpdate = selectedRow;
        input.gcfId = selectedRow.gcfid;
        input.gcfType = "gcf type here";
        this.ctd.show(input);
      }

      if (x == "rejected") {
        //this.rtd.show(this.metabolitesService.selectedData);

        let input: RejectTargetDialogInput[] = [];

        for (let row of this.bgcService.selectedData) {
          let x = new RejectTargetDialogInput();
          x.batchId = row.batchId;
          x.gcfId = row.gcfid;
          x.rowToUpdate = row;

          input.push(x);
        }

        this.rtd.show(input);

      }

      if (x == "locked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.bgcService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: row.batchId,
            gcfId: row.gcfid,
            status: LockedStatus.Locked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.bgcService.updateTargetButtons();
        });
      }

      if (x == "unlocked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.bgcService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: row.batchId,
            gcfId: row.gcfid,
            status: LockedStatus.UnLocked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.bgcService.updateTargetButtons();
        });
      }

    });




  }

  ngAfterViewInit(): void {
  }

  dataFiltered($event: TableFilterEvent) {
    this.bgcService.updateFilterValues(<BGCModuleRow[]>$event.filteredValue);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi.set(params.api);
  }

  onSelectionChanged($event: SelectionChangedEvent<BGCModuleRow, any>) {
    this.bgcService.setSelectedData($event.api.getSelectedNodes().map(x => x.data));
  }

  targetsRejected() {
  }


  targetCreated() {
  }

}
