import { forwardRef, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, NavigationStart, Router, RouteReuseStrategy } from '@angular/router';
import { filter } from 'lodash';
import { BrowserNavButtonsService } from './browser-nav-buttons.service';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private savedHandles = new Map<string, DetachedRouteHandle>();
    private browserNavClicked = false;

    constructor(private navBtsService: BrowserNavButtonsService) {
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (route.data["reuse"] === true && Object.keys(route.queryParams).length == 0) {
          return true;
        }

        return false;
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        if (!handle) {
          return;
        }

        const key = this.getRouteKey(route);
        this.savedHandles.set(key, handle);
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        let sa = (Object.keys(route.queryParams).length == 0) && this.savedHandles.has(this.getRouteKey(route));
        return sa;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        let sHandle = this.savedHandles.get(this.getRouteKey(route));
        return sHandle;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.routeConfig && curr.routeConfig && future.routeConfig === curr.routeConfig &&
            Object.keys(curr.queryParams).length == 0 && Object.keys(future.queryParams).length == 0) {
              return true;
        }

        return false;
    }

    private getRouteKey(route: ActivatedRouteSnapshot): string {
        const key = route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
        console.log(key);
        return key;
    }

}
