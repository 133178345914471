import { Component } from "@angular/core";
import { UserChipComponent } from "../../../user-chip/user-chip.component";
import { TargetStatus } from "../../../services/target-selection-api/target-selection-api";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { BadgeModule } from "primeng/badge";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  standalone: true,
  imports: [
    BadgeModule,
  ],
  template: `
        @if(value == "YES") {
        <p-badge [value]="value" severity="success"  />
        }
        @if(value == "NO") {
          <p-badge [value]="value" severity="danger"  />
        }
    `,
  styles: `
  
    `
})
export class BiodataRenderer implements ICellRendererAngularComp {
  value: string;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.value = params.value;
    return true;
  }


}
