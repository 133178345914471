<div
  #molSVG
  class="molecule-structure-svg"
  [title]="this.structure"
  [ngStyle]="{
    width: drawingDetails.width + 'px',
    height: drawingDetails.height + 'px'
  }"
  [innerHtml]="safeSVG"
></div>
