import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonModule } from 'primeng/button';
import { filter, Subject } from 'rxjs';

@Component({
  selector: 'app-chemdraw-wrapper',
  standalone: true,
  imports: [

    ButtonModule
  ],
  templateUrl: './chemdraw-wrapper.component.html',
  styleUrl: './chemdraw-wrapper.component.scss'
})
export class ChemdrawWrapperComponent implements AfterViewInit {
  @ViewChild("cdElement") cdElement: ElementRef;
  @Input() smiles: string;
  @Output() smilesChange = new EventEmitter<string>();

  public chemdraw: any;
  chemdrawReady$ = new Subject<boolean>();


  constructor() {
    this.chemdrawReady$.pipe(takeUntilDestroyed()).subscribe(ready => {
      if (ready) {
        this.chemdraw.fitToContainer();
        if (this.smiles) {
          this.chemdraw.loadSMILES(this.smiles, (cdxml: any, error: any) => {
            console.log(error);
          });
        }
      }


    });


  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      window.perkinelmer.ChemdrawWebManager.attach({
        id: "chemdrawjs-container",
        callback: (cd: any) => {
          this.chemdraw = cd;
          this.chemdraw.fitToContainer();
          this.chemdrawReady$.next(true);
        },
        errorCallback: (err: any) => { },
        licenseUrl: "assets/chemdrawweb/ChemDraw-JS-License[5].xml",
        preservePageInfo: false,
      });

    });
  }


  ngOnInit(): void {

  }

  update() {
    this.chemdraw.getSMILES((smiles: any, error: any) => {
      this.smilesChange.emit(smiles);
    });

  }





}
