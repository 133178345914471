import { graphApiResourceMap, loggerCallback } from './common';
import { AppSettings } from '../app/app.settings';
import { ProtectedResourceScopes } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel } from '@azure/msal-browser';

export const lcSettings: AppSettings = {
  baseUrl: 'https://localhost:5005',
  environment: 'local',
  isProd: false,
  appInsightsInstrumentationKey: "d8e81bfd-3484-48b7-92eb-1a90256746ba",
  msal: {
    config: {
      auth: {
        clientId: "66141d9f-6893-4219-b76a-0db890ac2d4c",
        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
        redirectUri: "https://localhost:4200/home",
        postLogoutRedirectUri: "https://localhost:4200/",
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    },
    guard: {
      interactionType: InteractionType.Redirect
    },
    interceptor: {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>([
        graphApiResourceMap,
        [
          'https://localhost:5005',
          ['api://8dd31af8-2205-467b-88c2-c7bb4a9b11b3/API.Call']
        ],
      ])
    }
  }



};
