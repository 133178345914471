import { LogLevel } from '@azure/msal-browser';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export enum Environment {
  Local = 'lc',
  Development = 'dv',
  Test = 'tst'
  //   ProofOfConcept = 'poc',
  //   QualityAssurance = 'qa',
  //   Training = 'tr',
  //   Production = 'pr'
}

export const environmentMonikerRegex: RegExp =
  /https?:\/\/[a-zA-Z\d-]+?(-poc|-dev|-test|-train)?\./;

export function getEnvironment(): Environment {
  const url: string = window.location.href;

  if (url.includes('localhost')) return Environment.Local;

  const matches: RegExpMatchArray | null = url.match(environmentMonikerRegex);

  if (matches == null) {
    throw new Error('Could not parse URL to retrieve environment');
  }

  const environmentMoniker: string | null = matches[1];

  //   if (environmentMoniker == null) {
  //     return Environment.Production;
  //   }

  switch (environmentMoniker) {
    // case '-poc':
    //   return Environment.ProofOfConcept;
    case '-dev':
      return Environment.Development;
    case '-test':
      return Environment.Test;
    // case '-train':
    //   return Environment.Training;
    default:
      throw new Error(`Unrecognized environment moniker: ${environmentMoniker}`);
  }
}

export const graphApiResourceMap: [string, string[]] = [
  'https://graph.microsoft.com/',
  [
    'User.Read',
    // 'People.Read'
    //'User.Read.All',
    'User.ReadBasic.All',
    // 'Directory.Read.All',
    // 'Group.Read.All'
    'ProfilePhoto.Read.All'
  ]
];
