import { Component, ViewChild } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DecimalPipe } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { AppService, TSModule } from '../services/app.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [ButtonModule, BadgeModule, ChipModule, DecimalPipe, TooltipModule, CardModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  strainsCount = 0;
  metabolitesCount = 0;
  bgcCount = 0;

  /**
   *
   */
  constructor(public appService: AppService) {


  }

  selectModule(module: TSModule) {
    this.appService.selectModule(module);
  }

  getModuleNameRemoveSpaces(name: string) {
    return name = name.replace(/\s+/g, '');
  }

}