import { lcSettings } from './../../../../settings/settings.lc';


import { AfterViewInit, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridFilterModel, GetLibraryRowsQuery, LibraryModuleRow, StructureSearchTypeEnum, TargetSelectionApi } from '../../../services/target-selection-api/target-selection-api';
import { TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent, ITextFilterParams, NumberFilter, SelectionChangedEvent } from 'ag-grid-enterprise';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StructureRenderer, StructureRendererContext } from '../../metabolite/metabolite-module/structure-renderer.component';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { LibraryModuleFilterComponent, LibraryModuleFilterComponentData } from '../library-module-filter/library-module-filter.component';
import { AppService } from '../../../services/app.service';
import { LoaderService } from '../../../services/loader.service';
import { first, forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { UrlRenderer } from '../../metabolite/metabolite-module/url-renderer.component';
import _ from 'lodash';

@Component({
  selector: 'app-library-module',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    MoleculeStructureComponent,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule,
    AgGridAngular
],
  templateUrl: './library-module.component.html',
  styleUrl: './library-module.component.scss'
})

export class LibraryModuleComponent implements AfterViewInit {

  readonly SIMILARITY_COLUMN_ID: string = 'similarity';

  lmRows: LibraryModuleRow[] = [];
  structureSearchType = StructureSearchType;
  colDefs: ColDef[] = [];
  gridApi: GridApi;
  inputCompoundIds: string[];
  modifiedFilters: Set<string> = new Set<string>();
  lmRowsBeforeFiltersModified: LibraryModuleRow[] = [];
  filterModel: FilterModel;
  structureFilterData: LibraryModuleFilterComponentData  = {
    searchSmiles: undefined,
    selectedSearchType: 0,
    similarityThreshold: 0
  };
  public defaultFilterData: LibraryModuleFilterComponentData  = {
    searchSmiles: undefined,
    selectedSearchType: StructureSearchType.Substructure,
    similarityThreshold: 0.5
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private targetSelectionApi: TargetSelectionApi,
    private appService: AppService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {

    if (this.activatedRoute.snapshot.queryParams['paramsGUID']) {
      this.inputCompoundIds = this.appService.getParameters(this.activatedRoute.snapshot.queryParams['paramsGUID']);
    }
    else {
      this.inputCompoundIds = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "COMPOUND_IDS");
    }

    this.setDefaults();
    this.updateColDefs();
    this.getData();

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  ngAfterViewInit(): void {

  }

  setDefaults() {
    this.structureFilterData = _.cloneDeep(this.defaultFilterData);
    // this.structureFilterData.searchSmiles = undefined;
    // this.structureFilterData.selectedSearchType = StructureSearchType.Substructure;
    // this.structureFilterData.similarityThreshold = 0.5;
  }

  getData() {
    let batchName = this.appService.selectedBatchName();
    if (!batchName || batchName.length < 1) {
      return;
    }

    this.lmRows = null;
    this.loaderService.setLoading(true);

    let lrQuery: GetLibraryRowsQuery = new GetLibraryRowsQuery();
    let useFilterQuery = false;
    if (this.inputCompoundIds?.length > 0) {
      lrQuery.compoundIds = this.inputCompoundIds;
      useFilterQuery = true;
    }

    if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Substructure) {
      lrQuery.structureSmiles = this.structureFilterData.searchSmiles;
      lrQuery.structureSearchType = StructureSearchTypeEnum.Substructure;
      useFilterQuery = true;
    }
    else if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Exact) {
      lrQuery.structureSmiles = this.structureFilterData.searchSmiles;
      lrQuery.structureSearchType = StructureSearchTypeEnum.Exact;
      useFilterQuery = true;
    }
    else if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Similarity && this.structureFilterData.similarityThreshold > 0) {
      lrQuery.structureSmiles = this.structureFilterData.searchSmiles;
      lrQuery.structureSearchType = StructureSearchTypeEnum.Similarity;
      lrQuery.similarityThreshold = this.structureFilterData.similarityThreshold;
      useFilterQuery = true;
    }

    if (this.filterModel) {
      let filterKeys = Object.keys(this.filterModel);
      if (filterKeys?.length > 0) {
        let agFilters: AgGridFilterModel[] = [];
        for (const fk of filterKeys) {

          if (!this.passFilterToApi(fk)) {
            continue;
          }

          let fm = this.filterModel[fk];

          if (fk == 'structure' && !fm.searchSmiles) {
            continue;
          }

          let agFilter: AgGridFilterModel = Object.assign(new AgGridFilterModel(), fm);
          if (fm['conditions']) {
            agFilter.conditions = [];
            for (const cnd of fm['conditions']) {
              agFilter.conditions.push(Object.assign(new AgGridFilterModel(), cnd));
            }
          }
          agFilter.columnName = fk;
          agFilters.push(agFilter);
          useFilterQuery = true;
        }
        lrQuery.agGridFilters = agFilters;
      }
    }

    if (!useFilterQuery) {
      this.targetSelectionApi.getLibraryModuleRows(batchName).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }
    else {
      this.targetSelectionApi.getLibraryModuleRowsFiltered(batchName, lrQuery).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }

  }

  setData(rows: LibraryModuleRow[]) {

    if (rows?.length < 1) {
      this.messageService.add({ severity: 'warn', summary: 'No Matches', detail: "No matches found, try different filters", sticky: true });
    }

    this.lmRows = rows;
    this.loaderService.setLoading(false);

    if (this.gridApi) {
      let showSimilarity: boolean = !!this.structureFilterData.searchSmiles && this.structureFilterData.searchSmiles.length > 0 && this.structureFilterData.selectedSearchType == this.structureSearchType.Similarity;

      this.gridApi.applyColumnState({
        state: [{
          colId: this.SIMILARITY_COLUMN_ID,
          hide: !showSimilarity
        }]
      });

      if (!showSimilarity) {
        this.gridApi.setColumnFilterModel(this.SIMILARITY_COLUMN_ID, null);
      }

      let similarityColDef = this.gridApi.getColumnDef(this.SIMILARITY_COLUMN_ID);
      similarityColDef.suppressColumnsToolPanel = !showSimilarity;
      similarityColDef.suppressFiltersToolPanel = !showSimilarity;
      this.gridApi.refreshToolPanel();
    }
  }

  handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
    this.loaderService.setLoading(false);
  }

  navigateToMetaboliteModule(msnodes: number[]) {

    if (!msnodes || msnodes.length < 1) return;

    this.appService.navigateToModuleWithParams(msnodes.map(String), "metabolites");
  }

  onSelectionChanged(event: SelectionChangedEvent<LibraryModuleRow, any>) {
    var rowCount = event.api.getSelectedNodes().length;
  }

  passFilterToApi(colId: string) : boolean {
    if (colId != "compoundId" && colId != "compoundNames" && colId != "compoundFamilyId" && colId != "structure") {
      return false;
    }

    return true;
  }

  onFilterModified(event: any) {
    let colId = event.column.colId;

    if (!this.passFilterToApi(colId)) {
      return;
    }

    let uiModel = event.filterInstance.getModelFromUi();
    let model = event.filterInstance.getModel();

    this.filterModified(colId, model, uiModel);

    // let uiModelString = uiModel ? JSON.stringify(uiModel) : null;
    // let modelString = model ? JSON.stringify(model) : null;

    // if ((!uiModel && !model) ||
    //     (uiModelString && modelString && uiModelString == modelString)) {

    //   let colRemoved: boolean = this.modifiedFilters.delete(colId);

    //   // reset if the filter isn't changed
    //   if (colRemoved && this.modifiedFilters.size == 0) {
    //     this.messageService.clear();
    //     this.lmRows = this.lmRowsBeforeFiltersModified;
    //     this.lmRowsBeforeFiltersModified = null;
    //   }

    //   return;
    // }

    // this.filterModified(colId);
  }

  filterModified(colId: string, model: any, uiModel: any) {
    if (!this.passFilterToApi(colId)) {
      return;
    }

    let uiModelString = uiModel ? JSON.stringify(uiModel) : null;
    let modelString = model ? JSON.stringify(model) : null;

    if ((!uiModel && !model) ||
        (uiModelString && modelString && uiModelString == modelString)) {

      let colRemoved: boolean = this.modifiedFilters.delete(colId);

      // reset if the filter isn't changed
      if (colRemoved && this.modifiedFilters.size == 0) {
        this.messageService.clear();
        this.lmRows = this.lmRowsBeforeFiltersModified;
        this.lmRowsBeforeFiltersModified = null;
      }

      return;
    }

    if (!this.modifiedFilters.has(colId)) {

      if (this.modifiedFilters.size == 0) {
        this.messageService.add({ severity: 'warn', summary: 'Apply All Filters', detail: "Apply all filters to retrieve matching data", sticky: true, closable: true });
        this.lmRowsBeforeFiltersModified = this.lmRows;
        this.lmRows = [];
      }

      this.modifiedFilters.add(colId);
    }
  }

  onFilterChanged(event: any) {
    if (this.modifiedFilters.size < 1) {
      return;
    }

    for (const col of event.columns) {
      this.filterChanged(col.colId);
    }
  }

  filterChanged(colId: string) {
    let colRemoved: boolean = this.modifiedFilters.delete(colId);

    if (colRemoved && this.modifiedFilters.size == 0) {
      this.messageService.clear();
      this.filterModel = this.gridApi.getFilterModel();
      this.lmRowsBeforeFiltersModified = null;
      this.getData();
    }
  }

  updateColDefs() {
    this.colDefs = [];

    this.colDefs.push({ field: "compoundId", headerName: "Compound ID", filter: "agTextColumnFilter", cellDataType: "text",
      filterParams: {
        buttons: ["apply", "clear"],
        closeOnApply: true,
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith']
      } as ITextFilterParams
    });
    this.colDefs.push(
      {
        field: "spectralMatchMsNodes.length", headerName: "Spectral Library Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
        cellRendererParams: {
          clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
            if (lmRow.spectralMatchMsNodes?.length) {
              this.navigateToMetaboliteModule(lmRow.spectralMatchMsNodes);
            }
          }
        }
      }
    );
    this.colDefs.push(
      {
        field: "exactMatchMsNodes.length", headerName: "EMM", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
        cellRendererParams: {
          clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
            if (lmRow.exactMatchMsNodes?.length) {
              this.navigateToMetaboliteModule(lmRow.exactMatchMsNodes);
            }
          }
        }
      }
    );
    this.colDefs.push({ field: "compoundNames", headerName: "Compound Name", filter: "agTextColumnFilter", cellDataType: "text",
      filterParams: {
        buttons: ["apply", "clear"],
        closeOnApply: true,
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith']
      } as ITextFilterParams
     });
    this.colDefs.push({ field: "structure", headerName: "Structure", cellRenderer: StructureRenderer, filter: LibraryModuleFilterComponent,
                        suppressFiltersToolPanel: this.inputCompoundIds?.length > 0, suppressHeaderFilterButton: this.inputCompoundIds?.length > 0,
      cellRendererParams: {
        context: {
          smilesProp: "structure",
          compoundNameProp: null,
          showSpectralMatchChip: false,
          getSubstructure: () => {
            return (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == this.structureSearchType.Substructure ? this.structureFilterData.searchSmiles : undefined);
          }
        } as StructureRendererContext
      },
      filterParams : {
        context : this
      }
     });

    this.colDefs.push({ field: this.SIMILARITY_COLUMN_ID, headerName: "Similarity", filter: "agNumberColumnFilter", cellDataType: "number",
                        valueFormatter: params => params?.data?.similarity ? params.data.similarity.toFixed(3) : null });

    this.colDefs.push({ headerName: "Bioactivity", filter: "agSetColumnFilter", cellDataType: "text",
                        valueGetter: p => p.data.hasBioactivity ? 'Yes' : undefined,
                        filterValueGetter: p => p.data.hasBioactivity ? 'Yes' : '(Blanks)',
                        filterParams: {
                          values: ['Yes', '(Blanks)']
                        }
                      });

    //this.colDefs.push({ field: "", headerName: "MIBIG Link", filter: "agNumberColumnFilter", cellDataType: "number" });
    this.colDefs.push({ field: "compoundFamilyId", headerName: "Compound Family ID", filter: "agTextColumnFilter", cellDataType: "text",
      filterParams: {
        buttons: ["apply", "clear"],
        closeOnApply: true,
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith']
      } as ITextFilterParams
     });

    this.colDefs.push({
          field: "spectralMatchStrainIds.length", headerName: "Spectral Matched Strains", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
          cellRendererParams: {
            clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
              if (lmRow.spectralMatchStrainIds?.length) {
                const queryParams: any = {};
                queryParams["STRAIN_IDS"] = lmRow.spectralMatchStrainIds;
                this.appService.navigateToModuleWithParams(queryParams, "strains");
              }
            }
          }
        });

    this.colDefs.push({
      field: "exactMatchStrainIds.length", headerName: "Exact Mass Match Strains", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
          if (lmRow.exactMatchStrainIds?.length) {
            const queryParams: any = {};
            queryParams["STRAIN_IDS"] = lmRow.exactMatchStrainIds;
            this.appService.navigateToModuleWithParams(queryParams, "strains");
          }
        }
      }
    });

    this.colDefs.push({ field: 'molecularWeight', headerName: "Molecular Weight", filter: "agNumberColumnFilter", cellDataType: "number",
      valueFormatter: params => params?.data?.molecularWeight ? params.data.molecularWeight.toFixed(3) : null });

    this.colDefs.push({ field: 'molecularFormula', headerName: "Molecular Formula", filter: "agTextColumnFilter", cellDataType: "text" });

    this.colDefs.push({ field: 'materialId', headerName: "Material ID", filter: "agNumberColumnFilter", cellDataType: "number" });
  }
}

export const StructureSearchType = {
  Substructure: 0,
  Similarity: 1,
  Exact: 2
};
