import { Component, effect, OnInit, signal } from '@angular/core';
import { AdductService } from '../services/adduct.service';
import { MessageService } from 'primeng/api';
import { AppService } from '../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoaderService } from '../services/loader.service';
import { ColDef, SelectionChangedEvent } from 'ag-grid-enterprise';
import { Adduct, UpdateAdductCommand } from '../services/target-selection-api/target-selection-api';
import { AgGridAngular } from 'ag-grid-angular';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { first, forkJoin } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-adducts',
  standalone: true,
  imports: [
    AgGridAngular,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputTextModule,
    ButtonModule,
    TooltipModule
  ],
  templateUrl: './adducts.component.html',
  styleUrl: './adducts.component.scss'
})
export class AdductsComponent implements OnInit {


  selAdduct = signal<Adduct | undefined>(undefined);
  frmGrp: FormGroup;
  updating = false;
  hasChange = signal(false);
  initialValue: any;
  changing = false;
  adducts = signal<Adduct[] | undefined>(undefined);


  colDefs: ColDef[] = [

    { field: "id", headerName: "ID", cellDataType: "number" },
    { field: "name", headerName: "Name", cellDataType: "text" },
    { field: "mass", headerName: "Mass", cellDataType: "number" },
    { field: "multiple", headerName: "Multiple", cellDataType: "number" },

  ];


  constructor(
    private messageService: MessageService,
    public adductService: AdductService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) {

    

    this.frmGrp = this.fb.group({
      adductName: [null, Validators.required],
      adductMass: [null, [Validators.required]],
      adductMultiple: [null, [Validators.required]],
    });


    effect(() => {
      if (this.selAdduct()) {
        this.changing = true;

        this.frmGrp.setValue({
          adductName: this.selAdduct().id ? this.selAdduct().name : null,
          adductMass: this.selAdduct().id ? this.selAdduct().mass : null,
          adductMultiple: this.selAdduct().id ? this.selAdduct().multiple : null,
        });

        this.changing = false;

        this.initialValue = this.frmGrp.value;
      }
    });

    this.frmGrp.valueChanges.subscribe(value => {
      this.hasChange.set(!this.changing && Object.keys(this.initialValue).some(key => this.frmGrp.value[key] !=
        this.initialValue[key]));
    });

  }

  ngOnInit(): void {
    this.getAdducts();
  }

  reset() {
    this.frmGrp.reset();
    this.selAdduct.set(undefined);
    this.getAdducts();
  }

  getAdducts() {
    this.adductService.getAdducts().pipe(first()).subscribe(x => {
      this.adducts.set(x);
    });
  }

  onSelectionChanged($event: SelectionChangedEvent<Adduct, any>) {
    let selRows = $event.api.getSelectedRows();
    if (selRows?.length == 1) {
      this.selAdduct.set(selRows[0]);
    }
  }

  getAdductFromForm(): Adduct {
    return new Adduct({
      id: this.selAdduct()?.id,
      name: this.frmGrp.get("adductName").value,
      mass: this.frmGrp.get("adductMass").value,
      multiple: this.frmGrp.get("adductMultiple").value
    })
  }

  onSubmit() {
    if (!this.selAdduct()) return;

    if (this.selAdduct().id) {
      this.updating = true;
      this.adductService.updateAdduct(new UpdateAdductCommand({ adduct: this.getAdductFromForm() })).subscribe(x => {
        this.selAdduct.set(x);
      }, (e) => { }, () => {
        this.reset();
        this.updating = false;
        this.messageService.add({ severity: 'info', summary: 'Update', detail: 'Adduct updated.' });
      });
    }
    else {
      this.updating = true;
      this.adductService.creteAdduct(new UpdateAdductCommand({ adduct: this.getAdductFromForm() })).subscribe(x => {
        this.selAdduct.set(x);
      }, (e) => { }, () => {
        this.reset();
        this.updating = false;
        this.messageService.add({ severity: 'info', summary: 'Create', detail: 'Adduct created.' });
      });

    }
  }

  addClick() {
    this.selAdduct.set(new Adduct());
  }

  deleteClick() {
    if (!this.selAdduct()) return;

    this.updating = true;
    this.adductService.deleteAdduct(this.selAdduct().id).subscribe(x => {
    }, (e) => { }, () => {
      this.reset();
      this.updating = false;
      this.messageService.add({ severity: 'info', summary: 'Delete', detail: 'Adduct deleted.' });  
    });
  }
}
