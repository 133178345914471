

import { AfterViewInit, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryModuleRow, TargetSelectionApi } from '../../../services/target-selection-api/target-selection-api';
import { TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StructureRenderer, StructureRendererContext } from '../../metabolite/metabolite-module/structure-renderer.component';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { LibraryModuleFilterComponent, LibraryModuleFilterComponentData } from '../library-module-filter/library-module-filter.component';
import { AppService } from '../../../services/app.service';
import { LoaderService } from '../../../services/loader.service';
import { first } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-library-module',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    MoleculeStructureComponent,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule,
    AgGridAngular
],
  templateUrl: './library-module.component.html',
  styleUrl: './library-module.component.scss'
})

export class LibraryModuleComponent implements AfterViewInit {

  readonly SIMILARITY_COLUMN_ID: string = 'similarity';

  lmRows: LibraryModuleRow[] = [];
  structureSearchType = StructureSearchType;
  colDefs: ColDef[] = [];
  gridApi: GridApi;
  inputCompoundIds: string[];
  structureFilterData: LibraryModuleFilterComponentData  = {
    searchSmiles: undefined,
    selectedSearchType: 0,
    similarityThreshold: 0
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private targetSelectionApi: TargetSelectionApi,
    private appService: AppService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {

    this.inputCompoundIds = JSON.parse(this.activatedRoute.snapshot.queryParams['COMPOUND_IDS'] ?? "[]");
    this.setDefaults();
    this.updateColDefs();
    this.getData();

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  ngAfterViewInit(): void {

  }

  setDefaults() {
    this.structureFilterData.searchSmiles = undefined;
    this.structureFilterData.selectedSearchType = StructureSearchType.Substructure;
    this.structureFilterData.similarityThreshold = 0.5;
  }

  getData() {
    let batchName = this.appService.selectedBatchName();
    if (!batchName || batchName.length < 1) {
      return;
    }

    this.lmRows = null;
    this.loaderService.setLoading(true);

    if (this.inputCompoundIds?.length > 0) {
      this.targetSelectionApi.getLibraryModuleRowsForCompoundIds(batchName, this.inputCompoundIds).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }
    else if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Substructure) {
      this.targetSelectionApi.getLibraryModuleRowsSubStructureSearch(batchName, this.structureFilterData.searchSmiles).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }
    else if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Exact) {
      this.targetSelectionApi.getLibraryModuleRowsExactStructureSearch(batchName, this.structureFilterData.searchSmiles).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }
    else if (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == StructureSearchType.Similarity && this.structureFilterData.similarityThreshold > 0) {
      this.targetSelectionApi.getLibraryModuleRowsSimilaritySearch(batchName, this.structureFilterData.searchSmiles, this.structureFilterData.similarityThreshold).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }
    else {
      this.targetSelectionApi.getLibraryModuleRows(batchName).pipe(first()).subscribe(rows => {
        this.setData(rows);
      }, error => {this.handleError(error);})
    }

  }

  setData(rows: LibraryModuleRow[]) {

    this.lmRows = rows;
    this.loaderService.setLoading(false);

    if (this.gridApi) {
      let showSimilarity: boolean = !!this.structureFilterData.searchSmiles && this.structureFilterData.searchSmiles.length > 0 && this.structureFilterData.selectedSearchType == this.structureSearchType.Similarity;

      this.gridApi.applyColumnState({
        state: [{
          colId: this.SIMILARITY_COLUMN_ID,
          hide: !showSimilarity
        }]
      });

      if (!showSimilarity) {
        this.gridApi.setColumnFilterModel(this.SIMILARITY_COLUMN_ID, null);
      }

      let similarityColDef = this.gridApi.getColumnDef(this.SIMILARITY_COLUMN_ID);
      similarityColDef.suppressColumnsToolPanel = !showSimilarity;
      similarityColDef.suppressFiltersToolPanel = !showSimilarity;
      this.gridApi.refreshToolPanel();
    }
  }

  handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
    this.loaderService.setLoading(false);
  }

  navigateToMetaboliteModule(msnodes: number[]) {

    if (!msnodes || msnodes.length < 1) return;

    const queryParams: any = {};
    queryParams.MS_NODE_IDS = JSON.stringify(msnodes.map(String));

    const navigationExtras: NavigationExtras = {
      queryParams
    };

    this.router.navigate(['/metabolites'], navigationExtras);
  }

  onSelectionChanged($event: SelectionChangedEvent<LibraryModuleRow, any>) {
    var rowCount = $event.api.getSelectedNodes().length;
  }

  updateColDefs() {
    this.colDefs = [];

    this.colDefs.push({ field: "compoundId", headerName: "Compound ID", filter: "agTextColumnFilter", cellDataType: "text" });
    this.colDefs.push(
      {
        field: "spectralMatchMsNodes.length", headerName: "Spectral Library Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
        cellRendererParams: {
          clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
            if (lmRow.spectralMatchMsNodes?.length) {
              this.navigateToMetaboliteModule(lmRow.spectralMatchMsNodes);
            }
          }
        }
      }
    );
    this.colDefs.push(
      {
        field: "exactMatchMsNodes.length", headerName: "EMM", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
        cellRendererParams: {
          clicked: (key: number, field: string, lmRow: LibraryModuleRow) => {
            if (lmRow.exactMatchMsNodes?.length) {
              this.navigateToMetaboliteModule(lmRow.exactMatchMsNodes);
            }
          }
        }
      }
    );
    this.colDefs.push({ field: "compoundNames", headerName: "Compound Name", filter: "agTextColumnFilter", cellDataType: "text" });
    this.colDefs.push({ field: "structure", headerName: "Structure", cellRenderer: StructureRenderer, filter: LibraryModuleFilterComponent,
                        suppressFiltersToolPanel: this.inputCompoundIds?.length > 0, suppressHeaderFilterButton: this.inputCompoundIds?.length > 0,
      cellRendererParams: {
        context: {
          smilesProp: "structure",
          compoundNameProp: null,
          showSpectralMatchChip: false,
          getSubstructure: () => {
            return (this.structureFilterData.searchSmiles?.length > 0 && this.structureFilterData.selectedSearchType == this.structureSearchType.Substructure ? this.structureFilterData.searchSmiles : undefined);
          }
        } as StructureRendererContext
      },
      filterParams : {
        context : this
      }
     });

    this.colDefs.push({ field: this.SIMILARITY_COLUMN_ID, headerName: "Similarity", filter: "agNumberColumnFilter", cellDataType: "number",
                        valueFormatter: params => params?.data?.similarity ? params.data.similarity.toFixed(3) : null });

    this.colDefs.push({ headerName: "Bioactivity", filter: "agSetColumnFilter", cellDataType: "text",
                        valueGetter: p => p.data.hasBioactivity ? 'Yes' : undefined,
                        filterValueGetter: p => p.data.hasBioactivity ? 'Yes' : '(Blanks)',
                        filterParams: {
                          values: ['Yes', '(Blanks)']
                        }
                      });

    //this.colDefs.push({ field: "", headerName: "MIBIG Link", filter: "agNumberColumnFilter", cellDataType: "number" });
    this.colDefs.push({ field: "compoundFamilyId", headerName: "Compound Family ID", filter: "agTextColumnFilter", cellDataType: "text" });
    this.colDefs.push({ field: "spectralMatchStrainIds.length", headerName: "Spectral Matched Strains", filter: "agNumberColumnFilter", cellDataType: "number" });
    this.colDefs.push({ field: "exactMatchStrainIds.length", headerName: "Exact Mass Match Strain", filter: "agNumberColumnFilter", cellDataType: "number" });

  }
}

export const StructureSearchType = {
  Substructure: 0,
  Similarity: 1,
  Exact: 2
};
