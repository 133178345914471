import { AfterViewInit, Component, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryModuleRow, StrainModuleRow, TargetSelectionApi } from '../../../services/target-selection-api/target-selection-api';
import { TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StructureRenderer, StructureRendererContext } from '../../metabolite/metabolite-module/structure-renderer.component';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { AppService } from '../../../services/app.service';
import { LoaderService } from '../../../services/loader.service';
import { first } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TargetModuleService } from '../../../services/target-module.service';

@Component({
  selector: 'app-strains-module',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule,
    AgGridAngular
  ],
  templateUrl: './strains-module.component.html',
  styleUrl: './strains-module.component.scss'
})
export class StrainsModuleComponent {

  strainRows: StrainModuleRow[] = [];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };
  strainIds: any[] = [];
  batchId: string;
  gridApi: GridApi<StrainModuleRow>;
  filterModel: FilterModel;
  colDefs: ColDef[] = [
    {
      field: "strainId", headerName: "Strain ID", filter: "agSetColumnFilter", cellDataType: "text"
    },
    {
      headerName: "Strain Name"
    },
    {
      field: "taxonomy", headerName: "Taxonomy", filter: "agTextColumnFilter", cellDataType: "text"
    },
    {
      headerName: "Fraction"
    },
    { field: "countMsNode", headerName: "# MS Node", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "strainId",
        clicked: (strainId: number, type: string, rowData: any) => {
          this.navigateFromCount(strainId, type);
        }
      }
    },
    {
      field: "countGCF", headerName: "# GCF", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "strainId",
        clicked: (strainId: number, type: string, rowData: any) => {
          this.navigateFromCount(strainId, type);
        }
      }
    },
    { field: "countSpectralMatch", headerName: "# Spectral Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "strainId",
        clicked: (strainId: number, type: string, rowData: any) => {
          this.navigateFromCount(strainId, type);
        }
      }
    },
    {
      headerName: "AntiSmash Output"
    },
    { field: "countRefGeneClusterMatch", headerName: "# Ref Gene Cluster Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer },
    {
      field:"countTarget", headerName: "Target", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "strainId",
        clicked: (strainId: number, type: string, rowData: any) => {
          this.navigateFromCount(strainId, type);
        }
      }
    },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private targetSelectionApi: TargetSelectionApi,
    private appService: AppService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private targetService: TargetModuleService
  ) {

    if (this.activatedRoute.snapshot.queryParams['paramsGUID']) {
      let params = this.appService.getParameters(this.activatedRoute.snapshot.queryParams['paramsGUID']);
      if (params) {
        this.strainIds = params['STRAIN_IDS'] ?? [];
        this.batchId = params['batchId'];
      }
    }
    else {
      this.strainIds = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "STRAIN_IDS") ?? [];
      this.batchId = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "batchId");
    }

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

    targetService.targetsUpdated$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

  }

  ngAfterViewInit(): void {

    if (this.batchId) {
      this.appService.selectBatch(this.batchId);
    }

    this.getData();
  }

  getData() {
    let batchName = this.appService.selectedBatchName();
    if (!batchName || batchName.length < 1) {
      return;
    }

    this.strainRows = null;
    this.loaderService.setLoading(true);

    this.targetSelectionApi.getStrainModuleRow(batchName).pipe(first()).subscribe(rows => {
      this.strainRows = rows;
      this.loaderService.setLoading(false);
      this.setFilter();
    }, error => {this.handleError(error);});
  }

  handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
    this.loaderService.setLoading(false);
  }

  setFilter() {
    if (this.strainIds?.length > 0 && this.gridApi && this.strainRows) {
      this.loaderService.setLoading(true);
      setTimeout(() => {
        this.gridApi.setColumnFilterModel("strainId", { values: [...this.strainIds] }).then(res => this.gridApi.onFilterChanged());
        this.strainIds = [];
        this.loaderService.setLoading(false);
      }, 100)
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  navigateFromCount(strainId: number, type: string) {
    this.loaderService.setLoading(true);
    this.targetSelectionApi.getStrainLinkIds(this.appService.selectedBatchName(), strainId.toString(), type).subscribe(x => {

      this.loaderService.setLoading(false);

      if (!x || x.length == 0) {
        this.messageService.add({ severity: 'info', summary: 'Not Found', detail: 'No matches found' });
        return;
      }

      console.log(x);
      console.log(type);

      // Call setLoading with dontModifyCount=true so the loading indicator shows during and after transition to other module
      this.loaderService.setLoading(true, true);

      if (type == "countMsNode") {
        this.appService.navigateToModuleWithParams(x, "metabolites");
      }
      else if (type == "countGCF") {
        this.appService.navigateToModuleWithParams(x, "bgc");
      }
      else if (type == "countTarget") {
        this.appService.navigateToModuleWithParams(x, "targets");
      }
      else if (type == "countSpectralMatch") {
        this.appService.navigateToModuleWithParams(x, "library");
      }

    });
  }
}
