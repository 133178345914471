import { Injectable, signal } from '@angular/core';
import { AppService } from './app.service';
import { BGCModuleRow, LibraryModuleRow, TargetSelectionApi } from './target-selection-api/target-selection-api';
import { first, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderService } from './loader.service';
import { FilterItem } from '../filter/filter.component';
import { RDKitLoaderService } from './rdkit-loader.service';
import { RDKitModule } from '@rdkit/rdkit';
import { LocalStorageService } from './local-storage.service';
import { NavigationExtras, Router } from '@angular/router';

export interface BGCModuleFilter {
    filterConfig?: FilterItem[];
}

export interface FilterInterface {
    (input: any[]): any[];
}

@Injectable({
  providedIn: 'root'
})

export class BGCModuleService {

  data: BGCModuleRow[] = [];
  filteredData = signal<BGCModuleRow[]>([]);
  selectedData: BGCModuleRow[] = [];
  dataLoaded$ = new Subject<void>();
  beforeFilterApplied$ = new Subject<boolean>();
  filter: BGCModuleFilter;
  filters: FilterInterface[] = [];

  constructor(
    private client: TargetSelectionApi,
    private appService: AppService,
    public loaderService: LoaderService,
    private router: Router
  ) {

    this.getData();

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
        this.beforeFilterApplied$.next(true);
        this.getData();
      });

  }

  addFilter(f: FilterInterface) {
    this.filters.push(f);
  }

  getData() {
    this.loaderService.setLoading(true);
    this.data = [];
    this.beforeFilterApplied$.next(false);
    this.filteredData.set([]);
    this.client.getBGCModuleRows(this.appService.selectedBatchName()).subscribe(x => {
      this.data = x;
      this.filteredData.set([...x]);
    }, null, () => this.loaderService.setLoading(false));
  }

  reset() {
    if (this.data.length > 0) {
      this.beforeFilterApplied$.next(true);
      this.filter = this.getBGCFilter();
      this.filteredData.set(this.data.filter(x => true));
    }
  }

  getFilteredData(): any[] {
    var retval = this.data.filter(x => true);
    for (var f of this.filters) {
      retval = f(retval);
    }
    return retval;
  }

  updateFilterValues(rows: BGCModuleRow[]) {
    if (!rows) return;
  }
  

  getBGCFilter(): BGCModuleFilter {
    return {
      filterConfig: []
    };
  }

  navigate(gcfId: number, type: string) {
    if (type == "gcfid"){
      const url = this.router.serializeUrl(this.router.createUrlTree(["/bgc-deep-dive"]));
      this.appService.openWindow(url);
      //this.router.navigate(['/bgc-deep-dive']); // Not fully implemented yet
    }
    this.client.getBGCLinkIds(this.appService.selectedBatchName(), gcfId, type).subscribe(x => {
      if (!x || x.length == 0) return;

      console.log(x);
      console.log(type);

      if (["countBGC", "countRefGeneCluster"].includes(type)) {
        const queryParams: any = {};
        queryParams.COMPOUND_IDS = JSON.stringify(x);
        const navigationExtras: NavigationExtras = {
          queryParams
        };
        const url = this.router.serializeUrl(this.router.createUrlTree(["/bgc-deep-dive"], navigationExtras));
      this.appService.openWindow(url);
      }
      else if (type == "countMSNodeCorrelated") {
        const queryParams: any = {};
        queryParams.MS_NODE_IDS = JSON.stringify(x);
        const navigationExtras: NavigationExtras = {
          queryParams
        };
        this.router.navigate(['/metabolites'], navigationExtras);
      }

    });
  }

  }