<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<!-- *ngIf="loaderService.isLoading | async" -->

<!-- <div [style]="{ height: '3px' }">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div> -->
<p-toast position="top-center" />


<res-layout>
  <res-header>

    <res-logo appName="Target Selection" pBadge style="font-size: 2rem" value="Not Production" severity="danger"
      *ngIf="!isProd"></res-logo>

    <res-logo appName="Target Selection" *ngIf="isProd"></res-logo>


    <res-loading-image [size]="20" [units]="'px'" />
    <res-navigation>
      <!-- Add your Navigation Here -->
      <res-navigation-item>
        <a routerLink="/home">Home</a>
      </res-navigation-item>
      <res-navigation-group name="Batches">
        @for (batch of (batches); track batch) {
        <res-navigation-item>
          <a [id]="'batch'+$index" (click)="selectBatch(batch.name)">{{batch.name}}</a>
        </res-navigation-item>
        }
        <res-navigation-item>
          <div style="border: 1px solid black; text-align:center;margin-bottom: 6px;"></div>
          <a>Search from Library <span class="pi pi-search search-from-library"></span></a>
        </res-navigation-item>

      </res-navigation-group>
      <res-navigation-group name="Modules">
        @for (module of (appService.menuModules()); track module.name) {
        <res-navigation-item>
          <a [id]="'module'+$index"   (click)="selectModule(module)">{{module.name}}
            @for (count of (module.count); track count.name) {
            <p-chip class="ml-2" [label]="count.count| number:'1.0-0'" [pTooltip]="count.name"></p-chip>
            }
          </a>
        </res-navigation-item>
        }
      </res-navigation-group>
      <res-navigation-group name="Settings">
        <res-navigation-item>
          <a>Users</a>
        </res-navigation-item>
        <res-navigation-item>
          <a>Clear Cache</a>
        </res-navigation-item>
      </res-navigation-group>

    </res-navigation>
  </res-header>
  <!-- Add your application content here (eg: Router Outlet)-->


  <div class="grid">
    <div class="col-4">
      <h5>Batch: {{appService.selectedBatchName()}}</h5>

    </div>

    <div class="col-4">
      <h5>Module: {{appService.selectedModule()?.name}}

        @for (count of (appService.selectedModule()?.count); track count.name) {
        <p-chip class="ml-2" [label]=" count.count | number:'1.0-0'" [pTooltip]="count.name"></p-chip>
        }

      </h5>

    </div>
    <div class="col-2 right-side-icon-div">
      <ng-container *ngIf="appService.selectedModule()?.showTargetButtons">
        <i class="pi pi-lock right-side-icon" pTooltip="Lock the selected record(s)"
          (click)="appService.targetButtonClicked('locked')" *ngIf="appService.showLockButton()"></i>
        <i class="pi pi-lock-open right-side-icon" pTooltip="Unlock the selected record(s)"
          (click)="appService.targetButtonClicked('unlocked')" *ngIf="appService.showUnLockButton()"></i>

        <i class="pi pi-ban right-side-icon reject" pTooltip="Reject as a Target"
          (click)="appService.targetButtonClicked('rejected')" *ngIf="appService.showRejectButton()"></i>
        <i class="pi pi-bullseye right-side-icon target" pTooltip="Select as a Target"
          (click)="appService.targetButtonClicked('target')" *ngIf="appService.showTargetButton()"></i>
      </ng-container>

      <!-- <router-outlet name="module-buttons"></router-outlet> -->
    </div>
    <div class="col-2 right-side-icon-div" *ngIf="appService.selectedModule()">
      <i class="pi pi-filter right-side-icon ml-4" pTooltip="Filter the dataset" (click)="showSideBar()"
        *ngIf="appService.selectedModule()?.filterRoute"></i>
      <i class="pi pi-refresh right-side-icon " pTooltip="Refresh the dataset" (click)="refresh()"></i>
    </div>
  </div>

  <router-outlet />

  <div>
    <p-sidebar [(visible)]="sidebarVisible" position="right" appendTo="body" (onShow)="onShowFilter($event)"
      (onHide)="onHideFilter($event)">


      <ng-template pTemplate="header">
        <h5>Filter</h5>
      </ng-template>

      <ng-template pTemplate="content">
        <router-outlet name="filter"></router-outlet>
      </ng-template>

    </p-sidebar>
  </div>

  <res-footer></res-footer>
</res-layout>


<p-blockUI [blocked]="loaderService.isLoading$ | async">
  <res-loading-image [size]="200" [units]="'px'" />
</p-blockUI>