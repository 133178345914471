<div class="grid">
    <div class="col-6">
        <h5>Adducts</h5>
        <ag-grid-angular class="ag-theme-quartz" style="height: 800px;" [rowData]="adducts()" [columnDefs]="colDefs"
            [pagination]="true" [paginationPageSize]="50"
            [rowSelection]="{ mode: 'singleRow', checkboxes: false, enableClickSelection: true}"
            [paginationPageSizeSelector]="[10,25,50]" (selectionChanged)="onSelectionChanged($event)" />
    </div>
    <div class="col-6">
        <div class="m-4">
            <h5>Adduct</h5>
            <form id="adductForm" [formGroup]="frmGrp" (ngSubmit)="onSubmit()">
                <div class="field">
                    <label for="adductName">Name</label>
                    <input type="text" id="adductName" pInputText formControlName="adductName"
                        [disabled]="!selAdduct" />
                </div>
                <div class="field">
                    <label for="adductMass">Mass</label>
                    <p-inputNumber inputId="adductMass" formControlName="adductMass" mode="decimal"
                        [minFractionDigits]="0" [maxFractionDigits]="3" [disabled]="!selAdduct" />
                </div>
                <div class="field">
                    <label for="adductMultiple">Multiple</label>
                    <p-inputNumber inputId="adductMultiple" formControlName="adductMultiple" mode="decimal"
                        [minFractionDigits]="0" [maxFractionDigits]="2" [disabled]="!selAdduct" />
                </div>
                <div>
                    <span style="width: 200px;"></span>
                    <p-button icon="pi pi-plus" [rounded]="true" [disabled]="updating" (onClick)="addClick()"
                        class="mr-2" pTooltip="Add" />
                    <p-button icon="pi pi-times" [rounded]="true"
                        [disabled]="updating || !selAdduct() || !selAdduct().id" (onClick)="deleteClick()" class="mr-2"
                        pTooltip="Delete" />
                    <p-button icon="pi pi-check" [rounded]="true" [disabled]="!frmGrp.valid || updating || !hasChange()"
                        type="submit" form="adductForm" pTooltip="Save" />
                </div>
            </form>
        </div>
    </div>
</div>