@for (fi of (filterItems); track fi.name) {
<p-panel [header]="fi.name" [toggleable]="true" class="mt-2">
    <div class="flex flex-row">
        <!-- <div class="flex"> -->

        @switch (fi.matchMode) {
        @case ("in") {
        <p-multiSelect [options]="fi.choices" [(ngModel)]="fi.value" [placeholder]="fi.name" [style]="{'width':'225px'}"
            [showClear]="true" optionLabel="name">

            <ng-template let-item pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ item.name }}</div>
                    <p-badge [value]="item.count" />
                </div>
            </ng-template>
        </p-multiSelect>
        }
        @case ("between") {
        <div class="flex flex-column">
            <div class="flex ">
                <p-slider [(ngModel)]="fi.value" [range]="true" [min]="fi.min" [max]="fi.max"
                    [style]="{'width':'225px'}" />
            </div>
            <div class="flex ">
                <!-- <input type="text" pInputText [(ngModel)]="fi.value[0]" />
                <input type="text" pInputText [(ngModel)]="fi.value[1]" /> -->

                <div class="grid mt-2" style="width: 225px;">
                    <div class="col-5">{{fi.value[0] | number }}</div>
                    <div class="col-5">{{ fi.value[1] | number}}</div>
                    <div class="col-2">
                        <p-button type="button" icon="pi pi-times" (onClick)="fi.value = [fi.min,fi.max]"
                            size="small"></p-button>
                    </div>
                </div>

            </div>
        </div>
        }

        @default {
        <p-inputGroup>
            <input type="text" pInputText [(ngModel)]="fi.value" />
            <p-button type="button" icon="pi pi-times" (onClick)="fi.value = undefined" size="small"></p-button>
        </p-inputGroup>
        }


        }






        <!-- </div> -->
    </div>
</p-panel>
}