import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { CreateTargetCommand, CreateTargetInfo, LockedStatus, LockTargetCommand, MetaboliteModuleRow, RejectTargetCommand, TargetSelectionApi, TargetStatus } from './target-selection-api/target-selection-api';
import { Observable, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TargetModuleService {
  createTargetInfo: CreateTargetInfo;

  constructor(
    private client: TargetSelectionApi,
    private appService: AppService,
  ) {

    
  }

  createTarget(cmd: CreateTargetCommand): Observable<TargetStatus> {
    return this.client.createTarget(cmd).pipe(tap(x => {
      console.log(`Target ${x} created`);
      //todo .. raise a TargetCreated Event
    }));
  }

  lockTarget(cmd: LockTargetCommand, row: MetaboliteModuleRow): Observable<TargetStatus> {
    return this.client.lockTarget(cmd).pipe(tap(x => {
      if (row) {
        if (cmd.status == LockedStatus.Locked) {
          Object.assign(row.status, x);
        }
        else if (cmd.status == LockedStatus.UnLocked) {
          row.status.status = "";
        }
      }
    }));
  }

  rejectTarget(cmd: RejectTargetCommand, row: any): Observable<TargetStatus> {
    return this.client.rejectTarget(cmd).pipe(tap(x => {
      if (row) {
        Object.assign(row.status, x);
      }
    }));
  }


  getCreateTargetInfo(msNodeId: number | undefined, gcfId: number | undefined): Observable<CreateTargetInfo> {
    return this.client.getCreateTargetInfo(msNodeId, gcfId, this.appService.selectedBatchName()).pipe(tap(x => {
      console.log(`getCreateTargetInfo called`);
      this.createTargetInfo = x;
    }));

  }

}
