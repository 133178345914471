import { Component } from "@angular/core";
import { UserChipComponent } from "../../../user-chip/user-chip.component";
import { TargetStatus } from "../../../services/target-selection-api/target-selection-api";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { BadgeModule } from "primeng/badge";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  standalone: true,
  imports: [
    BadgeModule,
  ],
  template: `
        @if(count) {
        <p-badge [value]="count" class="count-badge"  (click)="badgeClicked()" />
        }
    `,
  styles: `
    .count-badge:hover  {
      cursor: pointer;
    }
    `
})
export class CountRenderer implements ICellRendererAngularComp {
  count: number;
  countField: string;
  key: any;
  field: string;
  rowData: any;
  clicked: (msNodeId: number, type: string, rowData: any) => void;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.count = params.value;
    this.countField = (<any>params)["countField"];
    if (this.countField) {
      this.key = params.data[this.countField];
    }
    this.field = params.colDef.field;
    this.rowData = params.data;

    this.clicked = (<any>params)["clicked"];
    return true;
  }

  badgeClicked() {
    //console.log(`Get ${this.count} ids for ${this.keyName}: ${this.key} for field ${this.field} `);
    if (this.clicked)
      this.clicked(this.key, this.field, this.rowData);
  }

}
