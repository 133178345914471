@if (userService.isReaderOrHigher()) {
<ng-container>
    <div class="p-grid" style="display: flex;flex-wrap: wrap; margin: 5px;">

        @for (module of (appService.menuModules()); track module.name) {
        <p-card class="card-module w-4" id="module{{getModuleNameRemoveSpaces(module.name)}}"  [style]="{'margin':'.5em', 'height': '95%'}" (click)="selectModule(module)">
            <ng-template pTemplate="header">
                <div class="grid">

                    <div class="col-10">
                        <h5 class="ml-4">{{module.name}}</h5>
                        <span class="ml-4">Count: </span>
                        @for (count of (module.count); track count.name) {
                        <p-chip class="ml-2" [label]="count.count| number:'1.0-0'" [pTooltip]="count.name"></p-chip>
                        }
                    </div>
                    <div class="col-2">
                        <img class="img-top"   alt="{{module.name}}Card" src="./assets/images/{{module.name}}.png" />
                    </div>
                </div>
            </ng-template>
            <!-- <ng-template pTemplate="subheader">
                <span>Count: </span>
                @for (count of (module.count); track count.name) {
                <p-chip class="ml-2" [label]="count.count| number:'1.0-0'" [pTooltip]="count.name"></p-chip>
                }
            </ng-template> -->

            <p>Description of the {{module.name}} is here, click to take you to the module</p>
            <ng-template pTemplate="footer">

                <img class="img-bottom" alt="{{module.name}}Bottom" src="./assets/images/{{module.name}}Background.png">
            </ng-template>
        </p-card>
        }
    </div>
</ng-container>
}
@else {
  <h2>
    You are not authorized to access this application. Please submit a ticket at the link below to request access.
  </h2>
  <br>
  <a href="https://corteva-research.atlassian.net/servicedesk/customer/portal/20/group/88/create/420">
    <h4>Click here to request access</h4>
  </a>
}
