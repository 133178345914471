import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { ChipModule } from "primeng/chip";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TooltipModule } from "primeng/tooltip";
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { ButtonModule } from "primeng/button";
import { CommonModule } from "@angular/common";
import { MessageService } from "primeng/api";
import { BadgeModule } from "primeng/badge";

@Component({
  standalone: true,
  imports: [
    ChipModule,
    MoleculeStructureComponent,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ButtonModule,
    CommonModule,
    BadgeModule
  ],
  template: `

    <ng-container *ngIf="getStructure()">

      @if(srContext?.showSpectralMatchChip && getMatchType()) {
        <div class="molecule-structure-svg-tiny p-overlay-badge" 
            (click)="op.toggle($event)" pBadge style="font-size: 1rem" [value]="getMatchType()">
            <app-molecule-structure [width]="50" [height]="50" [structure]="getStructure()" [svgMode]="false">
            </app-molecule-structure>
        </div>
      }
      @else {
        <div class="molecule-structure-svg-tiny" (click)="op.toggle($event)" >
          <app-molecule-structure [width]="50" [height]="50" [structure]="getStructure()" [svgMode]="false">
          </app-molecule-structure>
        </div>

      }

      <p-overlayPanel #op>
        <ng-template pTemplate="content">

          <h5 *ngIf="getCompoundName()" class="mb-4" [pTooltip]="getCompoundName()">{{getCompoundName() | truncate:15:'...'}}</h5>

            <p-chip *ngIf="srContext?.showSpectralMatchChip && getMatchType()" styleClass="pl-0 pr-3">
              <span
                  class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">
                  {{getMatchType()}}
              </span>
              <span class="ml-2 font-medium">
                  {{getMatchText()}}
              </span>
            </p-chip>

              <app-molecule-structure [width]="200" [height]="200"
                  [structure]="getStructure()" [svgMode]="false"
                  [substructure]="getSubstructure()"></app-molecule-structure>

              <div class="flex flex-row flex-wrap">
                  <div class="flex align-items-center justify-content-center ">
                      <p-button (onClick)="copySMILES(getStructure())" icon="pi pi-copy"
                          label="Copy" />
                  </div>
              </div>

          </ng-template>
      </p-overlayPanel>

    </ng-container>

    `,
  styles: `
    @import "../../../../../node_modules/@corteva-research/ngx-components-core/assets/styles/colors.scss";

    .molecule-structure-svg-tiny {
    cursor: pointer;
    display: inline-block;
    border: 2px solid transparent;
    }

    .molecule-structure-svg-tiny:hover {
        border: 2px solid $res-color-blue;
    }


    `
})
export class StructureRenderer implements ICellRendererAngularComp {
  row: any;
  srContext: StructureRendererContext;

  /**
   *
   */
  constructor(
    //public metabolitesService: MetaboliteModuleService,
    private messageService: MessageService,
  ) {


  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.row = params.data;
    this.srContext = params.context;
    return true;
  }

  getMatchType(): string {
    if (this.srContext?.matchTypeValue) {
      return this.srContext.matchTypeValue;
    }

    else if (this.row && this.srContext?.matchTypeProp) {
      return (<string>this.row[this.srContext.matchTypeProp])?.toLowerCase().substring(0,2);
    }

    return null;
  }

  getMatchText(): string {
    if (this.getMatchType() == "em") {
      return "Exact Mass Match";
    }
    if (this.getMatchType() == "sm") {
      return "Spectral Match";
    }
    return null;
  }

  getStructure(): string {
    if (this.row && this.srContext?.smilesProp) {
      return this.row[this.srContext.smilesProp];
    }

    return null;
  }

  getCompoundName(): string {
    if (this.row && this.srContext?.compoundNameProp) {
      return this.row[this.srContext.compoundNameProp];
    }

    return null;
  }

  getSubstructure(): string {
    if (this.srContext?.getSubstructure) {
      return this.srContext.getSubstructure();
    }

    return null;
  }

  copySMILES(SMILES: string) {
    navigator.clipboard.writeText(SMILES);
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: 'SMILES copied to clipboard' });
  }

}

export interface StructureRendererContext {
  smilesProp: string;
  compoundNameProp: string;
  matchTypeProp: string;
  matchTypeValue: "em" | "sm";
  showSpectralMatchChip: boolean;
  getSubstructure: () => string;
}
