import { AfterViewInit, Component, effect, Input, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterEvent, TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LockedStatus, LockTargetCommand, MetaboliteModuleRow, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { forkJoin, Observable } from 'rxjs';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { AppService } from '../../../services/app.service';
import { ChemdrawWrapperComponent } from '../../../chemdraw-wrapper/chemdraw-wrapper.component';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef, GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { GridReadyEvent } from 'ag-grid-enterprise';
import { StatusRenderer } from './status-renderer.component';
import { StructureRenderer, StructureRendererContext } from './structure-renderer.component';
import { StructureFilterComponent } from '../../../structure-filter/structure-filter.component';
import { MSGraphService } from '../../../services/msgraph.service';
import { ActivatedRoute } from '@angular/router';
import { StepperModule } from 'primeng/stepper';
import { InputGroupModule } from 'primeng/inputgroup';
import { CountRenderer } from './count-renderer.component';
import { UrlRenderer } from './url-renderer.component';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';
import { CreateTargetComponent, CreateTargetDialogInput } from "../../targets/create-target/create-target.component";
import { RejectTargetComponent, RejectTargetDialogInput } from '../../targets/reject-target/reject-target.component';
import { TargetModuleService } from '../../../services/target-module.service';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'app-metabolite-module',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ResearchComponentsCoreModule,
    BlockUIModule,
    OverlayPanelModule,
    CardModule,
    TooltipModule,
    ButtonModule,
    FloatLabelModule,
    FormsModule,
    BadgeModule,
    ChipModule,
    SliderModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    AgGridAngular,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    InputGroupModule,
    CreateTargetComponent,
    RejectTargetComponent
  ],

  templateUrl: './metabolite-module.component.html',
  styleUrl: './metabolite-module.component.scss'
})
export class MetaboliteModuleComponent implements AfterViewInit {
  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;
  @ViewChild("ctd") ctd: CreateTargetComponent;
  @ViewChild("rtd") rtd: RejectTargetComponent;

  first = 0;
  selectedRow: MetaboliteModuleRow;
  msNodeIds: any[] = [];
  gridApi = signal<GridApi<MetaboliteModuleRow> | undefined>(undefined);
  filterModel: any;

  //Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    {
      field: "status", headerName: "Status", filter: "agSetColumnFilter", filterValueGetter: (p) => {
        return p.data.status?.status;
      }, cellRenderer: StatusRenderer, cellDataType: "text"
    },
    { field: "mz", headerName: "m/z", filter: "agNumberColumnFilter", cellDataType: "number" },
    //{ field: "retentionTime", headerName: "Retention Time" },
    {
      field: "msNodeId", headerName: "MS Node ID", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.appService.openModuleNewWindow(urlValue.toString(), field, "metabolites-deep-dive");
        }
      }
    },
    {
      field: "spectralFamilyId", headerName: "Spectral Family ID", filter: "agSetColumnFilter", cellDataType: "number", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          this.appService.openModuleNewWindow(urlValue.toString(), field, "metabolites-deep-dive");
        }
      }
    },
    {
      field: "strainID", headerName: "Highest Strain", filter: "agSetColumnFilter", cellDataType: "text", cellRenderer: UrlRenderer,
      cellRendererParams: {
        clicked: (urlValue: number, field: string) => {
          const queryParams: any = {};
          queryParams["STRAIN_IDS"] = [urlValue.toString()];
          this.appService.navigateToModuleWithParams(queryParams, "strains");
        }
      }
    },
    { field: "countStrain", headerName: "# Strain", filter: "agNumberColumnFilter", cellDataType: "number" },
    {
      field: "countExactMassMatch", headerName: "# Exact Mass Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    {
      field: "countSpectralMatch", headerName: "# Spectral Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    {
      field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer, filter: StructureFilterComponent,
      cellRendererParams: {
        context: {
          smilesProp: "spectrumCompoundSMILES",
          compoundNameProp: "spectrumCompoundName",
          showSpectralMatchChip: true,
          matchTypeValue: "sm",
          getSubstructure: () => {
            return this.metabolitesService?.filter?.smiles;
          }
        } as StructureRendererContext
      }
    },
    //{ field: "spectrumCompoundSMILES", headerName: "Predicted Structure", cellRenderer: StructureRenderer },
    { field: "cScore", headerName: "C-Score", filter: "agNumberColumnFilter", cellDataType: "number" },
    {
      field: "countGCF", headerName: "# GCF Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      cellRendererParams: {
        countField: "msNodeId",
        clicked: (msNodeId: number, type: string, rowData: any) => {
          this.metabolitesService.navigateFromCount(msNodeId, type);
        }
      }
    },
    // { headerName: "Bioactivity Correlation" },
    // { headerName: "HCA-PCA selection" },

  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  constructor(
    private messageService: MessageService,
    public metabolitesService: MetaboliteModuleService,
    private appService: AppService,
    private graphService: MSGraphService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mmdd: MetaboliteDeepDiveService,
    private targetService: TargetModuleService,
    private loaderService: LoaderService
  ) {

    if (this.activatedRoute.snapshot.queryParams['paramsGUID']) {
      this.msNodeIds = this.appService.getParameters(this.activatedRoute.snapshot.queryParams['paramsGUID']) ?? [];
    }
    else {
      this.msNodeIds = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "msNodeId") ?? [];
    }

    this.metabolitesService.getData();

    effect(() => {
      console.log('num rows:', this.metabolitesService.filteredData()?.length);

      if (this.metabolitesService.filteredData()?.length > 0 && this.gridApi) {

        this.loaderService.setLoading(true);
        setTimeout(() => {
          if (this.filterModel) {
            this.gridApi().setFilterModel(this.filterModel); //put the saved filter back
          }

          if (this.msNodeIds?.length > 0) {
            this.gridApi().setColumnFilterModel("msNodeId", { values: [...this.msNodeIds] });
            this.msNodeIds = [];
          }

          this.gridApi().onFilterChanged(); //apply the filter
          this.loaderService.setLoading(false);
        }, null);
      }
    });

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      //if (this.appService.selectedModule().name == "Metabolites") {
        this.metabolitesService.getData(true);
      //}
    });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.filterModel = null;
      this.gridApi()?.setFilterModel(null);
    });

    this.metabolitesService.beforeFilterApplied$.pipe(takeUntilDestroyed()).subscribe(reset => {
      this.first = 0;

      //save the ag grid filter before we filter the data our own way
      if (this.gridApi && !reset) {
        this.filterModel = this.gridApi().getFilterModel();
      }
    });

    //in case someone comes directly to this route
    this.appService.selectModule(this.appService.modules.find(x => x.name == "Metabolites"), false);

    appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      if (this.metabolitesService.selectedData.length == 1 && x == "target") {
        this.selectedRow = this.metabolitesService.selectedData[0];
        let input = new CreateTargetDialogInput();
        input.batchId = this.selectedRow.batchId;
        input.msNodeId = this.selectedRow.msNodeId;
        input.mz = this.selectedRow.mz;
        input.rowToUpdate = this.selectedRow;
        input.spectralFamilyId = this.selectedRow.spectralFamilyId;
        input.spectrumCompoundSMILES = this.selectedRow.spectrumCompoundSMILES;
        input.strainID = this.selectedRow.strainID;

        this.ctd.show(input);
      }

      if (x == "rejected") {
        let input: RejectTargetDialogInput[] = [];

        for (let row of this.metabolitesService.selectedData) {
          let x = new RejectTargetDialogInput();
          x.batchId = row.batchId;
          x.msNodeId = row.msNodeId;
          x.rowToUpdate = row;

          input.push(x);
        }

        this.rtd.show(input);
      }

      if (x == "locked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.metabolitesService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.Locked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.metabolitesService.updateTargetButtons();
        });
      }

      if (x == "unlocked") {
        let obs: Observable<TargetStatus>[] = [];

        for (let row of this.metabolitesService.selectedData) {
          var cmd = new LockTargetCommand({
            batchId: this.appService.selectedBatchName(),
            msNodeId: row.msNodeId, status:
              LockedStatus.UnLocked
          });
          obs.push(this.targetService.lockTarget(cmd, row));
        }

        forkJoin(obs).subscribe(x => {
          this.metabolitesService.updateTargetButtons();
        });
      }

    });

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi.set(params.api);
  }

  ngAfterViewInit(): void {
    //this.metabolitesService.applyFilter();
  }

  copySMILES(SMILES: string) {
    navigator.clipboard.writeText(SMILES);
    this.messageService.add({ severity: 'info', summary: 'Copied', detail: 'SMILES copied to clipboard' });
  }

  dataFiltered($event: TableFilterEvent) {
    this.metabolitesService.updateFilterValues(<MetaboliteModuleRow[]>$event.filteredValue);
  }

  targetCreated() {
    this.gridApi().deselectAll();
  }

  targetsRejected() {
    this.gridApi().deselectAll();
  }

  onSelectionChanged($event: SelectionChangedEvent<MetaboliteModuleRow, any>) {
    var rowCount = $event.api.getSelectedNodes().length;
    console.log(rowCount);

    this.metabolitesService.setSelectedData($event.api.getSelectedNodes().map(x => x.data));
  }

}

