import { Injectable, signal } from '@angular/core';
import { AppService } from './app.service';
import { Adduct, CreateAdductCommand, CreateTargetCommand, CreateTargetInfo, LockedStatus, LockTargetCommand, MetaboliteModuleRow, RejectTargetCommand, TargetSelectionApi, TargetStatus, UpdateAdductCommand, UpdateTargetMaterialCommand } from './target-selection-api/target-selection-api';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root'
})
export class AdductService {


  constructor(
    private client: TargetSelectionApi,
    private appService: AppService,
  ) {

   
  }

  getAdducts() : Observable<Adduct[]>  { 
    return this.client.getAdducts().pipe(tap(x => {
      console.log(`getting adducts`);
    }));
  }


  updateAdduct(cmd: UpdateAdductCommand): Observable<Adduct> {
    return this.client.updateAdduct(cmd).pipe(tap(x => {
      console.log(`updating adduct`);
    }));
  }

  creteAdduct(cmd: CreateAdductCommand): Observable<Adduct> {
    return this.client.createAdduct(cmd).pipe(tap(x => {
      console.log(`creating adduct`);
    }));
  }

  deleteAdduct(adductId: number) {
    return this.client.deleteAdduct(adductId).pipe(tap(x => {
      console.log(`deleting adduct`);
    }));
  }

  // getAdducts(): Observable<Adduct[]> {
  //   return this.client.getAdducts().pipe(tap(x => {
  //     console.log(`Getting adducts`);
  //   }));
  // }



}
