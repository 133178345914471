import { AfterViewInit, Component, EventEmitter, input, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { AppService } from '../services/app.service';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import * as _ from 'lodash';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { MultiSelectModule } from 'primeng/multiselect';
import { BadgeModule } from 'primeng/badge';
import { SliderModule } from 'primeng/slider';
import { DecimalPipe } from '@angular/common';


export interface FilterItem {
  path: string;
  name: string;
  value?: any;
  matchMode: 'equals' | 'between' | 'gt' | 'gte' | 'lt' | 'lte' | 'in';
  type: 'number' | 'string' | 'datetime';
  choices?: any[];
  min?: number;
  max?: number;
}

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    InputNumberModule,
    FormsModule,
    ButtonModule,
    PanelModule,
    FormsModule,
    InputTextModule,
    InputGroupModule,
    InputGroupAddonModule,
    MultiSelectModule,
    BadgeModule,
    SliderModule, 
    DecimalPipe

  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements AfterViewInit, OnChanges {

  @Input() data: any[];
  @Output() filteredData = new EventEmitter<any[]>();

  @Input() filterItems: FilterItem[];

  /**
   *
   */
  constructor(public appService: AppService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  ngAfterViewInit(): void {
    //this.init();
  }


  init() {
    if (this.data && this.filterItems) {
      for (var fi of this.filterItems) {
        if (fi.matchMode == "in") {
          var dict = _.countBy(this.data, fi.path);
          fi.choices = Object.keys(dict).map((key) => { return { 'name': key, 'count': dict[key] }; })
        }
        else if (fi.matchMode == "between") {
          fi.min = _.get(_.minBy(this.data, fi.path), fi.path);
          fi.max = _.get(_.maxBy(this.data, fi.path), fi.path);
          fi.value = [fi.min, fi.max];
        }
      }
    }
  }

  applyFilter() {
    var fd = this.getFilteredData(this.data);
    this.filteredData.emit(fd);
  }

  getFilteredData(input: any[]): any[] {
    return input.filter(x => this.filter(x));
  }

  filter(row: any): boolean {
    for (var fi of this.filterItems.filter(x => x.value)) {
      var val = _.get(row, fi.path);
      //if (!val) return false;
      if (!this.filterRow(fi, val)) return false;
    }

    return true;
  }

  filterRow(fi: FilterItem, val: any): boolean {
    if (!fi.value) return false;

    var filterValue;
    if (fi.type == "datetime") filterValue = new Date(fi.value);
    else if (fi.type == "number") filterValue = new Number(fi.value)
    else filterValue = new String(fi.value);

    switch (fi.matchMode) {
      case "equals":
        return (val == filterValue);
      case "gt":
        return (val > filterValue);
      case "gte":
        return (val >= filterValue);
      case "lt":
        return (val < filterValue);
      case "lte":
        return (val <= filterValue);
      case "in":
        if (Array.isArray(fi.value))
          return (<any[]>fi.value.map(x => x.name)).includes(val ?? "null");
        else
          return false;
      case "between":
        if (fi.value[0] <= fi.min && fi.value[1] >= fi.max) return true;
        return (val >= fi.value[0] && val <= fi.value[1]);

      default:
        return (val == filterValue);
    }
  }



}
