import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { CreateTargetCommand, CreateTargetInfo, LockedStatus, LockTargetCommand, MetaboliteModuleRow, RejectTargetCommand, TargetSelectionApi, TargetStatus, UpdateTargetMaterialCommand } from './target-selection-api/target-selection-api';
import { Observable, Subject, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TargetModuleService {
  createTargetInfo: CreateTargetInfo;
  targetsUpdated$ = new Subject<boolean>();

  constructor(
    private client: TargetSelectionApi,
    private appService: AppService,
  ) {


  }

  createTarget(cmd: CreateTargetCommand): Observable<TargetStatus> {
    return this.client.createTarget(cmd).pipe(tap(x => {
      console.log(`Target ${x} created`);
      //todo .. raise a TargetCreated Event
      this.targetsUpdated$.next(true);
      this.appService.updateModuleCount();
    }));
  }

  updateTarget(cmd: CreateTargetCommand): Observable<TargetStatus> {
    return this.client.updateTarget(cmd).pipe(tap(x => {
      console.log(`Target ${x} updated`);
      //todo .. raise a TargetCreated Event
      this.targetsUpdated$.next(true);
    }));
  }

  updateTargetMaterial(cmd: UpdateTargetMaterialCommand): Observable<TargetStatus> {
    return this.client.updateTargetMaterial(cmd).pipe(tap(x => {
      console.log(`Target material ${x} updated`);
      //todo .. raise a TargetCreated Event
      this.targetsUpdated$.next(true);
    }));
  }

  lockTarget(cmd: LockTargetCommand, row: MetaboliteModuleRow): Observable<TargetStatus> {
    return this.client.lockTarget(cmd).pipe(tap(x => {
      if (row) {
        if (cmd.status == LockedStatus.Locked) {
          Object.assign(row.status, x);
        }
        else if (cmd.status == LockedStatus.UnLocked) {
          row.status.status = "";
        }
      }
      this.targetsUpdated$.next(true);
    }));
  }

  rejectTarget(cmd: RejectTargetCommand, row: any): Observable<TargetStatus> {
    return this.client.rejectTarget(cmd).pipe(tap(x => {
      if (row) {
        Object.assign(row.status, x);
      }
      this.targetsUpdated$.next(true);
    }));
  }


  getCreateTargetInfo(msNodeId: number | undefined, gcfId: number | undefined): Observable<CreateTargetInfo> {
    return this.client.getCreateTargetInfo(msNodeId, gcfId, this.appService.selectedBatchName()).pipe(tap(x => {
      console.log(`getCreateTargetInfo called`);
      this.createTargetInfo = x;
    }));
  }

  getTarget(targetId: number): Observable<CreateTargetCommand> {
    return this.client.getTarget(targetId).pipe(tap(x => {
      console.log(`getTarget called`);
    }));
  }

}
