<p-dialog header="Header" [modal]="true" [(visible)]="display" [style]="{ width: '60rem',  'min-height': '46rem'  }"
    appendTo="body">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <i class="pi pi-bullseye right-side-icon target"></i>
            <span class="font-bold white-space-nowrap">
                @if (updating) {
                    Edit Target
                }
                @else {
                    Create Target
                }
            </span>
        </div>
    </ng-template>


    <ng-container *ngIf="input">
        <div>
            @if(mode=="MSNODE") {
            <p-table [value]="[input]" [tableStyle]="{ 'width': '100%' }" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th>MS Node</th>
                        <th>m/z</th>
                        <th>Spectral Family</th>
                        <th>Strain</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td>
                            {{row.msNodeId}}
                        </td>
                        <td>
                            {{row.mz}}
                        </td>
                        <td>
                            {{row.spectralFamilyId}}
                        </td>
                        <td>
                            {{row.strainID}}
                        </td>

                    </tr>
                </ng-template>
            </p-table>
            }
            @else if (mode=="GCF") {
            <p-table [value]="[input]" [tableStyle]="{ 'width': '100%' }" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th>GCF Id</th>
                        <th>GCF Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td>
                            {{row.gcfId}}
                        </td>
                        <td>
                            {{row.gcfType}}
                        </td>

                    </tr>
                </ng-template>
            </p-table>
            }

        </div>

        <form id="createTargetForm" [formGroup]="createTargetFormGrp" (ngSubmit)="onSubmit()">

            <p-stepper [(activeStep)]="activeStep" (activeStepChange)="stepChanged($event)" *ngIf="!onlyEditMaterial">
                <p-stepperPanel header="General">
                    <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">

                        <div style="min-height: 22rem;" class="mt-2">
                            <div class="formgrid grid">
                                <div class="field col-5">
                                    <label for="selectionType">Selection Type</label>
                                    <p-dropdown id="selectionType"
                                        [options]="targetService.createTargetInfo?.selectionCriteria"
                                        placeholder="Selection Criteria " formControlName="selectionType" />

                                    <small *ngIf="createTargetFormGrp.get('selectionType')?.hasError('required')"
                                        class="p-error">Selection Type is required.</small>
                                </div>

                                <div class="field col-2">
                                    <label for="type">Type</label>
                                    <p-dropdown id="type" [options]="targetService.createTargetInfo?.types"
                                        placeholder="Type" formControlName="type" [readonly]="updating" />

                                    <small *ngIf="createTargetFormGrp.get('type')?.hasError('required')"
                                        class="p-error">Type is
                                        required.</small>
                                </div>

                                @if(mode=="MSNODE") {
                                <div class="field col-2">
                                    <label for="gcfId">GCF Id</label>
                                    <p-dropdown id="gcfId" [options]="targetService.createTargetInfo?.gcfIds"
                                        placeholder="GCF Id" formControlName="gcfId" [readonly]="updating" />
                                </div>
                                }
                                @else if (mode=="GCF") {
                                <div class="field col-2">
                                    <label for="gcfId">MS Node Id</label>
                                    <p-dropdown id="msNodeId"
                                        [options]="targetService.createTargetInfo?.msNodeIds?.sort()"
                                        placeholder="Node Id" formControlName="msNodeId" [virtualScroll]="true"
                                        [filter]="true" [virtualScrollItemSize]="38" [readonly]="updating" />
                                </div>

                                }

                                <div class="field col-3">
                                    <label for="selectionType">Adduct</label>
                                    <p-dropdown id="adduct" [options]="targetService.createTargetInfo?.adducts"
                                        optionLabel="name" placeholder="Adduct "
                                        formControlName="adduct" />
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="hypothesis">Hypothesis</label>
                                    <textarea id="hypothesis" rows="10" cols="90" pInputTextarea
                                        formControlName="hypothesis">
                                </textarea>
                                    <small *ngIf="createTargetFormGrp.get('hypothesis')?.hasError('required') "
                                        class="p-error">Hypothesis is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-end">
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Structure">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                        let-index="index">

                        <div style="min-height: 22rem;" class="mt-2 ml-4">
                            <div class="flex flex-row">
                                <div class="flex">
                                    <div class="field ">
                                        <label for="gcfId">SMILES</label>
                                        <p-inputGroup>
                                            <input id="smiles" type="text" pInputText
                                                [(ngModel)]="this.createTargetCmd.predictedStructureSMILES"
                                                [ngModelOptions]="{standalone: true}">
                                            <p-button type="button" icon="pi pi-times"
                                                (onClick)="this.createTargetCmd.predictedStructureSMILES = undefined"
                                                size="small"></p-button>
                                        </p-inputGroup>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row">
                                <div class="flex">
                                    <div class="field ">
                                        <label for="gcfId">Structure</label>
                                        <app-molecule-structure [width]="175" [height]="175"
                                            [structure]="this.createTargetCmd.predictedStructureSMILES"
                                            *ngIf="this.createTargetCmd.predictedStructureSMILES"
                                            [svgMode]="false"></app-molecule-structure>
                                    </div>
                                </div>
                                <div class="flex" *ngIf="this.createTargetCmd.predictedStructureSMILES">
                                    <div class="flex flex-column">
                                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                            (onClick)="showChemdraw=true" size="small" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Charts">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                        let-index="index">
                        <div style="min-height: 22rem;" class="mt-2 ml-4">
                            <div class="grid" style="width: 40rem;">

                                <div style="height: 250px; width: 250px;"
                                    (paste)="onPaste($event,'totalIonChromatogram')" tabindex="0"
                                    class="paste-div col-3 m-2">
                                    <div class="mb-4 chart-title">Ion Chromatogram</div>
                                    <!-- <p-image *ngIf="createTargetCmd.totalIonChromatogram"
                                        [src]="createTargetCmd.totalIonChromatogram" alt="Pasted Image" height="180px"
                                        width="180px" [preview]="true"></p-image> -->

                                    <div class="flex" *ngIf="createTargetCmd.totalIonChromatogram">
                                        <div class="flex">
                                            <p-image [src]="createTargetCmd.totalIonChromatogram" alt="Pasted Image"
                                                height="180px" width="180px" [preview]="true"></p-image>
                                        </div>

                                        <div class="flex">
                                            <i class="flex clear-image pi pi-times-circle ml-2"
                                                (click)="createTargetCmd.totalIonChromatogram = undefined"></i>
                                        </div>
                                    </div>

                                    <div class="click-paste" *ngIf="!createTargetCmd.totalIonChromatogram">
                                        Click here then paste the image.
                                    </div>
                                </div>

                                <div style="height: 250px; width: 250px;"
                                    (paste)="onPaste($event,'extractedIonChromatogram')" tabindex="0"
                                    class="paste-div col-3 m-2">
                                    <div class="mb-4 chart-title">Extracted Ion Chromatogram</div>

                                    <!-- <p-image *ngIf="createTargetCmd.extractedIonChromatogram"
                                        [src]="createTargetCmd.extractedIonChromatogram" alt="Pasted Image"
                                        height="180px" width="180px" class="m-2" [preview]="true"></p-image> -->

                                    <div class="flex" *ngIf="createTargetCmd.extractedIonChromatogram">
                                        <div class="flex">
                                            <p-image [src]="createTargetCmd.extractedIonChromatogram" alt="Pasted Image"
                                                height="180px" width="180px" [preview]="true"></p-image>
                                        </div>

                                        <div class="flex">
                                            <i class="flex clear-image pi pi-times-circle ml-2"
                                                (click)="createTargetCmd.extractedIonChromatogram = undefined"></i>
                                        </div>
                                    </div>

                                    <div class="click-paste" *ngIf="!createTargetCmd.extractedIonChromatogram">
                                        Click here then paste the image.
                                    </div>
                                </div>

                                <div style="height: 250px; width: 250px;" (paste)="onPaste($event,'mS1Spectra')"
                                    tabindex="0" class="paste-div col-3 m-2">
                                    <div class="mb-4 chart-title">MS1 Spectra</div>
                                    <!-- <p-image *ngIf="createTargetCmd.mS1Spectra" [src]="createTargetCmd.mS1Spectra"
                                        alt="Pasted Image" height="180px" width="180px" class="m-2"
                                        [preview]="true"></p-image> -->

                                    <div class="flex" *ngIf="createTargetCmd.mS1Spectra">
                                        <div class="flex">
                                            <p-image [src]="createTargetCmd.mS1Spectra" alt="Pasted Image"
                                                height="180px" width="180px" [preview]="true"></p-image>
                                        </div>

                                        <div class="flex">
                                            <i class="flex clear-image pi pi-times-circle ml-2"
                                                (click)="createTargetCmd.mS1Spectra = undefined"></i>
                                        </div>
                                    </div>

                                    <div class="click-paste" *ngIf="!createTargetCmd.mS1Spectra">
                                        Click here then paste the image.
                                    </div>
                                </div>

                                <div style="height: 250px; width: 250px;" (paste)="onPaste($event,'mS2Spectra')"
                                    tabindex="0" class="paste-div col-3 m-2">
                                    <div class="mb-4 chart-title">MS2 Spectra</div>
                                    <div class="flex" *ngIf="createTargetCmd.mS2Spectra">
                                        <div class="flex">
                                            <p-image [src]="createTargetCmd.mS2Spectra" alt="Pasted Image"
                                                height="180px" width="180px" [preview]="true"></p-image>
                                        </div>

                                        <div class="flex">
                                            <i class="flex clear-image pi pi-times-circle ml-2"
                                                (click)="createTargetCmd.mS2Spectra = undefined"></i>
                                        </div>
                                    </div>

                                    <div class="click-paste" *ngIf="!createTargetCmd.mS2Spectra">
                                        Click here then paste the image.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>

                <p-stepperPanel header="Material IDs">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                        let-index="index">

                        <!-- <div class="mat-ids-instructions">
                            Paste a list of CORE Material Id(s) for the isolated compound.
                        </div> -->

                        <div style="min-height: 22rem;" class="mt-2">

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="materialIds">
                                        Paste/edit a list of CORE Material Id(s) for the isolated compound.
                                    </label>
                                    <textarea id="materialIds" rows="5" cols="30" pInputTextarea
                                        formControlName="materialIds">
                                </textarea>
                                    <small
                                        *ngIf="createTargetFormGrp.get('materialIds')?.hasError('invalidMaterialIds')"
                                        class="p-error">Invalid Material Ids.</small>
                                </div>

                            </div>
                        </div>

                        <!-- <div style="text-align: center;">
                            <textarea id="materialIds"  class="mat-ids" rows="5" cols="30" pInputTextarea formControlName="materialIds">
                            </textarea>
                            <small *ngIf="createTargetFormGrp.get('materialIds')?.hasError('invalidMaterialIds')"
                                class="p-error">Invalid Material Ids.</small>
                        </div> -->

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <p-button label="Next" icon="pi pi-arrow-right" iconPos="right"
                                (onClick)="nextCallback.emit()" />
                        </div>
                    </ng-template>
                </p-stepperPanel>



                <p-stepperPanel header="Summary">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                        <div style="min-height: 22rem;" class="grid mt-2 ml-4">
                            <div class="col-8">
                                <table>
                                    <tr>
                                        <td class="header">Batch</td>
                                        <td>{{createTargetCmd.batchId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">MS Node ID</td>
                                        <td>{{createTargetCmd.msNodeId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">m/z</td>
                                        <td>{{input.mz}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Spectral Family</td>
                                        <td>{{input.spectralFamilyId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Strain ID</td>
                                        <td>{{input.strainID}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">GCF ID</td>
                                        <td>{{createTargetCmd.gcfId}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Selection Type</td>
                                        <td>{{createTargetCmd.selectionType}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Hypothesis</td>
                                        <td>{{createTargetCmd.hypothesis}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">Adduct</td>
                                        <td>{{createTargetCmd.adduct?.name}}</td>
                                    </tr>

                                    <tr>
                                        <td class="header">Type</td>
                                        <td>{{createTargetCmd.type}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header">SMILES</td>
                                        <td>{{createTargetCmd.predictedStructureSMILES}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header"># Graphs</td>
                                        <td>{{getChartCount()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header"># Material Ids</td>
                                        <td>{{getMaterialIds()?.length}}</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-4">
                                <div class="field ">
                                    <label for="gcfId">Predicted Structure</label>
                                    <app-molecule-structure [width]="200" [height]="200"
                                        [structure]="this.createTargetCmd.predictedStructureSMILES"
                                        *ngIf="this.createTargetCmd.predictedStructureSMILES"
                                        [svgMode]="false"></app-molecule-structure>
                                </div>
                            </div>
                        </div>

                        <div class="flex pt-4 justify-content-between">
                            <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                            <button pButton label="Finish" type="submit" form="createTargetForm" icon="pi pi-check"
                                iconPos="right" [disabled]="!createTargetFormGrp.valid || creatingTarget"></button>
                        </div>
                    </ng-template>
                </p-stepperPanel>
            </p-stepper>


            <div style="min-height: 22rem;" class="mt-2" *ngIf="onlyEditMaterial">

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="materialIds">
                            Paste/edit a list of CORE Material Id(s) for the isolated compound.
                        </label>
                        <textarea id="materialIds" rows="5" cols="30" pInputTextarea formControlName="materialIds">
                    </textarea>
                        <small *ngIf="createTargetFormGrp.get('materialIds')?.hasError('invalidMaterialIds')"
                            class="p-error">Invalid Material Ids.</small>
                    </div>

                </div>

                <div class="flex pt-4 justify-content-between">
                    <button pButton label="Finish" type="submit" form="createTargetForm" icon="pi pi-check"
                        iconPos="right" [disabled]="!createTargetFormGrp.valid || creatingTarget"></button>
                </div>
            </div>


        </form>
    </ng-container>

    <!-- <ng-template pTemplate="footer">
        <p-button label="Cancel" severity="secondary" (onClick)="showCreateTarget = false" />
        <button pButton label="Save" type="submit" form="createTargetForm"></button> </ng-template> -->

    <div #appendHere>

    </div>

</p-dialog>



<p-dialog header="Header" [modal]="true" appendTo="body" [(visible)]="showChemdraw"
    styleClass="ag-custom-component-popup" [style]="{ width: '55rem', height: '46rem'  }" closable="false">

    <ng-template pTemplate="header">
        <h5>Draw Structure</h5>
    </ng-template>

    <div style="height: 490px;" *ngIf="this.createTargetCmd?.predictedStructureSMILES">
        <app-chemdraw-wrapper #cdw *ngIf="showChemdraw"
            [(smiles)]="this.createTargetCmd.predictedStructureSMILES"></app-chemdraw-wrapper>
    </div>

    <div class="mt-4">

    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="showChemdraw = false" />
        <p-button label="OK" [outlined]="true" severity="secondary" (onClick)="updateSmiles()" />
    </ng-template>
</p-dialog>