import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-w-limited-dec',
  standalone: true,
  imports: [
    TooltipModule,
    ReactiveFormsModule,
    InputTextModule
  ],
  templateUrl: './input-w-limited-dec.component.html',
  styleUrl: './input-w-limited-dec.component.scss'
})

export class InputWLimitedDecComponent {

  inputControl: FormControl = new FormControl('');

  _value: number;
  get value(): number {
      return this._value;
  }
  @Input() set value(value: number) {

    if (value != this._value) {
      this._value = value;
      this.inputControl.setValue(value);
    }

  }

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() placeholder: string;
  @Input() min: number;
  @Input() max: number;
  @Input() disabled: boolean;

  numberRegex: RegExp = /[0-9]/
  decimalRegex: RegExp = /^\d+((\.)|(\.\d{1,3}))?$/

  constructor() { }

  ngOnInit(): void {
  }

  onInput(event: any) {
    if (event.inputType.startsWith('deleteContent')) {
      this.updateValue(event.target.value, null);
    }
  }

  onChange(event: any) {
    let curVal: string = (<any>event.target).value;
    this.updateValue(curVal, event, false);
  }

  onKeyPress(event: KeyboardEvent) {

    let curVal: string = (<any>event.target).value;

    if (!curVal) {
      if (!this.numberRegex.test(event.key)) {
        event.preventDefault();
        return;
      }
      this.updateValue(event.key, event);
    }
    else {

      let selStart = (<any>event.target).selectionStart;
      let selEnd = (<any>event.target).selectionEnd;

      let newVal = curVal.slice(0, selStart) + event.key + curVal.slice(selEnd);

      if (!this.decimalRegex.test(newVal)) {
        event.preventDefault();
        return;
      }

      this.updateValue(newVal, event);
    }
  }

  onCut(event: ClipboardEvent) {

    let curVal: string = (<any>event.target).value;
    let selStart = (<any>event.target).selectionStart;
    let selEnd = (<any>event.target).selectionEnd;

    let newVal = curVal.slice(0, selStart) + curVal.slice(selEnd);

    this.updateValue(newVal, event);
  }

  onPaste(event: ClipboardEvent) {
    try {

      let pastedText = event.clipboardData.getData('text');
      if (!pastedText || pastedText.length < 1) {
        event.preventDefault();
        return;
      }

      let curVal: string = (<any>event.target).value;
      let selStart = (<any>event.target).selectionStart;
      let selEnd = (<any>event.target).selectionEnd;

      let newVal = curVal.slice(0, selStart) + pastedText + curVal.slice(selEnd);

      if (!this.decimalRegex.test(newVal)) {
        event.preventDefault();
        return;
      }

      this.updateValue(newVal, event);
    }
    catch (ex) {
      event.preventDefault();
      return;
    }
  }

  updateValue(newVal: string, event: any, checkDecimals: boolean = true) {
    let newFloat = null;

    if (newVal && newVal.length > 0) {

      let numDecimals = 0;
      if (checkDecimals) {
        let decSplit = newVal.split(".");
        if (decSplit.length > 1) {
          numDecimals = decSplit[1].length;
        }
      }

      newFloat = parseFloat(newVal);

      if (!checkDecimals || numDecimals >= 3) {
        if (this.min != null && this.min != undefined && newFloat && newFloat < this.min) {
          newFloat = this.min;
          this.inputControl.setValue(newFloat);
          if (event) {
            event.preventDefault();
          }
        }
        else if (this.max != null && this.max != undefined && newFloat && newFloat > this.max) {
          newFloat = this.max;
          this.inputControl.setValue(newFloat);
          if (event) {
            event.preventDefault();
          }
        }
      }
    }

    if (newFloat != this._value) {
      this._value = newFloat;
      this.valueChange.emit(newFloat);
    }
  }

}
