import { Component } from "@angular/core";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { TargetModuleRow } from "../../../services/target-selection-api/target-selection-api";

@Component({
  standalone: true,
  imports: [

  ],
  template: `
      @if(gCount > 0) {
        {{gCount}}
      }
      @else {
        <i class="pi pi-exclamation-circle" style="color: red; font-size: 2rem"></i>
      }
    `,
  styles: `
    `
})

export class GraphCountRenderer implements ICellRendererAngularComp {

  targetRow: TargetModuleRow;
  gCount: number;

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.targetRow = params.data;

    this.gCount = 0;
    if (this.targetRow) {
      if (this.targetRow.extractedIonChromatogramImg) {
        ++this.gCount;
      }
      if (this.targetRow.mS1SpectraImg) {
        ++this.gCount;
      }
      if (this.targetRow.mS2SpectraImg) {
        ++this.gCount;
      }
      if (this.targetRow.totalIonChromatogramImg) {
        ++this.gCount;
      }
    }

    return true;
  }

}
