import { Environment, getEnvironment } from './common';
import { AppSettings } from '../app/app.settings';

import { lcSettings } from './settings.lc';
import { dvSettings } from './settings.dv';
import { tstSettings } from './settings.tst';
// import { qaSettings } from './settings.qa';
// import { trSettings } from './settings.tr';
// import { prSettings } from './settings.pr';
// import { pocSettings } from './settings.poc';

export type SettingsMap = Record<Environment, AppSettings>;

export const settingsMap: SettingsMap = {
  lc: lcSettings,
  dv: dvSettings,
  tst: tstSettings

//   poc: pocSettings,
//   qa: qaSettings,
//   tr: trSettings,
//   pr: prSettings
};

export const settings: AppSettings = settingsMap[getEnvironment()];
