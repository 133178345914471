import { AfterViewInit, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryModuleRow, StrainModuleRow, TargetSelectionApi } from '../../../services/target-selection-api/target-selection-api';
import { TableModule } from 'primeng/table';
import { MoleculeStructureComponent } from "../../../rdkit/molecule-structure/molecule-structure.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterModel, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StructureRenderer, StructureRendererContext } from '../../metabolite/metabolite-module/structure-renderer.component';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { AppService } from '../../../services/app.service';
import { LoaderService } from '../../../services/loader.service';
import { first } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-strains-module',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ChipModule,
    AgGridAngular
  ],
  templateUrl: './strains-module.component.html',
  styleUrl: './strains-module.component.scss'
})
export class StrainsModuleComponent {

  strainRows: StrainModuleRow[] = [];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  };

  gridApi: GridApi<StrainModuleRow>;
  colDefs: ColDef[] = [
    {
      field: "strainId", headerName: "Strain ID", filter: "agSetColumnFilter", cellDataType: "text"
    },
    {
      headerName: "Strain Name"
    },
    {
      field: "taxonomy", headerName: "Taxonomy", filter: "agTextColumnFilter", cellDataType: "text"
    },
    {
      headerName: "Fraction"
    },
    { field: "countMsNode", headerName: "# MS Node", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer 
    },
    {
      field: "countGCF", headerName: "# GCF", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer
    },
    { field: "countSpectralMatch", headerName: "# Spectral Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer 
    },
    {
      headerName: "AntiSmash Output"
    },
    { field: "countRefGeneClusterMatch", headerName: "# Ref Gene Cluster Match", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer },
    {
      field:"countTarget", headerName: "Target", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer
    },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private targetSelectionApi: TargetSelectionApi,
    private appService: AppService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {

    this.getData();

    appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.getData();
    });

  }

  getData() {
    let batchName = this.appService.selectedBatchName();
    if (!batchName || batchName.length < 1) {
      return;
    }

    this.strainRows = null;
    this.loaderService.setLoading(true);

    this.targetSelectionApi.getStrainModuleRow(batchName).pipe(first()).subscribe(rows => {
      this.strainRows = rows;
      this.loaderService.setLoading(false);
    }, error => {this.handleError(error);})
  }

  handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
    this.loaderService.setLoading(false);
  }
}
