import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from "../../../pipes/TruncatePipe";
import { AgGridAngular } from 'ag-grid-angular';
import { BGCDeepDiveResponse, LockedStatus, LockTargetCommand, TargetSelectionApi, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { ColDef, FilterModel, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { LoaderService } from '../../../services/loader.service';
import { MessageService } from 'primeng/api';
import { TargetModuleService } from '../../../services/target-module.service';
import { debounceTime, first, forkJoin, Observable, Subject } from 'rxjs';
import { CountRenderer } from '../../metabolite/metabolite-module/count-renderer.component';
import { StatusRenderer } from '../../metabolite/metabolite-module/status-renderer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CreateTargetComponent, CreateTargetDialogInput } from '../../targets/create-target/create-target.component';
import { RejectTargetComponent, RejectTargetDialogInput } from '../../targets/reject-target/reject-target.component';

@Component({
  selector: 'app-bgc-module-deep-dive',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    TooltipModule,
    ChipModule,
    AgGridAngular,
    RejectTargetComponent,
    CreateTargetComponent
],
  templateUrl: './bgc-module-deep-dive.component.html',
  styleUrl: './bgc-module-deep-dive.component.scss'
})
export class BgcModuleDeepDiveComponent {
  @ViewChild("ctd") ctd: CreateTargetComponent;
  @ViewChild("rtd") rtd: RejectTargetComponent;

  bgcDeepDiveRows: BGCDeepDiveResponse[] = [];
  gridApi: GridApi<BGCDeepDiveResponse>;
  gcfId: number;
  refBGC: string;
  batchId: string;
  bgc: BGCDeepDiveResponse;
  selectedData: BGCDeepDiveResponse[] = [];
  private selectionSubject = new Subject<BGCDeepDiveResponse[]>();

  colDefs: ColDef[] = [
      {
        field: "gcF_ID", headerName: "GCF", filter: "agSetColumnFilter", cellDataType: "text"
      },
      {
        field: "strain", headerName: "Strain", filter: "agTextColumnFilter", cellDataType: "text"
      },
      { headerName: "AntiSmash Link"},
      {
        field: "contig", headerName: "Contig", comparator: this.contigComparator, filter: "agTextColumnFilter", cellDataType: "text"
      },
      {
        field: "region", headerName: "Region", filter: "agTextColumnFilter", cellDataType: "text"
      },
      {
        field: "bgC_ID", headerName: "BGC ID", filter: "agTextColumnFilter", cellDataType: "text"
      },
      {
        field: "ref_BGC", headerName: "Ref BGC", filter: "agSetColumnFilter", cellDataType: "text"
      },
      {
        field: "countMSNodeCorrelated", headerName: "# MS Node Correlated", filter: "agNumberColumnFilter", cellDataType: "number", cellRenderer: CountRenderer,
      },
      {
        field: "status", headerName: "Status", filter: "agSetColumnFilter", filterValueGetter: (p) => {
          return p.data.status?.status;
        }, cellRenderer: StatusRenderer, cellDataType: "text"
      },
    ];

  public defaultColDef: ColDef = {
      flex: 1,
      minWidth: 150,
      filter: "agTextColumnFilter",
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    };

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private targetSelectionApi: TargetSelectionApi,
      private appService: AppService,
      private loaderService: LoaderService,
      private messageService: MessageService,
      private targetService: TargetModuleService
    ) {
      if (this.activatedRoute.snapshot.queryParams['paramsGUID']) {
        let params = this.appService.getParameters(this.activatedRoute.snapshot.queryParams['paramsGUID']);
        if (params) {
          this.gcfId = params['gcfId'];
          this.refBGC = params['refBGC'];
          this.batchId = params['batchId'];
        }
      }
      else {
        this.gcfId = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "gcfId");
        this.refBGC = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "refBGC");
        this.batchId = appService.retrieveParameter(this.activatedRoute.snapshot.queryParams, "batchId");
      }

      appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
            if (this.selectedData.length > 0 && x == "target") {
              //this.selectedRow = this.metabolitesService.selectedData[0];
              let input = new CreateTargetDialogInput();
              input.batchId = this.selectedData[0].batchId;
              input.gcfId = this.selectedData[0].gcF_ID;
              input.gcfType = this.selectedData[0].gcF_Type;
              input.rowToUpdate = this.selectedData[0];
              input.strainID = this.selectedData[0].strain;
      
              this.ctd.show(input);
            }
      
            if (x == "rejected") {
      
              let input: RejectTargetDialogInput[] = [];
      
              for (let row of this.selectedData) {
                let x = new RejectTargetDialogInput();
                x.batchId = row.batchId;
                x.gcfId = row.gcF_ID;
                x.rowToUpdate = row;
      
                input.push(x);
              }
      
              this.rtd.show(input);
            }
      
            if (x == "locked") {
              let obs: Observable<TargetStatus>[] = [];
      
              for (let row of this.selectedData) {
                var cmd = new LockTargetCommand({
                  batchId: this.appService.selectedBatchName(),
                  gcfId: row.gcF_ID, status:
                    LockedStatus.Locked
                });
                obs.push(this.targetService.lockTarget(cmd, row));
              }
      
              forkJoin(obs).subscribe(x => {
                this.updateTargetButtons();
              });
            }
      
            if (x == "unlocked") {
              let obs: Observable<TargetStatus>[] = [];
      
              for (let row of this.selectedData) {
                var cmd = new LockTargetCommand({
                  batchId: this.appService.selectedBatchName(),
                  gcfId: row.gcF_ID, status:
                    LockedStatus.UnLocked
                });
                obs.push(this.targetService.lockTarget(cmd, row));
              }
      
              forkJoin(obs).subscribe(x => {
                this.updateTargetButtons();
              });
            }
      
          });
    }

    ngAfterViewInit(): void {

      if (this.batchId) {
        this.appService.selectBatch(this.batchId);
      }

      this.getData();

      this.selectionSubject.pipe(debounceTime(1000)).subscribe((v) => {
        this.setSelectedData(v);
      });
  
      if (this.batchId) {
        this.appService.selectBatch(this.batchId);
      }
    }

    getData() {
      this.bgcDeepDiveRows = null;
      this.loaderService.setLoading(true);

      this.targetSelectionApi.getBGCDeepDive(this.batchId, this.gcfId).pipe(first()).subscribe(rows => {
        this.bgcDeepDiveRows = rows;
        this.setFilter();
        this.bgc = this.bgcDeepDiveRows[0];
        this.loaderService.setLoading(false);
      }, error => {this.handleError(error);});
    }

    setFilter() {
      if (this.refBGC == "Yes" && this.gridApi && this.bgcDeepDiveRows) {
        this.loaderService.setLoading(true);
        setTimeout(() => {
          this.gridApi.setColumnFilterModel("ref_BGC", { values: [...['Yes']] }).then(res => this.gridApi.onFilterChanged());
          this.loaderService.setLoading(false);
        }, 100)
      }
    }

    handleError(error: any) {
      console.log(error);
      this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
      this.loaderService.setLoading(false);
    }

    onGridReady(params: GridReadyEvent) {
      this.gridApi = params.api;
    }

    targetCreated() {
      this.gridApi.deselectAll();
    }
  
    targetsRejected() {
      this.gridApi.deselectAll();
    }

    onSelectionChanged($event: SelectionChangedEvent<BGCDeepDiveResponse, any>) {
        var rowCount = $event.api.getSelectedNodes().length;
        console.log(rowCount);
        this.selectionSubject.next($event.api.getSelectedNodes().map(x => x.data));
    }

    updateTargetButtons() {
      this.appService.showUnLockButton.set(false);
      this.appService.showLockButton.set(false);
      this.appService.showRejectButton.set(false);
      this.appService.showTargetButton.set(false);
  
      if (!this.selectedData) return;
  
      if (this.selectedData.length > 0 && !["target", "rejected"].includes(this.selectedData[0].status.status)) {
        this.appService.showTargetButton.set(true);
      }
  
      if (this.selectedData.length > 0) {
        const targetRows = this.selectedData.filter(x => x.status?.status == "target");
        const rejectRows = this.selectedData.filter(x => x.status?.status == "rejected");
        const lockedByMeRows = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId == this.appService.currentUser); // also check on show locked
        const lockedBySomeoneElse = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId != this.appService.currentUser); // also check on show locked
        const noStatusRows = this.selectedData.filter(x => !x.status || !x.status.status);
  
        if (targetRows?.length > 0) {
          return;
        }
  
        if (noStatusRows?.length == this.selectedData.length) {
          this.appService.showLockButton.set(true);
        }
  
        if (lockedByMeRows?.length == this.selectedData.length) {
          this.appService.showUnLockButton.set(true);
        }
  
        if (targetRows?.length == 0 && rejectRows?.length == 0) {
          this.appService.showRejectButton.set(true);
        }
      }
    }
  
    contigComparator(contig1: string, contig2: string) {
      const contig1number = Number.parseInt(contig1);
      const contig2number = Number.parseInt(contig2);

      if (Number.isNaN(contig1number) && Number.isNaN(contig2number)) {
        return 0;
      }
      if (Number.isNaN(contig1number)) {
        return -1;
      }
      if (Number.isNaN(contig2number)) {
        return 1;
      }

      return Math.sign(contig1number - contig2number);
    }

    onFirstDataRendered(params: any) {
      if (this.bgcDeepDiveRows[0]?.gcF_ID && this.gridApi) {
        this.gridApi.forEachNode(node => {
          if (node.data.gcF_ID == this.bgcDeepDiveRows[0]?.gcF_ID) {
            node.setSelected(true);
          }
        });
      }
    }

    setSelectedData(rows: BGCDeepDiveResponse[]) {
      this.selectedData = rows;
      this.updateTargetButtons();
    }
}
