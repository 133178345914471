import { Component } from "@angular/core";
import { UserChipComponent } from "../../../user-chip/user-chip.component";
import { TargetStatus } from "../../../services/target-selection-api/target-selection-api";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    standalone: true,
    imports: [
      UserChipComponent
    ],
    template: `
        <div>
         @switch (status?.status) {
          @case ("locked") {
            <i class="pi pi-lock status-icon locked" pTooltip="Locked" tooltipPosition="left"></i>
            <app-user-chip [email]="status.userId" [mode]="'avatar'"></app-user-chip>
  
          }
          @case ("target") {
            <i class="pi pi-bullseye status-icon target" pTooltip="Target" tooltipPosition="left"></i>
            <app-user-chip [email]="status.userId" [mode]="'avatar'"></app-user-chip>
          }
          @case ("rejected") {
            <i class="pi pi-ban status-icon rejected" pTooltip="Rejected" tooltipPosition="left"></i>
            <app-user-chip [email]="status.userId" [mode]="'avatar'"></app-user-chip>
          }
        }
        </div>
    `,
    styles: `
    @import "../../../../../node_modules/@corteva-research/ngx-components-core/assets/styles/colors.scss";
  
    .status-icon {
      color: $res-color-blue;
      margin-right: 12px;
      font-size: 1.5rem;
  }
  
    .target {
        color: $res-color-green;
    }
  
    .rejected {
        color: $res-color-red;
    }
    
    
    `
  })
  export class StatusRenderer implements ICellRendererAngularComp {
    status: TargetStatus;
  
    agInit(params: ICellRendererParams<any, any, any>): void {
      this.refresh(params);
    }
    
    refresh(params: ICellRendererParams<any, any, any>): boolean {
      this.status = params.value;
      return true;
    }
  
  }
  