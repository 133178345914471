import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, BehaviorSubject, debounceTime, Subject, tap } from 'rxjs';


@Injectable()
export class LoaderService {
    private _isLoading = new Subject<boolean>()

    public isLoading$: Observable<boolean>;
    private trueCount: number = 0;

    constructor() {
        //this.isLoading$ = this._isLoading.pipe(debounceTime(100));
        this.isLoading$ = this._isLoading;

        //this.isLoading$.subscribe(x=> console.log(`value is ${x}`));
    }

    setLoading(value: boolean, dontModifyCount: boolean = false) {

      //console.log(`setLoading: ${value}`);

      if (value) {
        ++this.trueCount;

        if (this.trueCount == 1) {
          //console.log("setting _isLoading: true");)
          this._isLoading.next(true);
        }

        if (dontModifyCount) {
          --this.trueCount;
        }
      }
      else {
        --this.trueCount;

        if (this.trueCount == 0) {
          //console.log("setting _isLoading: false");
          this._isLoading.next(false);
        }

        if (dontModifyCount) {
          ++this.trueCount;
        }
      }

    }
}
