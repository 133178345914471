import { Component } from "@angular/core";
import { UserChipComponent } from "../../../user-chip/user-chip.component";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    standalone: true,
    imports: [
      UserChipComponent
    ],
    template: `
        <app-user-chip [email]="email" [mode]="'avatar'"></app-user-chip>
    `,
    styles: `
    `
  })

  export class UserRenderer implements ICellRendererAngularComp {
    email: string;

    agInit(params: ICellRendererParams<any, any, any>): void {
      this.refresh(params);
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
      this.email = params.value;
      return true;
    }

  }
