import { Component, HostListener, Inject, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { Batch, TargetSelectionApi } from './services/target-selection-api/target-selection-api';
import { DecimalPipe, DatePipe, CurrencyPipe, AsyncPipe, CommonModule } from '@angular/common';
import moment from 'moment';
import { ButtonModule } from 'primeng/button';


import { PublicClientApplication } from "@azure/msal-browser";

import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterComponent } from './filter/filter.component';
import { Observable } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarModule } from 'primeng/progressbar';
import { LoaderService } from './services/loader.service';
import { DialogModule } from 'primeng/dialog';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { AppService, TSModule } from './services/app.service';
import { MSGraphService } from './services/msgraph.service';
import { APP_SETTINGS_TOKEN, AppSettings } from './app.settings';
import { BadgeModule } from 'primeng/badge';
import {LicenseManager} from "ag-grid-enterprise";


LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-063443}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Pioneer_Hi-Bred_Intl_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{27}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_September_2025}____[v3]_[01]_MTc1Njc2NzYwMDAwMA==63e11e68d69287b34a91ac47a17194ac");



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DatePipe,
    ResearchComponentsCoreModule,
    DashboardComponent,
    ButtonModule,
    FilterComponent,
    AsyncPipe,
    TooltipModule,
    RouterLink,
    ChipModule,
    DecimalPipe,
    SidebarModule,
    CommonModule,
    ProgressBarModule,
    BlockUIModule,
    ToastModule,
    BadgeModule
  ],
  providers: [
    // need to trick ResearchComponentsCoreModule into thinking it hasn't
    // already been imported. Services were registered in main.ts but
    // components will get registered here.
    { provide: ResearchComponentsCoreModule, useValue: undefined }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'target-selection-ui';
  batches: Batch[] = [];
  sidebarVisible = false;
  //isLoading: boolean;
  isProd = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    public loaderService: LoaderService,
    public appService: AppService,
    public msGraphService: MSGraphService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(APP_SETTINGS_TOKEN) private settings: AppSettings
  ) {

    //this.loaderService.isLoading$.subscribe(x => this.isLoading = x);

    this.appService.batches$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.batches = x;
      this.appService.restoreLocalStorage();
    });

    this.isProd = settings.isProd;

  }


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    // Your cleanup code here
    console.log('App is closing');

    // Optionally, you can prompt the user to confirm leaving
    // event.returnValue = 'Are you sure you want to leave?';
    // for(let w of this.windows) {
    //   try {
    //     w.close();
    //   }
    //   catch {}
    // }

    this.appService.beforeUnload();
  }


  public async ngOnInit() {
    await this.authService.instance.initialize();
    this.authService.instance.handleRedirectPromise().then(res => {
      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account);
      }
    });

   

  }

  selectBatch(batchName: string) {
    this.appService.selectBatch(batchName);
  }

  selectModule(module: TSModule) {
    this.appService.selectModule(module);
  }

  showSideBar() {
    this.sidebarVisible = true;

    this.router
  }

  onShowFilter(event: any) {
    this.appService.showFilter();
  }

  onHideFilter(event: any) {
    this.appService.hideFilter();
  }

  refresh() {
    this.appService.refresh();
  }

}
