import { Component } from "@angular/core";
import { UserChipComponent } from "../../../user-chip/user-chip.component";
import { TargetStatus } from "../../../services/target-selection-api/target-selection-api";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { BadgeModule } from "primeng/badge";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  standalone: true,
  imports: [
    BadgeModule,
  ],
  template: `
        @if(urlValue) {
        <a href="javascript:void(0)"  (click)="urlClicked()">{{urlValue}}</a>
        }
    `,
  styles: `
    .count-badge:hover  { 
      cursor: pointer;
    }
    `
})
export class UrlRenderer implements ICellRendererAngularComp {
  urlValue: any;
  field: string;
  clicked: (value: any, field: string) => void;
  clickedWRow: (value: any, field: string, row: any) => void;
  row: any;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.row = params.data;
    this.urlValue = params.value;
    this.field = params.colDef.field;
    this.clicked = (<any>params)["clicked"];
    this.clickedWRow = (<any>params)["clickedWRow"];
    return true;
  }

  urlClicked() {
    if (this.clicked)
      this.clicked(this.urlValue, this.field);
    else if (this.clickedWRow)
      this.clickedWRow(this.urlValue, this.field, this.row);
  }


}
