import { TargetModuleRow, TargetSelectionApi } from './../../../services/target-selection-api/target-selection-api';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-enterprise';
import { MessageService } from 'primeng/api';
import { SplitterModule } from 'primeng/splitter';
import { LoaderService } from '../../../services/loader.service';
import { first } from 'rxjs';
import { GraphCountRenderer } from '../../metabolite/metabolite-module/graph-count-renderer.component';
import { TabViewModule } from 'primeng/tabview';
import { ImageModule } from 'primeng/image';
import { UserRenderer } from '../../metabolite/metabolite-module/user-renderer.component';

@Component({
  selector: 'app-targets-module',
  standalone: true,
  imports: [
    SplitterModule,
    CommonModule,
    AgGridAngular,
    TabViewModule,
    ImageModule
  ],
  templateUrl: './targets-module.component.html',
  styleUrl: './targets-module.component.scss'
})
export class TargetsModuleComponent implements AfterViewInit {

  bottomPanelPercentage: number = 50;
  splitPanelHeight = this.getSplitPannelSize();
  graphBorderHeight: number;
  graphHeight: number;
  bottomPanelHeight: number;
  gridApi: GridApi;
  tmRows: TargetModuleRow[];
  selectedRow: TargetModuleRow;

  constructor(
    private targetSelectionApi: TargetSelectionApi,
    private messageService: MessageService,
    private loaderService: LoaderService
  ) {

  }

  ngAfterViewInit(): void {
    this.updateBottomPanelPercentage(this.splitPanelHeight);
    this.getData();
  }

  colDefs: ColDef[] = [
    { field: "targetId", headerName: "Target ID", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "mz", headerName: "m/z", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "adduct", headerName: "Aduct", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "microbeType", headerName: "Microbe Type", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "m", headerName: "[M]", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "msNodeId", headerName: "MS Node ID", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "gcfId", headerName: "GCF ID", filter: "agNumberColumnFilter", cellDataType: "number" },
    { field: "msScanId", headerName: "MS Scan ID", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "selectionCriteria", headerName: "Selection Criteria", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "strain", headerName: "Strain", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "batchId", headerName: "Batch ID", filter: "agTextColumnFilter", cellDataType: "text" },
    { field: "createdDate", headerName: "Created Date", filter: "agDateColumnFilter", cellDataType: "date" },
    { headerName: "Graph", cellRenderer: GraphCountRenderer},
    { field: "targetSelectedByUser", headerName: "Target Selected By", filter: "agSetColumnFilter", cellDataType: "text", cellRenderer: UserRenderer },
  ];

  tmColDefs: ColDef[] = [
    { field: "materialId", headerName: "Material ID", filter: "agNumberColumnFilter", cellDataType: "number" },
  ];

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.splitPanelHeight = this.getSplitPannelSize();
  }

  getSplitPannelSize() {
    let splitHeight = window.innerHeight - 180;
    this.updateBottomPanelPercentage(splitHeight);
    return splitHeight;
  }

  onSplitterResizeEnd(event: any) {
    this.bottomPanelPercentage = event.sizes[1];
    this.updateBottomPanelPercentage(this.splitPanelHeight);
  }

  updateBottomPanelPercentage(splitHeight: number) {
    this.bottomPanelHeight = (this.bottomPanelPercentage/100*splitHeight - 61); // subtract height of tab panel header
    this.graphBorderHeight = (this.bottomPanelHeight - 5)/2;
    this.graphHeight = this.graphBorderHeight - 15;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onSelectionChanged(event: any) {
    let selectedRows = event.api?.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      this.selectedRow = selectedRows[0];
    }
    else {
      this.selectedRow = undefined;
    }
    let blah = 23424;
  }

  getData() {
    this.loaderService.setLoading(true);
    this.targetSelectionApi.getTargetModuleRows().pipe(first()).subscribe(rows => {
      this.tmRows = rows;
      this.loaderService.setLoading(false);
    }, error => {this.handleError(error);})
  }

  handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Service Error', detail: error.message });
    this.loaderService.setLoading(false);
  }
}
