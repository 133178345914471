import { Component } from '@angular/core';

@Component({
  selector: 'app-strains-module',
  standalone: true,
  imports: [],
  templateUrl: './strains-module.component.html',
  styleUrl: './strains-module.component.scss'
})
export class StrainsModuleComponent {

}
