import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, AgPromise, IDoesFilterPassParams, IAfterGuiAttachedParams } from 'ag-grid-enterprise';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { MoleculeStructureComponent } from "../rdkit/molecule-structure/molecule-structure.component";
import { ChemdrawWrapperComponent } from "../chemdraw-wrapper/chemdraw-wrapper.component";
import { DialogModule } from 'primeng/dialog';
import { RDKitLoaderService } from '../services/rdkit-loader.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RDKitModule } from '@rdkit/rdkit';
import { MetaboliteModuleRow } from '../services/target-selection-api/target-selection-api';
import { fromReadableStreamLike } from 'rxjs/internal/observable/innerFrom';
import { MetaboliteModuleService } from '../services/metabolite-module.service';

@Component({
  selector: 'app-structure-filter',
  standalone: true,
  imports: [FormsModule,
    CommonModule,
    InputGroupModule,
    ButtonModule,
    MoleculeStructureComponent,
    ChemdrawWrapperComponent,
    DialogModule],
  templateUrl: './structure-filter.component.html',
  styleUrl: './structure-filter.component.scss'
})
export class StructureFilterComponent implements IFilterAngularComp {

  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;


  smiles: string;
  filterParams!: IFilterParams;
  showChemdraw = false;
  rdkit: RDKitModule;
  isActive = false;

  passingSmiles = new Set();
  updatePassingSmiles: boolean;
  afterGuiAttachedParams: IAfterGuiAttachedParams;

  constructor(private rdkitService: RDKitLoaderService,
    private metaboliteModuleService: MetaboliteModuleService
  ) {
    this.rdkitService.getRDKit().pipe(takeUntilDestroyed()).subscribe(x => {
      this.rdkit = x;

    });
  }


  agInit(params: IFilterParams<any, any>): void {
    this.filterParams = params;
  }

  isFilterActive(): boolean {
    //return this.smiles != null && this.smiles !== '';
    //return this.isActive;
    return false;
  }

  getModel() {
    return { value: this.smiles };
  }

  setModel(model: any): void | AgPromise<void> {
    this.smiles = model == null ? null : model.value;
  }

  refresh?(newParams: IFilterParams): boolean {
    return false;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;

    if (!this.smiles) return true;

    // var row = <MetaboliteModuleRow>params.data;
    // if (!row.spectrumCompoundSMILES) return false;


    const { node } = params;
    const value = this.filterParams.getValue(node);
    if (!value) return false;

    //return value == this.smiles;



    if (this.updatePassingSmiles) {

      const qmol = this.rdkit.get_qmol(this.smiles);

      try {

        const mol = this.rdkit.get_mol(value);
        try {
          const matches: string | any[] = !!mol && !!qmol ? mol.get_substruct_match(qmol) : [];
          if (matches.length > 2) {
            this.passingSmiles.add(value);
            //return true;
          }
          // else {
          //   return false;
          // }
        } finally {
          mol?.delete();
        }

      } finally {
        qmol?.delete();
      }
    }


    return this.passingSmiles.has(value)
  }



  onNewRowsLoaded?(): void {
  }

  onAnyFilterChanged?(): void {
  }

  getModelAsString?(model: any): string {
    return this.smiles;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.afterGuiAttachedParams = params;

  }

  afterGuiDetached?(): void {
  }

  ok() {
    if (this.showChemdraw) {
      this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
        this.smiles = smiles;
      });
      this.showChemdraw = false;
    }

    if (this.afterGuiAttachedParams.hidePopup) {
      this.afterGuiAttachedParams.hidePopup();
    }
    // this.updatePassingSmiles = true;
    // this.passingSmiles.clear();
    // this.isActive = true;
    // this.filterParams.filterChangedCallback();
    // this.isActive = false;
    // this.updatePassingSmiles = false;
    this.metaboliteModuleService.filter.smiles = this.smiles;
    this.metaboliteModuleService.applyFilter();
  }

  clearSmiles() {
    this.smiles = undefined;
    this.ok();
  }

}
