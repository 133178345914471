import { Injectable, signal } from '@angular/core';
import { AppService } from './app.service';
import { CreateTargetCommand, CreateTargetInfo, LockedStatus, LockTargetCommand, MetaboliteModuleRow, RejectTargetCommand, TargetSelectionApi, TargetStatus } from './target-selection-api/target-selection-api';
import { RDKitLoaderService } from './rdkit-loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { RDKitModule } from '@rdkit/rdkit';
import { debounceTime, map, Observable, of, shareReplay, Subject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageService } from './local-storage.service';
import { FilterItem } from '../filter/filter.component';
import { LoaderService } from './loader.service';
import { NavigationExtras, Router } from '@angular/router';

export interface MetabliteModuleFilter {
  smiles?: string;
  cScoreRange?: number[];
  cScoreMin?: number;
  cScoreMax?: number;

  filterConfig?: FilterItem[];
}

export interface FilterInterface {
  (input: any[]): any[];
}



@Injectable({
  providedIn: 'root'
})
export class MetaboliteModuleService {


  filter: MetabliteModuleFilter;// =getMetFilter();

  //loading = signal<boolean>(false);
  data: MetaboliteModuleRow[] = [];
  filteredData = signal<MetaboliteModuleRow[]>([]);//    MetaboliteModuleRow[] = [];
  selectedData: MetaboliteModuleRow[] = [];

  //rdkit$: Observable<RDKitModule>;

  beforeFilterApplied$ = new Subject<boolean>();
  rdkit: RDKitModule;

  filters: FilterInterface[] = [];

  dataLoaded$ = new Subject<boolean>();


  //selectionCriteria = ["C-score", "M/G correlation", "Metaboloactivity correlation", "HCA-PCA selection", "Hypothesis based"];
  createTargetInfo: CreateTargetInfo;



  constructor(
    private client: TargetSelectionApi,
    private rdkitService: RDKitLoaderService,
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private appService: AppService,
    private localStorage: LocalStorageService,
    public loaderService: LoaderService,
    private router: Router) {

    this.filter = this.getSavedFilter();

    // appService.targetButtonClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
    //   this.updateTargetButtons();
    // });

    // appService.batchSelected$.pipe(takeUntilDestroyed()).subscribe(x => {
    //   if (this.appService.selectedModule().name == "Metabolites") {
    //     this.getData();
    //   }
    // });

    appService.refreshDatasetClicked$.pipe(takeUntilDestroyed()).subscribe(x => {
      this.beforeFilterApplied$.next(true);
      this.filter = this.getMetFilter();
      this.getData();
    });

    this.rdkitService.getRDKit().subscribe(x => {
      this.rdkit = x;
      this.addFilter((input) => { return this.sss.bind(this)(input); });

      // if (this.appService.selectedBatchName()) {
      //   this.getData();
      // }
    });
  }


  getSavedFilter() {
    //var ls = this.localStorage.getItem<MetabliteModuleFilter>("metabolites-module-filter");
    var ls: MetabliteModuleFilter = undefined;
    var xxx = this.getMetFilter();

    if (!ls) {
      return xxx;
    }
    else {
      for (var i of xxx.filterConfig) {
        if (!ls.filterConfig.find(x => x.path == i.path)) {
          ls.filterConfig.push(i);
        }
      }

      return ls;
    }
  }


  getMetFilter(): MetabliteModuleFilter {
    return {
      filterConfig: [
        {
          path: "cScore",
          name: "C Score",
          matchMode: "between",
          type: "number"
        },
        {
          path: "msNodeId",
          name: "MS Node Id",
          matchMode: "equals",
          type: "number"
        },
        {
          path: "status.status",
          name: "Status",
          matchMode: "in",
          type: "string"
        },
        {
          path: "mz",
          name: "m/z",
          matchMode: "between",
          type: "number"
        },
      ]
    };
  }

  addFilter(f: FilterInterface) {
    this.filters.push(f);
  }

  getFilteredData(): any[] {
    var retval = this.data.filter(x => true);
    for (var f of this.filters) {
      retval = f(retval);
    }
    return retval;
  }

  getData() {
    this.loaderService.setLoading(true);
    this.data = [];
    this.filteredData.set([]);
    this.client.getMetaboliteModuleRow(this.appService.selectedBatchName(), this.appService.globalFilter.cscore).subscribe(x => {
      this.data = x;
      //var fd = this.getFilteredData();
      this.filteredData.set([...x]);
      //this.updateFilterValues(fd);
    }, null, () => this.loaderService.setLoading(false));
  }

  sss(data: MetaboliteModuleRow[]): MetaboliteModuleRow[] {

    var search = this.filter.smiles;

    if (!search) {
      return this.data;
    }

    const qmol = this.rdkit.get_qmol(search);
    try {
      const rtn = data.filter(x => x.spectrumCompoundSMILES).filter((row) => {
        const mol = this.rdkit.get_mol(row.spectrumCompoundSMILES);
        try {
          const matches: string | any[] = !!mol && !!qmol ? mol.get_substruct_match(qmol) : [];
          return matches.length > 2;
        } finally {
          mol?.delete();
        }
      });
      return rtn;
    } finally {
      qmol?.delete();
    }

  }

  applyFilter() {
    this.beforeFilterApplied$.next(false);
    this.filteredData.set(this.getFilteredData());//        [...filteredRows];
    this.localStorage.setItem<MetabliteModuleFilter>("metabolites-module-filter", this.filter);
  }

  reset() {
    if (this.data.length > 0) {
      this.beforeFilterApplied$.next(true);
      this.filter = this.getMetFilter();
      this.filteredData.set(this.data.filter(x => true));
    }
  }

  updateFilterValues(rows: MetaboliteModuleRow[]) {
    if (!rows) return;

    this.filter.cScoreMin = Math.min(...rows.filter(x => x).map(x => x.cScore));
    this.filter.cScoreMax = Math.max(...rows.filter(x => x).map(x => x.cScore));
    this.filter.cScoreRange = [this.filter.cScoreMin, this.filter.cScoreMax];
  }

  // createTarget(cmd: CreateTargetCommand): Observable<TargetStatus> {
  //   return this.client.createTarget(cmd).pipe(tap(x => {
  //     console.log(`Target ${x} created`);
  //     //todo .. raise a TargetCreated Event
  //   }));
  // }

  // lockTarget(cmd: LockTargetCommand, row: MetaboliteModuleRow): Observable<TargetStatus> {
  //   return this.client.lockTarget(cmd).pipe(tap(x => {
  //     if (row) {
  //       if (cmd.status == LockedStatus.Locked) {
  //         Object.assign(row.status, x);
  //       }
  //       else if (cmd.status == LockedStatus.UnLocked) {
  //         row.status.status = "";
  //       }
  //     }
  //   }));
  // }

  // rejectTarget(cmd: RejectTargetCommand, row: MetaboliteModuleRow): Observable<TargetStatus> {
  //   return this.client.rejectTarget(cmd).pipe(tap(x => {
  //     if (row) {
  //       Object.assign(row.status, x);
  //     }
  //   }));
  // }


  // getCreateTargetInfo(msNodeId: number | undefined, gcfId: number | undefined): Observable<CreateTargetInfo> {
  //   return this.client.getCreateTargetInfo(msNodeId, gcfId, this.appService.selectedBatchName()).pipe(tap(x => {
  //     console.log(`getCreateTargetInfo called`);
  //     this.createTargetInfo = x;
  //   }));

  // }



  setSelectedData(rows: MetaboliteModuleRow[]) {
    this.selectedData = rows;
    this.updateTargetButtons();
  };

  updateTargetButtons() {
    this.appService.showUnLockButton.set(false);
    this.appService.showLockButton.set(false);
    this.appService.showRejectButton.set(false);
    this.appService.showTargetButton.set(false);

    if (!this.selectedData) return;

    if (this.selectedData.length == 1 && !["target", "rejected"].includes(this.selectedData[0].status.status)) {
      this.appService.showTargetButton.set(true);
    }

    if (this.selectedData.length > 0) {
      const targetRows = this.selectedData.filter(x => x.status?.status == "target");
      const rejectRows = this.selectedData.filter(x => x.status?.status == "rejected");
      const lockedByMeRows = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId == this.appService.currentUser); // also check on show locked 
      const lockedBySomeoneElse = this.selectedData.filter(x => x.status?.status == "locked" && x.status?.userId != this.appService.currentUser); // also check on show locked 
      const noStatusRows = this.selectedData.filter(x => !x.status || !x.status.status);

      if (targetRows?.length > 0) {
        return;
      }

      if (noStatusRows?.length == this.selectedData.length) {
        this.appService.showLockButton.set(true);
      }

      if (lockedByMeRows?.length == this.selectedData.length) {
        this.appService.showUnLockButton.set(true);
      }

      if (targetRows?.length == 0 && rejectRows?.length == 0) {
        this.appService.showRejectButton.set(true);
      }
    }

  }

  navigateFromCount(msNodeId: number, type: string) {
    this.client.getLinkIds(this.appService.selectedBatchName(), msNodeId, type).subscribe(x => {
      if (!x || x.length == 0) return;

      console.log(x);
      console.log(type);

      if (["countExactMassMatch", "countSpectralMatch"].includes(type)) {
        // const queryParams: any = {};
        // queryParams.COMPOUND_IDS = JSON.stringify(x);
        // const navigationExtras: NavigationExtras = {
        //   queryParams
        // };
        // this.router.navigate(['/library'], navigationExtras);

        this.appService.openModuleNewWindow(JSON.stringify(x),"COMPOUND_IDS","library")
      }
      else if (type == "countGCF") {
        // const queryParams: any = {};
        // queryParams.GCF_IDS = JSON.stringify(x);
        // const navigationExtras: NavigationExtras = {
        //   queryParams
        // };
        // this.router.navigate(['/bgc'], navigationExtras);

        this.appService.openModuleNewWindow(JSON.stringify(x),"GCF_IDS","bgc")

      }


    });
  }

  // navigate(id: any, idType: string, moduleName: string) {
  //   console.log(`value=${id} field=${idType}`);

  //   const queryParams: any = {};
  //   queryParams[idType] = JSON.stringify([String(id)]);;
  //   const navigationExtras: NavigationExtras = {
  //     queryParams
  //   };

  //   var url = this.router.serializeUrl(this.router.createUrlTree([`/${moduleName}`], navigationExtras));

  //   this.appService.openWindow(url);
  // }





  // xxx() {
  //   if (type == "strainID") {
  //     const queryParams: any = {};
  //     queryParams.STRAIN_IDS = JSON.stringify(x);
  //     const navigationExtras: NavigationExtras = {
  //       queryParams
  //     };
  //     this.router.navigate(['/strains'], navigationExtras);
  //   }


  // }
}
