import { InjectionToken } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Configuration } from '@azure/msal-browser';

export interface AppSettings {
  baseUrl: string;
  environment: 'local' | 'poc' | 'dv' | 'qa' | 'tr' | 'pr';
  isProd : boolean;
 
  msal: {
    config: Configuration;
    guard: MsalGuardConfiguration;
    interceptor: MsalInterceptorConfiguration;
  };

  appInsightsInstrumentationKey: string;
}

export const APP_SETTINGS_TOKEN = new InjectionToken<AppSettings>('APP_SETTINGS_TOKEN');
