import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { CreateTargetCommand, MetaboliteModuleRow, TargetStatus } from '../../../services/target-selection-api/target-selection-api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { AppService } from '../../../services/app.service';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MSGraphService } from '../../../services/msgraph.service';
import { ActivatedRoute } from '@angular/router';
import { StepperModule } from 'primeng/stepper';
import { InputGroupModule } from 'primeng/inputgroup';
import { MetaboliteDeepDiveService } from '../../../services/metabolite-deep-dive.service';
import { ChemdrawWrapperComponent } from '../../../chemdraw-wrapper/chemdraw-wrapper.component';
import { TargetModuleService } from '../../../services/target-module.service';
import { ImageModule } from 'primeng/image';


export class CreateTargetDialogInput {
  msNodeId: number;
  mz: number;
  spectralFamilyId: number;
  strainID: string;
  gcfId: number;
  gcfType: string;
  spectrumCompoundSMILES: string;
  batchId: string;
  rowToUpdate: any;
}


@Component({
  selector: 'app-create-target',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ResearchComponentsCoreModule,
    BlockUIModule,
    OverlayPanelModule,
    MoleculeStructureComponent,
    CardModule,
    TooltipModule,
    ButtonModule,
    FloatLabelModule,
    FormsModule,
    BadgeModule,
    ChipModule,
    SliderModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    InputGroupModule,
    ChemdrawWrapperComponent,
    ImageModule
  ],
  templateUrl: './create-target.component.html',
  styleUrl: './create-target.component.scss'
})
export class CreateTargetComponent implements AfterViewInit {

  @ViewChild("cdw") cdw: ChemdrawWrapperComponent;


  display: boolean;
  // @Output() displayChange = new EventEmitter();
  input: CreateTargetDialogInput;

  mode: "GCF" | "MSNODE";

  //input: CreateTargetDialogInput;

  @Output() targetCreated = new EventEmitter<TargetStatus>();

  createTargetFormGrp: FormGroup;
  createTargetCmd: CreateTargetCommand;
  creatingTarget: boolean;
  activeStep: number = 0;
  showChemdraw = false;

  public imageSrc = "";

  constructor(
    private messageService: MessageService,
    public targetService: TargetModuleService,
    private appService: AppService,
    private graphService: MSGraphService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mmdd: MetaboliteDeepDiveService
  ) {


    this.createTargetFormGrp = this.fb.group({
      selectionType: [null, Validators.required],
      hypothesis: ['', [Validators.required]],
      type: [null, [Validators.required]],
      adduct: [null],
      gcfId: [null],
      msNodeId: [null],
      materialIds: [null, [this.ValidateMaterialIds]]
    });
  }


  ngAfterViewInit(): void {
    // this.createTargetCmd = new CreateTargetCommand({
    //   msNodeId: this.selectedRow.msNodeId,
    //   batchId: this.selectedRow.batchId,
    //   strainID: this.selectedRow.strainID,
    //   predictedStructureSMILES: this.selectedRow.spectrumCompoundSMILES
    // });
  }

  onSubmit() {
    if (this.createTargetFormGrp.valid) {
      this.createTarget();
    }
  }

  createTarget() {
    this.creatingTarget = true;
    this.updateCreateTargetCommand();

    this.targetService.createTarget(this.createTargetCmd).subscribe(x => {
      Object.assign(this.input.rowToUpdate.status, x);
      this.targetCreated.emit(x);
    }, null, () => {
      this.display = false;
      this.creatingTarget = false;
    });
  }

  private updateCreateTargetCommand() {
    this.createTargetCmd.selectionType = this.createTargetFormGrp.get("selectionType").value;
    this.createTargetCmd.hypothesis = this.createTargetFormGrp.get("hypothesis").value;
    this.createTargetCmd.adduct = this.createTargetFormGrp.get("adduct").value;
    if (this.mode == "MSNODE") {
      this.createTargetCmd.gcfId = this.createTargetFormGrp.get("gcfId").value;
    }
    if (this.mode == "GCF") {
      this.createTargetCmd.msNodeId = this.createTargetFormGrp.get("msNodeId").value;
    }
    this.createTargetCmd.type = this.createTargetFormGrp.get("type").value;
    this.createTargetCmd.materialIds = this.getMaterialIds();
  }

  updateSmiles() {
    if (this.showChemdraw) {
      this.cdw.chemdraw.getSMILES((smiles: any, error: any) => {
        this.createTargetCmd.predictedStructureSMILES = smiles;
      });
      this.showChemdraw = false;
    }
  }

  show(input: CreateTargetDialogInput) {
    this.input = input;

    this.mode = input.msNodeId ? "MSNODE" : input.gcfId ? "GCF" : undefined;
    if (!this.mode) throw new Error("MSNodeId or GCFId not provieded.");

    this.createTargetCmd = new CreateTargetCommand({
      msNodeId: this.input.msNodeId,
      batchId: this.input.batchId,
      strainID: this.input.strainID,
      gcfId: this.input.gcfId,
      predictedStructureSMILES: this.input.spectrumCompoundSMILES
    });

    this.createTargetFormGrp.reset();

    this.targetService.getCreateTargetInfo(this.input.msNodeId, this.input.gcfId).subscribe(x => {
      this.activeStep = 0;
      this.display = true;
    });


  }

  public onPaste(event: ClipboardEvent, imgSrc: string): void {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const items = clipboardData.items;
      // Check if there is at least one item and if the first item is an         image
      if (items.length > 0 && items[0].type.indexOf('image') !== -1) {
        // Attempt to retrieve the image file from the clipboard item
        const blob = items[0].getAsFile();
        if (blob) {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target)
              // Setting the component's imageSrc property to the base64 data URL of the image
              (<any>this.createTargetCmd)[imgSrc] = event.target.result as string;
          };
          reader.readAsDataURL(blob);
        }
      }
    }
  }

  public getChartCount(): number {
    let chartCount = 0;

    if (this.createTargetCmd.extractedIonChromatogram) chartCount++;
    if (this.createTargetCmd.totalIonChromatogram) chartCount++;
    if (this.createTargetCmd.mS1Spectra) chartCount++;
    if (this.createTargetCmd.mS2Spectra) chartCount++;

    return chartCount;
  }

  public getMaterialIds(): number[] | undefined {
    let matIdsString = this.createTargetFormGrp.get("materialIds").value;
    if (!matIdsString) return undefined;

    return matIdsString.split(/[\s\n]+/).map(Number).filter((x: any) => !isNaN(x) && x != 0);
  }

  public ValidateMaterialIds(control: AbstractControl) {

    let matIdsString = control.value;
    if (!matIdsString) return undefined;

    let invalid = matIdsString.split(/[\s\n]+/).map(Number).filter(isNaN);

    if (invalid && invalid.length > 0) {
      return { invalidMaterialIds: true };
    }

    return null;
  }

  stepChanged(step: number) {
    if (step == 4) {
      this.updateCreateTargetCommand();

    }
  }

}
