import { Component } from '@angular/core';

@Component({
  selector: 'app-targets-module',
  standalone: true,
  imports: [],
  templateUrl: './targets-module.component.html',
  styleUrl: './targets-module.component.scss'
})
export class TargetsModuleComponent {

}
