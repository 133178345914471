<div class="flex flex-row">
    <div class="flex">
        <p-inputGroup>
            <input id="smiles" type="text" pInputText [(ngModel)]="smiles">
            <p-button type="button" icon="pi pi-times" (onClick)="clearSmiles()" size="small"></p-button>
        </p-inputGroup>
    </div>
</div>

<div class="flex flex-row">
    <div class="flex">
        <app-molecule-structure [width]="175" [height]="175" [structure]="smiles" *ngIf="smiles"
            [svgMode]="false"></app-molecule-structure>
    </div>
    <div class="flex" *ngIf="smiles">
        <div class="flex flex-column">
            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="showChemdraw=true" size="small" />
            <p-button icon="pi pi-check" [rounded]="true" [text]="true" (onClick)="ok()" size="small" />
        </div>

    </div>
</div>

<div #appendHere>

</div>


<p-dialog header="Header" [modal]="true" [appendTo]="appendHere" [(visible)]="showChemdraw"
    styleClass="ag-custom-component-popup" [style]="{ width: '800px;' }" closable="false">

    <ng-template pTemplate="header">
        <h5>Draw Structure</h5>
    </ng-template>

    <div style="height: 490px;">
        <app-chemdraw-wrapper #cdw *ngIf="showChemdraw" [(smiles)]="smiles"></app-chemdraw-wrapper>
    </div>

    <div class="mt-4">

    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="showChemdraw = false" />
        <p-button label="OK" [outlined]="true" severity="secondary" (onClick)="ok()" />
    </ng-template>
</p-dialog>