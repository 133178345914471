import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AppService } from '../../../services/app.service';
import { ChemdrawWrapperComponent } from '../../../chemdraw-wrapper/chemdraw-wrapper.component';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { MoleculeStructureComponent } from '../../../rdkit/molecule-structure/molecule-structure.component';
import { FormsModule } from '@angular/forms';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { MetaboliteModuleService } from '../../../services/metabolite-module.service';
import { FilterComponent, FilterItem } from "../../../filter/filter.component";
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-metabolite-module-filter',
  standalone: true,
  imports: [
    ButtonModule,
    ChemdrawWrapperComponent,
    DialogModule,
    CommonModule,
    MoleculeStructureComponent,
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    FilterComponent,
    PanelModule,
    InputTextModule
  ],
  templateUrl: './metabolite-module-filter.component.html',
  styleUrl: './metabolite-module-filter.component.scss'
})
export class MetaboliteModuleFilterComponent  {

  @ViewChild("filter") filter: FilterComponent;
  showChemDraw: boolean = false;

  /**
   *
   */
  constructor(
    public metaboliteService: MetaboliteModuleService
  ) {

  }

  applyFilter() {
    this.metaboliteService.applyFilter();
    this.metaboliteService.filteredData.set(this.filter.getFilteredData(this.metaboliteService.filteredData()));
  }

  reset() {
    this.metaboliteService.reset();
  }

  dataFiltered($event: any[]) {
    this.metaboliteService.filteredData.set($event);
  }

}
