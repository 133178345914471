<div
  class="molecule-canvas-container"
  [ngStyle]="{
    width: drawingDetails.width + 'px',
    height: drawingDetails.height + 'px'
  }"
>
  <canvas
    #molCanvas
    [title]="this.structure"
    [width]="drawingDetails.width"
    [height]="drawingDetails.height"
  ></canvas>
</div>
