
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

export const Roles = {
  Admin : 'Admin',
  TargetPicker: 'TargetPicker',
  IsolationChemist: 'IsolationChemist',
  Reader: 'Reader'
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private account: AccountInfo = undefined;
  private roles: string[] = undefined;

  constructor(private msalService: MsalService) {
  }

  private refreshRoles() {
    if (this.account) return;

    this.account = this.msalService.instance?.getActiveAccount();
    this.roles = this.account?.idTokenClaims?.roles;
  }

  private hasAnyRole(checkRoles: string[]): boolean {

    this.refreshRoles();

    if (!checkRoles || checkRoles.length < 1 || !this.roles || this.roles.length < 1) {
      return false;
    }

    for (const role of checkRoles) {
      if (this.roles.includes(role)) {
        return true;
      }
    }

    return false;
  }

  public isReaderOrHigher(): boolean {
    return this.hasAnyRole([Roles.Admin, Roles.TargetPicker, Roles.IsolationChemist, Roles.Reader]);
  }

  public isIsolationChemistOrHigher(): boolean {
    return this.hasAnyRole([Roles.Admin, Roles.TargetPicker, Roles.IsolationChemist]);
  }

  public isTargetPickerOrHigher(): boolean {
    return this.hasAnyRole([Roles.Admin, Roles.TargetPicker]);
  }

  public isAdminOrHigher(): boolean {
    return this.hasAnyRole([Roles.Admin]);
  }
}
