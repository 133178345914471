import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StrainsModuleComponent } from './modules/strains/strains-module/strains-module.component';
import { MetaboliteModuleComponent } from './modules/metabolite/metabolite-module/metabolite-module.component';
import { BgcModuleComponent } from './modules/bgc/bgc-module/bgc-module.component';
import { TargetsModuleComponent } from './modules/targets/targets-module/targets-module.component';
import { LibraryModuleComponent } from './modules/library/library-module/library-module.component';
import { MetaboliteModuleFilterComponent } from './modules/metabolite/metabolite-module-filter/metabolite-module-filter.component';
import { MetaboliteModuleButtonsComponent } from './modules/metabolite/metabolite-module-buttons/metabolite-module-buttons.component';
import { LibraryModuleFilterComponent } from './modules/library/library-module-filter/library-module-filter.component';
import { MetaboliteModuleDeepDiveComponent } from './modules/metabolite/metabolite-module-deep-dive/metabolite-module-deep-dive.component';
import { BgcModuleDeepDiveComponent } from './modules/bgc/bgc-module-deep-dive/bgc-module-deep-dive.component';

export const routes: Routes = [
    {
        path: 'home',
        title: 'Target Selection Tool',
        component: DashboardComponent,
    },
    {
        path: 'strains',
        data: {
            reuse: true
        },
        title: 'Strains Module',
        component: StrainsModuleComponent,
    },

    {
        path: 'metabolites',
        data: {
            reuse: true
        },
        title: 'Metabolites Module',
        component: MetaboliteModuleComponent,
        // children: [
        //     {
        //         path: 'buttons',
        //         component: MetaboliteModuleButtonsComponent,
        //         outlet: 'module-buttons'
        //     },
        // ]
    },

    {
        path: 'metabolites-deep-dive',
        data: {
            reuse: true
        },
        title: 'Metabolites Detail',
        component: MetaboliteModuleDeepDiveComponent

    },

    {
        path: 'metabolites-filter',
        title: 'Metabolites Module Filter',
        component: MetaboliteModuleFilterComponent,
        outlet: 'filter'
    },
    {
        path: 'bgc',
        data: {
            reuse: true
        },
        title: 'Biosynthetic Gene Clusters',
        component: BgcModuleComponent,
    },
    {
        path: 'bgc-deep-dive',
        data: {
            reuse: true
        },
        title: 'Biosynthetic Gene Clusters Deep Dive',
        component: BgcModuleDeepDiveComponent,
    },
    {
        path: 'targets',
        data: {
            reuse: true
        },
        title: 'Targets Module',
        component: TargetsModuleComponent,
    },
    {
        path: 'library',
        data: {
            reuse: true
        },
        title: 'Library Module',
        component: LibraryModuleComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];
