<div class="grid">
    <div class="col-4">
        <h6>Max Correlation Score: {{this.bgc?.maxCorrelationScore ? this.bgc?.maxCorrelationScore : ""}}</h6>
    </div>
    <div class="col-4">
        <h6>GCF Type: {{this.bgc?.gcF_Type}}</h6>
    </div>
    <div class="col-4">
    </div>
</div>
<ag-grid-angular class="ag-theme-quartz" style="height: 800px;" [rowData]="bgcDeepDiveRows"
    [columnDefs]="colDefs" [pagination]="true" [paginationPageSize]="10" (gridReady)="onGridReady($event)" [paginationPageSizeSelector]="[10,25,50]"
    [rowSelection]="{ mode: 'multiRow',checkboxes: false,
    headerCheckbox: true, selectAll: 'currentPage',
    enableClickSelection: true}" (selectionChanged)="onSelectionChanged($event)" sideBar="filters" (firstDataRendered)="onFirstDataRendered($event)"/>

<app-create-target #ctd (targetCreated)="targetCreated()"></app-create-target>

<app-reject-target #rtd (targetsRejected)="targetsRejected()" ></app-reject-target>
