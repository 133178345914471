@if (mode == "chip") {
<p-chip [label]="profile?.displayName" [image]="userImage" [alt]="profile?.displayName" (click)="op.toggle($event)" />
} @else {
<p-avatar [image]="userImage" shape="circle" (click)="op.toggle($event)" />
}


<p-overlayPanel #op  [style]="{width: '400px'}" appendTo="body">
    <ng-template pTemplate>
        <table style="width: 100%;">
            <tr>
                <td class="heading">
                    Name
                </td>
                <td>
                    {{profile.displayName}}
                </td>
            </tr>
            <tr>
                <td class="heading">
                    Email
                </td>
                <td>
                    {{profile.mail}}
                </td>
            </tr>
        </table>
    </ng-template>
</p-overlayPanel>