import { Component } from '@angular/core';

@Component({
  selector: 'app-bgc-module-deep-dive',
  standalone: true,
  imports: [],
  templateUrl: './bgc-module-deep-dive.component.html',
  styleUrl: './bgc-module-deep-dive.component.scss'
})
export class BgcModuleDeepDiveComponent {

}
