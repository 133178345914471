import * as i3 from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i0 from '@angular/core';
import { NgModule, Optional, SkipSelf, Inject } from '@angular/core';
import * as i2 from '@azure/msal-angular';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import * as i1 from '@corteva-research/ngx-components-core';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { combineLatest, of, Observable } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
class ResearchComponentsMsalIntegrationModule {
  constructor(resComponentsMsal, userInformationService, msalService, msalBroadcastService, httpClient, msalGuardConfig, msalInterceptorConfig) {
    this.userInformationService = userInformationService;
    this.msalService = msalService;
    this.msalBroadcastService = msalBroadcastService;
    this.httpClient = httpClient;
    this.msalGuardConfig = msalGuardConfig;
    this.msalInterceptorConfig = msalInterceptorConfig;
    // Scopes needed to retrieve user information, including avatar
    this.requiredScopes = ['user.read', 'profile', 'openid'];
    this.graphApiBaseUri = 'https://graph.microsoft.com/v1.0/';
    this.graphApiUserInfoUri = `${this.graphApiBaseUri}/me`;
    this.graphApiUserAvatarUri = `${this.graphApiUserInfoUri}/photo/$value`;
    // Associate scopes for known resources
    this.requiredProtectedResources = new Map([[this.graphApiBaseUri, ['user.read']]]);
    if (resComponentsMsal) {
      throw new Error('ResearchComponentsMsalIntegrationModule should only be imported once -- at the root of your application');
    }
    this.augmentMsalInterceptorConfig();
    this.msalBroadcastService.inProgress$.pipe(filter(status => status === InteractionStatus.None)).subscribe(() => {
      this.setAuthenticatedUser();
    });
    userInformationService.enableAuthentication();
    userInformationService.loginRequest$.subscribe(() => {
      if (this.msalGuardConfig && this.msalGuardConfig.authRequest) {
        msalService.loginRedirect(this.msalGuardConfig.authRequest);
      } else {
        msalService.loginRedirect();
      }
    });
    userInformationService.logoutRequest$.subscribe(() => {
      const endSessionRequest = {
        account: this.msalService.instance.getActiveAccount()
      };
      msalService.logoutRedirect(endSessionRequest);
    });
    this.setAuthenticatedUser();
  }
  setAuthenticatedUser(accountInfo = null) {
    if (!accountInfo) {
      accountInfo = this.msalService.instance.getActiveAccount();
      if (!accountInfo) {
        const allAccounts = this.msalService.instance.getAllAccounts();
        if (!!allAccounts && allAccounts.length > 0) {
          accountInfo = allAccounts[0];
          this.msalService.instance.setActiveAccount(accountInfo);
        }
      }
    }
    if (accountInfo) {
      this.userInformationService.setAuthenticationStatus('authenticated');
      combineLatest([this.getGraphUserInfo().pipe(startWith({
        id: accountInfo.localAccountId,
        givenName: accountInfo.username,
        surname: ''
      })), this.getGraphUserAvatar().pipe(startWith(null))]).subscribe(([userInfo, avatar]) => {
        const userInformation = {
          uniqueId: userInfo.id,
          displayName: `${userInfo.givenName} ${userInfo.surname}`,
          avatarUri: avatar
        };
        this.userInformationService.setUserInformation(userInformation);
      });
    } else {
      this.userInformationService.setAuthenticationStatus('unauthenticated');
    }
  }
  augmentMsalInterceptorConfig() {
    // Needed for authorizing GraphAPI
    this.msalInterceptorConfig.interactionType = InteractionType.Redirect;
    this.msalInterceptorConfig.protectedResourceMap = this.msalInterceptorConfig.protectedResourceMap || new Map();
    for (const key of this.requiredProtectedResources.keys()) {
      const requiredScopes = this.requiredProtectedResources.get(key) || [];
      if (this.msalInterceptorConfig.protectedResourceMap.has(key)) {
        const scopes = this.msalInterceptorConfig.protectedResourceMap.get(key) || [];
        scopes.concat(requiredScopes.filter(scope => {
          return scopes.indexOf(scope) < 0;
        }));
        this.msalInterceptorConfig.protectedResourceMap.set(key, scopes);
      } else {
        this.msalInterceptorConfig.protectedResourceMap.set(key, requiredScopes);
      }
    }
  }
  getGraphUserAvatar() {
    return this.httpClient.get(this.graphApiUserAvatarUri, {
      responseType: 'blob'
    }).pipe(switchMap(blob => {
      return this.convertBlobToBase64(blob);
    }));
  }
  getGraphUserInfo() {
    return this.httpClient.get(this.graphApiUserInfoUri);
  }
  convertBlobToBase64(blob) {
    if (!blob) {
      return of(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Observable(subscriber => {
      let result = null;
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          result = reader.result;
        }
        subscriber.next(result);
        subscriber.complete();
      };
    });
  }
}
ResearchComponentsMsalIntegrationModule.ɵfac = function ResearchComponentsMsalIntegrationModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ResearchComponentsMsalIntegrationModule)(i0.ɵɵinject(ResearchComponentsMsalIntegrationModule, 12), i0.ɵɵinject(i1.UserInformationService), i0.ɵɵinject(i2.MsalService), i0.ɵɵinject(i2.MsalBroadcastService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(MSAL_GUARD_CONFIG), i0.ɵɵinject(MSAL_INTERCEPTOR_CONFIG));
};
ResearchComponentsMsalIntegrationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ResearchComponentsMsalIntegrationModule
});
ResearchComponentsMsalIntegrationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }],
  imports: [HttpClientModule, ResearchComponentsCoreModule, MsalModule, HttpClientModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResearchComponentsMsalIntegrationModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [HttpClientModule, ResearchComponentsCoreModule, MsalModule, HttpClientModule],
      exports: [],
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: ResearchComponentsMsalIntegrationModule,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: i1.UserInformationService
    }, {
      type: i2.MsalService
    }, {
      type: i2.MsalBroadcastService
    }, {
      type: i3.HttpClient
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MSAL_GUARD_CONFIG]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MSAL_INTERCEPTOR_CONFIG]
      }]
    }];
  }, null);
})();

/*
 * Public API Surface of ngx-components-msal-integration
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ResearchComponentsMsalIntegrationModule };
