<p-panel header="Predicted Structure (SMILES)" [toggleable]="true">

    <div class="flex flex-row">
        <div class="flex">
            <!-- <label for="smiles">Predicted Structure (SMILES)</label> -->
            <!-- <input type="text" pInputText [(ngModel)]="smiles" id="smiles" /> -->
            <p-inputGroup>
                <input id="smiles" type="text" pInputText [(ngModel)]="metaboliteService.filter.smiles">
                <p-button type="button" icon="pi pi-times" (onClick)="metaboliteService.filter.smiles = undefined"
                    size="small"></p-button>
            </p-inputGroup>
        </div>
    </div>

    <div class="flex flex-row">
        <div class="flex">
            <app-molecule-structure [width]="100" [height]="100" [structure]="metaboliteService.filter.smiles"
                *ngIf="metaboliteService.filter.smiles" [svgMode]="false"></app-molecule-structure>
        </div>
        <div class="flex" *ngIf="metaboliteService.filter.smiles">
            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="showChemDraw=true" size="small"
                class="m-4" />
        </div>
    </div>

</p-panel>





<app-filter #filter [data]="this.metaboliteService.filteredData()"
    [filterItems]="metaboliteService.filter.filterConfig"></app-filter>




<div class="flex flex-row mt-4">
    <p-button icon="pi pi-filter" label="Apply" (onClick)="applyFilter()" size="small" />
    <p-button icon="pi pi-refresh" label="Reset" (onClick)="reset()" size="small" class="ml-2" />
</div>

<p-dialog header="Draw Structure" [modal]="true" appendTo="body" [(visible)]="showChemDraw"
    [style]="{ width: '800px;' }">
    <div style="height: 490px;">
        <app-chemdraw-wrapper *ngIf="showChemDraw" [(smiles)]="metaboliteService.filter.smiles"></app-chemdraw-wrapper>
    </div>
</p-dialog>