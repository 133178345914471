

<div style="height: 320px;">
  <div class="flex flex-row">
    <p-dropdown id="searchTypeDropDown" [options]="searchTypesOptions"
                [(ngModel)]="filterData.selectedSearchType"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Search Type">
    </p-dropdown>
  </div>

  <div class="flex flex-row mt-1" *ngIf="filterData.selectedSearchType == structureSearchType.Similarity">
    <div class="flex">

      <app-input-w-limited-dec id="similarityThreshold" [placeholder]="'Similarity Threshold'" [(value)]="filterData.similarityThreshold" [min]="0.001" [max]="1.0" >
      </app-input-w-limited-dec>

    </div>
  </div>

  <div class="flex flex-row mt-1">
      <div class="flex">
          <p-inputGroup>
              <input id="smiles" type="text" pInputText [(ngModel)]="filterData.searchSmiles" placeholder="SMILES">
              <p-button type="button" icon="pi pi-times" (onClick)="filterData.searchSmiles = undefined"
                  size="small"></p-button>
          </p-inputGroup>
      </div>
  </div>

  <div class="flex flex-row">
      <div class="flex">
          <app-molecule-structure *ngIf="filterData.searchSmiles" [width]="175" [height]="175" [structure]="filterData.searchSmiles"
               [svgMode]="false"></app-molecule-structure>
      </div>
      <div class="flex" >
        <div class="flex flex-column">
          <!-- class="m-4" -->
          <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="showChemDrawDialog()" size="small" />
          <p-button *ngIf="filterData.searchSmiles" icon="pi pi-check" [rounded]="true" [text]="true" (onClick)="applyFilter()" size="small" />
          <p-button *ngIf="filterData.searchSmiles" icon="pi pi-times" [rounded]="true" [text]="true" (onClick)="reset()" size="small" />
        </div>
      </div>
  </div>

</div>

<div #appendHere>

</div>


<p-dialog header="Draw Structure" [modal]="true" [appendTo]="appendHere" [(visible)]="showChemDraw"
    styleClass="ag-custom-component-popup" [style]="{ width: '800px;' }" closable="false">

    <ng-template pTemplate="header">
        <h5>Draw Structure</h5>
    </ng-template>

    <div style="height: 490px;">
        <app-chemdraw-wrapper #cdw *ngIf="showChemDraw" [(smiles)]="drawnStructure"></app-chemdraw-wrapper>
    </div>

    <div class="mt-4">

    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="showChemDraw = false" />
        <p-button label="OK" [outlined]="true" severity="secondary" (onClick)="applyDrawnStructure()" />
    </ng-template>
</p-dialog>
